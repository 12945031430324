import React from "react";
import WorkingProcess from "./WorkingProcess";

const AIJourneyExpertise = ({ currentPage }) => {
  return (
    <WorkingProcess
      title={"Your AI Journey, Our Expertise"}
      desc={
        "Our proven AI strategy and consulting process is designed to transform your business. We combine deep industry knowledge with cutting-edge AI technologies to deliver tailored solutions that drive real results."
      }
      page={"serviceDetails"}
      currentPage={currentPage}
    />
  );
};

export default AIJourneyExpertise;
