import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { RiArrowDownSLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

const PortolioDetailCommon = ({ setName }) => {
  const location = useLocation();
  setName(location.state?.projectData?.title);
  return (
    <div className="w-full bg-[var(--background-color)] text-center pt-14 md:py-20 px-6 md:px-16 max-w-7xl mx-auto">
      <div className="flex cursor-pointer md:w-12 mb-3 mt-9 md:h-12 w-9 h-9 border border-white rounded-full  items-center justify-center">
        <BsArrowLeft className="text-[var(--text-color)] md:text-xl" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-20 text-[var(--text-color)]">
        <div className="text-start space-y-6">
          <h2 className="text-4xl flex flex-col md:text-4xl lg:text-5xl leading-10  font-semibold">
            <span className="lg:leading-[60px]">
              {" "}
              {location.state.projectData.title
                ? location.state.projectData.title
                : ""}
            </span>
            <span className="lg:leading-[60px]">Website Redesign</span>
          </h2>

          <p className="text-xs md:text-base font-normal lg:text-lg text-[#7D7C88]">
            {" "}
            {location.state.projectData.description
              ? location.state.projectData.description
              : ""}
          </p>

          <div className="flex text-xs md:text-xl flex-col items-start justify-start gap-3 font-semibold">
            <div className="flex items-center justify-center gap-2 text-xs md:text-xl">
              <img
                className="w-3 md:w-6 md:h-4 h-3"
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/sk.svg"
                }
                alt="active logo"
              />
              Data-Driven AI Transformation
            </div>

            <div className="flex items-center justify-center gap-2">
              <img
                className="w-3 md:w-6 md:h-4 h-3"
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/sk.svg"
                }
                alt="active logo"
              />
              Customized AI Solutions
            </div>

            <div className="flex items-center justify-center gap-2">
              <img
                className="w-3 md:w-6 md:h-4 h-3"
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/sk.svg"
                }
                alt="active logo"
              />
              Proven AI Expertise
            </div>
          </div>

          <div className="flex  items-start gap-5 lg:gap-8  justify-start  rounded-xl">
            <div className="flex  flex-col items-center justify-center ">
              <span className="text-[13.85px] md:text-4xl font-semibold">
                10M+
              </span>
              <p className="md:text-lg text-center font-normal text-[8.66px]">
                Downloads
              </p>
            </div>

            <div className="flex  flex-col items-center justify-center ">
              <span className="text-[13.85px] md:text-4xl font-semibold">
                4.7/5
              </span>
              <p className="md:text-lg text-center font-normal text-[8.66px]">
                Avg. Rating
              </p>
            </div>

            <div className="flex  flex-col items-center justify-center ">
              <span className="text-[13.85px] md:text-4xl font-semibold">
                $100M+
              </span>
              <p className="md:text-lg text-center font-normal text-[8.66px]">
                Funding Raised
              </p>
            </div>
          </div>

          <Link
            to="#"
            className="btn lg:w-1/2 gap-1 md:gap-0 bg-cover flex items-center justify-center hover:text-red-500 bg-opacity-50  bg-custom-gradient hover:bg-custom-gradient-hover text-base lg:text-lg rounded-full text-[var(--text-color)]  font-semibold px-9 py-2.5 duration-500 md:static"
          >
            Read Case Study <RiArrowDownSLine className="md:text-xl text-sm" />
          </Link>
        </div>

        <div className="w-full">
          {" "}
          <img
            className="w-full"
            src={
              location.state.projectData.image
                ? location.state.projectData.image
                : ""
            }
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default PortolioDetailCommon;
