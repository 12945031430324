import React, { useState, useRef } from "react";
import { RiArrowDownSLine } from "react-icons/ri";

const Accordion = ({
  idx,
  currentTab,
  setCurrentTab,
  title,
  answer,
  list,
  children,
}) => {
  const contentRef = useRef(null);

  const handleClick = () => {
    setCurrentTab(currentTab === idx ? 0 : idx);
  };

  const handleRotate = () => (currentTab === idx ? "rotate-180" : "");
  const handleToggle = () =>
    currentTab === idx
      ? { maxHeight: `${contentRef.current.scrollHeight}px` }
      : { maxHeight: "0px" };

  console.log(children, "we");

  return (
    <li className="bg-[var(--background-gray-color)]  md:py-2.5 py-1 rounded-lg text-[var(--text-color)] my-1.5 md:my-2 custom-shadow">
      <div
        onClick={handleClick}
        className="flex justify-between items-center font-semibold p-3 gap-3 cursor-pointer"
      >
        <span className="text-base lg:leading-5 md:text-xl flex-grow">
          {title}
        </span>
        <RiArrowDownSLine
          className={`fill-current rounded-full border-none bg-[var(--background-color-white)] text-black h-4 w-4 md:h-6 md:w-6 transform transition-transform duration-300 ${handleRotate()} flex-shrink-0`}
        />
      </div>
      <div
        ref={contentRef}
        className="overflow-hidden duration-300 transition-max-height"
        style={handleToggle()}
      >
        <div className="px-5 pb-2 text-base md:text-lg text-[#B1B1B1]">
          {answer}
        </div>

        {list &&
          list.map((item, index) => (
            <div
              key={index}
              className="px-5 gap-1 flex items-start justify-start pb-2 text-base md:text-lg text-[#B1B1B1]"
            >
              <img
                className="w-3 md:w-6 md:h-4 h-3 mt-1.5"
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/sk.svg"
                }
                alt="active logo"
              />{" "}
              {item}
            </div>
          ))}
      </div>
    </li>
  );
};

const FAQ = ({ faqData }) => {
  const [currentTab, setCurrentTab] = useState(0);

  console.log(faqData, "faq");

  return (
    <div className="bg-[var(--background-color)] px-6 md:px-16">
      <main className="w-full max-w-3xl mx-auto bg-light-blue rounded-lg">
        <h1 className="text-3xl md:text-[56px] text-center md:mb-14 mb-5 font-semibold text-[var(--text-color)]">
          FAQs
        </h1>
        <ul className="grid grid-cols-1 gap-4 md:gap-6">
          {faqData.map((faq) => (
            <Accordion
              key={faq.id}
              idx={faq.id}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              title={faq.question}
              answer={faq.answer}
              list={faq.list}
            >
              {faq.ans}
            </Accordion>
          ))}
        </ul>
      </main>
    </div>
  );
};

export default FAQ;
