import { useState } from "react";
import ButtonComponent from "../common/ButtonComponent";
import { headerData } from "../data/HeaderData";
import SidebarItem from "./SidebarItem";

// This sidebar component is for both mobile and desktop
function Sidebar({ children, expanded, setExpanded, openA, setOpen }) {
  console.log(openA, "chaning");
  return (
    <div
      className={`mt-7 z-50 ${
        openA ? "animate-fade-in-left" : "animate-fade-in-back"
      } overflow-hidden`}
    >
      <div
        style={{
          backgroundImage: `url(https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/homelogo.svg)`,
        }}
        className={`mb-20 z-50 px-6 bg-center bg-cover overflow-y-auto   transition-all ${
          expanded ? "w-full lg:hidden h-screen" : ""
        }`}
      >
        <nav className="flex z-50 mt-2  mb-5 flex-col">
          <ul className="text-white space-y-0">{children}</ul>
        </nav>
        <div className="ms-3">
          {" "}
          <ButtonComponent page={"menu"} setOpen={setOpen} />
        </div>
      </div>
    </div>
  );
}

export default function MakeSidebar({ openA, setOpenA, open, setOpen }) {
  const [expanded, setExpanded] = useState(true);
  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  return (
    <Sidebar
      expanded={expanded}
      setExpanded={setExpanded}
      openA={openA}
      setOpen={setOpen}
    >
      {headerData.map((item, index) => (
        <SidebarItem
          key={index}
          expanded={expanded}
          active={activeItem === item.name}
          onClick={() => handleItemClick(item.name)}
          {...item}
          setOpen={setOpen}
        />
      ))}
    </Sidebar>
  );
}
