import React from "react";
import { ImQuotesLeft } from "react-icons/im";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CustomLeftArrow from "../../components/common/CustomLeftArrow";
import CustomRightArrow from "../../components/common/CustomRightArrow";
import {
  testimonialResponsive,
  testimonials,
} from "../../components/data/HomeData";

const TestimonialSection = () => {
  return (
    <div className="bg-[var(--background-color)] text-[var(--text-color)]">
      <section className="mx-auto max-w-7xl">
        <div className="">
          <h2 className="text-center md:text-[56px] text-3xl font-semibold md:mb-14 mb-5">
            The Voice of Experience
          </h2>
          <div className="relative">
            <Carousel
              responsive={testimonialResponsive}
              className="itemClass  containerClass dotListClass gap-10 md:pb-24 py-14"
              customLeftArrow={<CustomLeftArrow />}
              customRightArrow={<CustomRightArrow />}
              infinite={true}
            >
              {testimonials &&
                testimonials.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      backgroundColor: `${item.color}`,
                    }}
                    className="h-full mx-3 p-5 flex flex-col  transition-transform duration-300"
                  >
                    <div className="flex relative border-4 mb-2 border-black -top-20 lg:-top-20 justify-center items-center w-24 h-24 sm:w-24 sm:h-24 md:w-28 md:h-28 rounded-full overflow-hidden mx-auto">
                      <img
                        src={item.image}
                        alt=""
                        className="w-full h-full  object-cover"
                      />
                    </div>

                    <div className="flex flex-col items-center justify-center -mt-20">
                      <h4 className="text-center text-lg  md:text-xl font-semibold">
                        {item.name}
                      </h4>
                      <h5 className="text-center text-base md:text-base font-medium">
                        {item.position}
                      </h5>

                      <div className="bg-[var(--background-color-white)] mt-3 h-9 w-9 sm:w-12 sm:h-12 md:w-12 md:h-12 rounded-full flex items-center justify-center">
                        <ImQuotesLeft
                          className="w-5 h-5"
                          style={{
                            color: `${item.color}`,
                          }}
                        />
                      </div>
                      <p className="mt-3 text-center text-base md:text-lg font-normal leading-relaxed text-[var(--text-color)]">
                        {item.text}
                      </p>
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TestimonialSection;
