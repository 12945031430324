import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import Loader from "../../../components/layout/loading/Loader";
import axios from "axios";

// import { useDispatch } from "react-redux";
// import { register } from "../../../actions/userAction";

const CaseStudyLeadPopup = ({ isOpenPopup, setIsOpenPopup }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  function isValiEmail(val) {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      return "Enter a valid email address";
    }
  }

  const handleChange = (phone) => {
    setFormData({ ...formData, phone });
    if (phone) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: "",
      }));
    }
  };

  const validate = () => {
    let errors = {};

    if (!formData.name) errors.name = "Name is required";

    // Email validation
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (isValiEmail(formData.email)) {
      errors.email = "Enter a valid email address";
    }

    if (!formData.phone) {
      errors.phone = "Phone number is required";
    } else if (!isValidPhoneNumber(formData.phone)) {
      errors.phone = "Enter a valid phone number";
    }

    return errors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (value.trim() !== "") {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      try {
        setLoading(true);

        const dataForm = {
          ...formData,
          date: new Date().toLocaleDateString(),
        };

        const config = { headers: { "Content-Type": "application/json" } };

        const data = await axios.post(
          `https://abstratbit-default-rtdb.firebaseio.com/downloadUsers.json`,
          dataForm,
          config
        );

        console.log(data, "kashif");

        if (data.status === 200) {
          setSuccessMessage("Your message has been successfully sent!");
          setFormData({
            name: "",

            email: "",
            phone: "",
          });
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setErrors(validationErrors);
      setSuccessMessage("");
    }
  };

  return (
    <div
      className={`fixed overflow-auto top-5 left-0 w-full h-full bg-[#161617] bg-opacity-50 z-40 ${
        isOpenPopup ? "block" : "hidden"
      }`}
    >
      <div className="lg:w-[800px] w-full my-16 mx-auto bg-[#161617] rounded-lg shadow-lg">
        <div className="max-w-7xl mx-auto  px-4 md:px-10 pt-2 pb-10">
          <div className="text-white flex items-center justify-between">
            <div></div>
            <span
              onClick={() => setIsOpenPopup(false)}
              className="text-xl cursor-pointer"
            >
              X
            </span>
          </div>
          <div
            className={`lg:flex-row gap-10  flex flex-col w-full   items-center md:items-start justify-center md:justify-start  text-[var(--text-color)]`}
          >
            <div className="text-start w-full lg:w-[40%] gap-2 flex flex-col items-start justify-start">
              <h2 className="text-5xl flex flex-col md:text-4xl lg:text-[84px] font-bold">
                <span className="textColor md:leading-[80px] max-w-xl">
                  Please fill the form.
                </span>{" "}
              </h2>

              <p className="text-xl md:text-2xl">
                And we will send you the case study link.{" "}
              </p>
            </div>

            {/* Right Section - Form */}
            <div className="flex flex-col md:mt-4 justify-center md:items-start md:justify-start w-full lg:w-[60%]">
              <form
                onSubmit={handleSubmit}
                className="md:space-y-4 space-y-2.5 w-full text-start"
              >
                <div className="space-y-1">
                  <label
                    htmlFor="name"
                    className="text-base md:text-lg font-normal"
                  >
                    Name *
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Enter your name here"
                    className={`w-full p-3 outline-none  rounded-md md:text-base text-base bg-[#2D2D2D] text-[var(--text-color)] placeholder-[#7D7C88] ${
                      errors.name ? "border-red-500 border" : ""
                    }`}
                  />
                  {errors.name && (
                    <p className="text-red-500 text-sm">{errors.name}</p>
                  )}
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="email"
                    className="text-base md:text-lg font-normal"
                  >
                    Email *
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Enter your email here"
                    className={`w-full p-3 outline-none rounded-md md:text-base text-base bg-[#2D2D2D] text-[var(--text-color)] placeholder-[#7D7C88] ${
                      errors.email ? "border-red-500 border" : ""
                    }`}
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm">{errors.email}</p>
                  )}
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="phone"
                    className="text-base md:text-lg font-normal"
                  >
                    Phone *
                  </label>
                  <PhoneInput
                    value={formData.phone}
                    onChange={handleChange}
                    defaultCountry="IN"
                    placeholder="Phone (eg. +91 1234567891)"
                    className={`w-full p-1.5 outline-none rounded-md md:text-base text-base bg-[#2D2D2D] text-[var(--text-color)] placeholder-[#7D7C88] ${
                      errors.phone ? "border-red-500 border" : ""
                    }`}
                  />
                  {errors.phone && (
                    <p className="text-red-500 text-sm">{errors.phone}</p>
                  )}
                </div>

                <button
                  type="submit"
                  className="btn flex items-center justify-center text-center bg-cover hover:text-red-500 bg-opacity-50 bg-custom-gradient hover:bg-custom-gradient-hover text-base lg:text-lg rounded-full text-[var(--text-color)] font-semibold px-9 py-2 duration-500 md:static"
                >
                  Submit {loading ? <Loader /> : ""}
                </button>

                {successMessage && (
                  <p className="mt-5 bg-gray-900 p-3 lg:p-5 text-green-500 text-base lg:text-xl">
                    {successMessage}
                  </p>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyLeadPopup;
