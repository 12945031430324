import React from "react";
import { Link } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";

const OutCome = () => {
  return (
    <div className="">
      <div className="bg-[var(--background-gray-color)] ">
        <div className="flex px-6 md:px-16 flex-col  mx-auto max-w-7xl text-[var(--text-color)] ">
          <div className="flex flex-col items-center justify-center py-10">
            <h1 className="md:text-[56px] text-2xl  text-center  mb-3 font-semibold">
              Outcomes
            </h1>
            <p className="md:text-xl text-xs font-mediumtext-center mt-3 mb-7">
              At Abstrabit, We love creating efficient solutions for our clients{" "}
              <br className="lg:block hidden" /> which improve their businesses
              and promises growth.
            </p>

            <div className="flex min-w-3xl mb-4 w-full items-start gap-5 lg:gap-20 mt-5 p-3 md:p-5 justify-center bg-[var(--background-color-white)] rounded-lg">
              <div className="flex  flex-col items-center justify-center ">
                <span className=" textColor  font-semibold">
                  <span className="md:text-6xl lg:text-8xl text-[28px]">
                    230
                  </span>
                  <span className="lg:text-[40px] md:text-2xl text-sm">%</span>
                </span>
                <p className="md:text-xl text-center textColorPurple font-semibold text-[8.66px]">
                  Increase in organic traffic through relevant search results
                </p>
              </div>

              <div className="flex  flex-col items-center justify-center ">
                <span className=" textColor  font-semibold">
                  <span className="md:text-6xl lg:text-8xl text-[28px]">
                    3.11
                  </span>
                  <span className="lg:text-[40px] md:text-2xl text-sm">
                    min
                  </span>
                </span>
                <p className="md:text-xl text-center textColorPurple font-semibold text-[8.66px]">
                  Average rise in engagement session indicating quality content
                </p>
              </div>

              <div className="flex  flex-col items-center justify-center ">
                <span className=" textColor  font-semibold">
                  <span className="md:text-6xl lg:text-8xl text-[28px]">
                    10
                  </span>
                  <span className="lg:text-[40px] md:text-2xl text-sm">th</span>
                </span>
                <p className="md:text-xl text-center textColorPurple font-semibold text-[8.66px]">
                  Ranking position achieved in three months. #No.1 Search on
                  Google
                </p>
              </div>
            </div>

            <Link
              to="#"
              className="btn md:w-1/2 w-1/3 lg:w-1/3 gap-1 md:gap-1 bg-cover mt-7 flex items-center justify-center hover:text-red-500 bg-opacity-50  bg-custom-gradient hover:bg-custom-gradient-hover text-base lg:text-lg rounded-full text-[var(--text-color)]  font-semibold px-9 py-3 duration-500 md:static"
            >
              Request a Quote for Your Project
              <MdArrowOutward className="" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutCome;
