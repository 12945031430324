import React from "react";
import { FiChevronRight } from "react-icons/fi";

import Carousel from "react-multi-carousel";
import CustomLeftArrow from "../../components/common/CustomLeftArrow";
import CustomRightArrow from "../../components/common/CustomRightArrow";
import { blogs } from "../../components/data/BlogData";
import { testimonialResponsive } from "../../components/data/HomeData";
import { useNavigate } from "react-router-dom";
import { convertToSlug } from "../../utils/convertToSlug";

const BlogSection = () => {
  const navigate = useNavigate();

  const handleGoToBlogDetailsPage = (id, blogItem) => {
    navigate(`/blog/blog-details/${id}`, {
      state: blogItem,
    });
  };

  return (
    <div className="-mt-20  lg:-mt-44">
      <section className=" bg-[var(--background-gray-color)] pt-20">
        <div className="flex mx-auto max-w-7xl pb-10 lg:py-10 flex-col text-[var(--text-color)] px-6 md:px-16">
          <h2 className=" text-3xl lg:text-6xl text-center font-semibold md:mb-14 mb-5">
            Blogs
          </h2>

          <div className="relative">
            <Carousel
              responsive={testimonialResponsive}
              className="itemClass containerClass dotListClass pb-16 md:pb-24"
              customLeftArrow={<CustomLeftArrow />}
              customRightArrow={<CustomRightArrow />}
              infinite={true}
            >
              {blogs &&
                blogs.map((item, index) => (
                  <div
                    key={index}
                    className="flex pb-14 lg:pb-16 sm:mr-3 h-full md:mr-5 bg-[#2D2D2D] flex-col items-start justify-start"
                  >
                    <div className="w-full">
                      <img
                        src={item.image}
                        alt=""
                        className="w-full h-64 object-cover"
                      />
                    </div>
                    <div className="flex flex-col items-start justify-between gap-1 lg:gap-2 p-5">
                      <div>
                        <div className="flex text-[#7D7C88]  text-base font-normal items-start gap-1 md:gap-2 justify-start">
                          <h4 className="text-left ">{item?.author}</h4>
                          <span>•</span>
                          <h5 className="text-start">{item.date}</h5>
                        </div>

                        <p className=" text-start mt-2 text-lg md:text-xl font-normal text-[var(--text-color)]">
                          {item.title}
                        </p>
                      </div>{" "}
                      <button
                        onClick={() =>
                          handleGoToBlogDetailsPage(
                            convertToSlug(item.title),
                            item
                          )
                        }
                        className="flex text-base bottom-5 absolute lg:bottom-5 hover:bg-[var(--background-color-white)] hover:text-red-500  items-center md:text-lg font-semibold justify-center border border-white rounded-3xl text-[#ffffff] px-4 py-2 "
                      >
                        Read More <FiChevronRight className="mt-1" />
                      </button>
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogSection;
