import React from "react";

import MetaData from "../../components/layout/MetaData";
import PlaceholderPage from "./PlaceholderPage";

const Ebooks = () => {
  return (
    <div className="flex flex-col gap-20 md:gap-44   bg-[var(--background-color)]">
       <PlaceholderPage />
      <MetaData title="E-books - Abstrabit Technologies" />
    </div>
  );
};

export default Ebooks;
