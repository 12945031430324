import React from "react";
import { useNavigate } from "react-router-dom";

const ProjectSectionCommon = ({ displayedProjects }) => {
  const navigate = useNavigate();

  const handleGoToRespectivePage = (id, projectData) => {
    navigate(`/portfolio/portfolio-details/${id}`, {
      state: { projectData },
    });
  };

  function convertToSlug(text) {
    return text
      .toLowerCase() // Convert the text to lowercase
      .replace(/[^a-z0-9\s]/g, "") // Remove any non-alphanumeric characters except spaces
      .trim() // Trim any leading or trailing spaces
      .replace(/\s+/g, "-"); // Replace spaces with hyphens
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 lg:gap-5 md:grid-cols-2 lg:grid-cols-3 mt-10 w-full bg-black max-w-7xl mx-auto px-6 md:px-16">
      {displayedProjects.map((project, index) => (
        <div
          key={index}
          className="thumb mb-4 lg:mb-7  pt-5 h-[400px] flex relative  overflow-hidden text-[var(--text-color)]"
        >
          <img className="thumb-img h-full" src={project.image} alt="Catz!" />
          <div className="thumb-gradient"></div>
          <div className="thumb-anim  mt-auto hover:space-y-5 space-y-5 px-2 lg:px-5 relative w-full md:hover:h-[55%]  hover:h-[100%] h-[55%]   md:h-[55%] ">
            <div>
              {" "}
              <h3 className="text-xl  md:text-2xl font-semibold pt-4">
                {project.title}
              </h3>
              <p className=" text-base font-normal">{project.description}</p>
            </div>

            <div>
              {" "}
              <button
                onClick={() =>
                  handleGoToRespectivePage(
                    convertToSlug(project.title),
                    project
                  )
                }
                className="w-full  gradient-orange text-[var(--text-color)] md:px-7 px-5 py-2 md:py-2 text-lg text-[10px] rounded-full"
              >
                View Case Study
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectSectionCommon;
