import React, { useRef } from "react";
import { MdArrowOutward } from "react-icons/md";
import { Link } from "react-router-dom";

import ButtonComponent from "../../components/common/ButtonComponent";
import MetaData from "../../components/layout/MetaData";
import ReviewSection from "./ReviewSection";

const HeroPage = () => {
  const containerRef = useRef(null);

  const handleRestartAnimation = () => {
    const container = containerRef.current;
    container.style.animation = "none";
    container.style.animation = "";
  };

  return (
    <div
      className="flex bg-[var(--background-color)]    overflow-hidden flex-col items-start justify-start pt-28 lg:pt-36  bg-cover bg-center"
      style={{
        backgroundImage: `url(https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/homelogo.svg)`,
      }}
    >
      <MetaData title="Home - Abstrabit Technologies" />
      <div className="mx-auto max-w-7xl px-6 md:px-12 lg:px-16">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-2 items-start justify-start">
          <div className="flex  animate-fade-in-down flex-col justify-center items-center md:items-start md:justify-start">
            <div className="text-[var(--text-color)] mb-0 flex flex-col items-center justify-center md:items-start md:justify-start font-semibold text-4xl lg:text-6xl">
              <span className="text-center md:text-start">
                AI-Powered : Supercharge
              </span>

              <section
                className="flex relative items-center justify-center text-center mb-5 md:mt-6 lg:mt-10
               text-[var(--text-color)]"
              >
                <div
                  ref={containerRef}
                  onClick={handleRestartAnimation}
                  className="flex flex-col md:flex-row font-semibold flex-nowrap lg:items-center items-center justify-between w-full relative rotatingText-content"
                >
                  <h2 className="rotatingText-adjective lg:leading-[85px]  textColor ">
                    Productivity
                  </h2>
                  <h2 className="rotatingText-adjective textColor ">
                    Customer Experience
                  </h2>
                  <h2 className="rotatingText-adjective textColor  ">
                    Business Growth
                  </h2>
                </div>
              </section>
            </div>

            <p className="text-[var(--text-color)] md:text-start text-center text-base lg:text-xl leading-6 mb-5 font-normal mt-12  md:my-5 lg:my-10">
              Your partner in AI-driven innovation and consulting. Deliver
              exceptional experiences with personalized recommendations, 24/7
              AI-powered support, and predictive analytics.
            </p>
            <div className="flex gap-3 mt-2 mb-5 md:items-center items-center md:justify-start justify-center  md:space-x-4">
              <ButtonComponent />

              <Link
                to="/portfolio"
                className="text-[var(--text-color)] underline underline-offset-4 text-sm md:text-base flex items-center justify-center gap-1 font-semibold"
              >
                Our Portfolio <MdArrowOutward className="mt-1" />
              </Link>
            </div>
          </div>
          <div className="relative  lg:left-10 animate-fade-in-right mt-3 lg:mt-3 md:mt-0 flex items-start justify-start">
            <div className="absolute  left-2 md:left-0 lg:ms-4 flex flex-col items-start justify-start">
              <div className="gradient-purple  mb-1 px-2 md:px-2 sm:px-3 py-2  lg:px-4 md:py-3 gap-2 rounded-lg w-24 sm:w-20 md:w-28 lg:w-32 flex flex-col text-black">
                <img
                  className="w-8 sm:w-10 md:w-16 lg:w-auto"
                  src={
                    "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/left1.svg"
                  }
                  alt="Left Icon"
                />
                <h1 className="text-sm pe-1 lg:px-2 md:text-sm text-[var(--text-color)] font-semibold text-start">
                  Passionate AI Experts. Proven Results.
                </h1>
              </div>
              <img
                className="h-[180px]  md:ms-0 ms-3 lg:ms-2 sm:h-[150px] md:h-[170px] lg:h-[300px]"
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/linear.svg"
                }
                alt="Line 1"
              />
              <img
                className="-mt-10 sm:-mt-5 md:-mt-10 lg:-mt-32 ms-5 w-14 sm:w-10 md:w-auto"
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/Star%201.svg"
                }
                alt="Star Icon"
              />
            </div>
            <div className="relative animate-fade-in-right left-[105px] md:left-28 lg:left-36  md:-mt-10 z-40 flex items-center justify-between">
              <img
                className="w-40  me-10 lg:me-20 sm:w-36 md:w-44 lg:w-64 md:mt-10"
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/pic.svg"
                }
                alt="pic"
              />
              <div className="absolute -right-1 lg:-right-0 justify-start -top-3 sm:-top-5 md:top-8 flex flex-col items-start text-[var(--text-color)]">
                <p className="text-base -space-y-2 relative lg:-space-y-1 -right-14 flex flex-col items-start justify-start sm:text-sm md:text-lg font-semibold">
                  <span className="text-base md:text-lg">12+ Years</span>
                  <span className="text-base md:text-base -mt-3 md:-mt-0 font-normal">
                    of Expertise
                  </span>
                </p>
                <img
                  className="h-7 ms-auto sm:h-9 md:h-20"
                  src={
                    "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/Line%203.svg"
                  }
                  alt="Line 3"
                />
              </div>
            </div>
            <div className="absolute right-4 md:ms-24  md:right-4 flex md:mt-24 mt-12 sm:mt-10 lg:mt-28">
              <img
                className="w-40 sm:w-36 lg:w-72 mt-1 md:mt-2"
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/blur.svg"
                }
                alt="Blur Logo"
              />
              <img
                className="-ms-20 sm:-ms-36 md:h-auto md:-ms-36 h-32 sm:h-40 -mb-5 sm:-mb-9 lg:mb-32"
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/line2.svg"
                }
                alt="Line 2"
              />
            </div>
          </div>
        </div>
        <ReviewSection />
      </div>
    </div>
  );
};

export default HeroPage;
