import React from "react";

import { Tooltip } from "react-tooltip";

import { imgData } from "../../components/data/ServiceData";

const OurTools = () => {
  return (
    <div className="bg-[var(--background-color)] text-[var(--text-color)] ">
      <div className="max-w-7xl mx-auto px-6 md:px-12 lg:px-16">
        <h2 className="text-3xl md:mb-14 mb-5 flex flex-col items-center justify-center md:text-[56px] font-semibold">
          Our Tools
        </h2>

        <div className="grid  md:max-w-3xl mx-auto grid-cols-3 items-center justify-center md:grid-cols-6 gap-5 md:gap-8 ">
          {imgData.map((tool, index) => (
            <div key={index} className="flex items-center justify-center">
              <img
                data-tooltip-id="my-tooltip"
                data-tooltip-content={`${tool.text}`}
                className="w-11 md:w-16 object-contain"
                src={tool.icon}
                alt={`Partner ${index}`}
              />
              <Tooltip id="my-tooltip" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurTools;
