import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import UsersList from "./UsersList";
import DownloadUserList from "./DownloadUserList";
import SubscribeUsers from "./SubscribeUsers";
import { FaSignOutAlt } from "react-icons/fa";
import { useAuth } from "../context/AuthContext";

const UserData = () => {
  const { logout } = useAuth();

  return (
    <div className="bg-black pt-20 lg:pt-28 max-w-7xl mx-auto px-6 md:px-12 lg:px-16">
      <div className="text-[var(--text-color)]  flex  flex-col ">
        <div className="flex items-center justify-between mb-5 md:mb-10">
          <h2 className="text-start  md:text-[56px] text-3xl font-semibold">
            Users Data
          </h2>

          <FaSignOutAlt
            title="Logout"
            onClick={() => logout()}
            className="text-white md:text-2xl text-xl lg:text-3xl cursor-pointer"
          />
        </div>
        <Tabs>
          <TabList className="flex justify-start space-x-2 md:space-x-4 mb-7">
            <Tab
              selectedClassName="bg-gradient-to-r outline-none text-[var(--text-color)]  from-pink-500 to-orange-500 text-[#ffffff] md:px-7   px-3.5 py-2 rounded-lg"
              className="md:px-7   px-3.5 text-center flex items-center justify-center md:py-2.5 py-2  md:text-xl text-xs  rounded-lg cursor-pointer bg-[#FFFFFF] text-[#04010066]"
            >
              Get In Touch
            </Tab>
            <Tab
              selectedClassName="bg-gradient-to-r outline-none text-[var(--text-color)]  from-pink-500 to-orange-500 text-[#ffffff]md:px-7   px-3.5 py-2 rounded-lg"
              className="md:px-7   px-3.5 text-center flex items-center justify-center  md:py-2.5 py-2  md:text-xl text-xs  rounded-lg cursor-pointer bg-[#FFFFFF] text-[#04010066]"
            >
              Download Data
            </Tab>
            <Tab
              selectedClassName="bg-gradient-to-r outline-none text-[var(--text-color)]  from-pink-500 to-orange-500 text-[#ffffff]md:px-7   px-3.5 py-2 rounded-lg"
              className="md:px-7   px-3.5 text-center flex items-center justify-center  md:py-2.5 py-2  md:text-xl text-xs  rounded-lg cursor-pointer bg-[#FFFFFF] text-[#04010066]"
            >
              Subscribed Data
            </Tab>
          </TabList>

          <TabPanel className="gap-0 ">
            <UsersList />
          </TabPanel>

          <TabPanel>
            <DownloadUserList />
          </TabPanel>
          <TabPanel>
            <SubscribeUsers />
          </TabPanel>

          {/* Repeat TabPanel for other tabs if content varies */}
        </Tabs>
      </div>
    </div>
  );
};

export default UserData;
