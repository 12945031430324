import React from "react";
import ButtonComponent from "../../components/common/ButtonComponent";

const ConnectSection = () => {
  return (
    <div className="bg-[var(--background-color)] text-[var(--text-color)] px-6 md:px-16 mb-20">
      <div className="mx-auto max-w-max">
        <h1 className="md:text-[56px] text-3xl font-semibold md:mb-14 mb-5 text-center">
          Let's Connect
        </h1>
        <div className="gradient-purple gap-5 lg:gap-20 px-4 py-4 lg:py-8 md:px-10 flex flex-col lg:flex-row items-center md:items-center justify-between md:rounded-lg">
          <div className="flex flex-col items-start justify-center gap-2">
            <h2 className="md:text-4xl text-3xl font-semibold text-start">
              Build Something Amazing with Us
            </h2>
            <p className="text-[var(--text-color)] text-base md:text-lg font-normal text-start lg:leading-6 w-full">
              Whether you have a question about our software solutions, need
              assistance with <br className="lg:block hidden" /> implementation,
              or want to explore partnership opportunities, we're here to help.
            </p>
          </div>
          <div className="flex justify-center relative  lg:-top-0 items-center h-full w-full md:w-auto">
            <ButtonComponent page={"connection"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectSection;
