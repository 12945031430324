import React from "react";
import { FaStar } from "react-icons/fa";

const AboutReviewSection = ({ page }) => {
  return (
    <div
      className={`flex-grow bg-[var(--background-color-white)] lg:block ${
        page === "about" ? "hidden" : ""
      }  animate-fade-in-down md:px-4 px-2 py-3 md:py-6 ${
        page === "portfolio" ? "" : ""
      }  rounded-2xl text-[#040100]`}
    >
      <div className="lg:flex-row w-full flex flex-col gap-6 md:gap-0 items-center md:items-start justify-center space-x-3 md:space-x-5">
        {/* Clutch Section */}
        <div className="flex items-center md:space-x-2 space-x-1 md:mb-0">
          <img
            src="https://abswebsiteassets.blob.core.windows.net/websiteimages/about-assets/clut.svg"
            alt="Clutch"
            className="h-8 md:h-9 w-20 lg:w-auto"
          />
          <div className="-space-y-2">
            <p className="flex items-center space-x-1">
              <span className="text-sm md:text-lg font-semibold">4.9</span>
              <span className="flex items-center gap-[2px] md:gap-1">
                <FaStar className="text-[#B827F1] w-2 h-2 md:w-3 md:h-3" />
                <FaStar className="text-[#B827F1] w-2 h-2 md:w-3 md:h-3" />
                <FaStar className="text-[#B827F1] w-2 h-2 md:w-3 md:h-3" />
                <FaStar className="text-[#B827F1] w-2 h-2 md:w-3 md:h-3" />
                <FaStar className="text-[#B827F1] w-2 h-2 md:w-3 md:h-3" />
              </span>
            </p>
            <p className="text-sm md:text-base font-normal">
              Average Review Rating
            </p>
          </div>
        </div>

        {/* Divider */}
        <div className="hidden md:block border-r border-gray-600 h-12"></div>

        {/* Upwork Section */}
        <div className="flex items-center md:space-x-2 space-x-1">
          <img
            src={
              "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/upw.svg"
            }
            alt="Upwork"
            className="h-8 md:h-9 w-20 lg:w-auto"
          />
          <div className="flex flex-col -space-y-1.5">
            <p className="flex items-center space-x-1 text-sm md:text-base font-normal">
              <img
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/king.svg"
                }
                alt="Top Rated Plus"
                className="w-4 h-4 md:w-auto md:h-auto"
              />
              <span>100% Job Success</span>
            </p>
            <p className="flex items-center space-x-1 text-sm md:text-base font-normal">
              <img
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/co.svg"
                }
                alt="Top Rated Plus"
                className="w-4 h-4 md:w-auto md:h-auto"
              />
              <span>Top Rated Plus</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutReviewSection;
