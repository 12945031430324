import React from "react";

const EngagementModelsHero = () => {
  return (
    <div className="bg-[var(--background-color)] text-center pt-20 md:pt-32 px-6 md:px-16 max-w-7xl mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 lg-gap-10 lg:gap-20 text-[var(--text-color)] items-start justify-start">
        <div className="md:text-start text-center animate-fade-in-down">
          <h2 className="text-4xl flex flex-col -space-y-2 md:-space-y-0 md:text-4xl lg:text-6xl   font-semibold">
            <span className="lg:leading-[85px] textColorPurple">
              {" "}
              Your Project,
            </span>
            <span className="lg:leading-[40px]">Your Terms.</span>
          </h2>

          <p className="text-base md:text-base font-normal lg:text-xl mt-5 lg:mt-10">
            We understand that every project is unique. Our flexible engagement
            models empower you to choose the partnership that best aligns with
            your goals. Whether you need a dedicated team, a structured project
            plan, or ongoing support, we provide the expertise and resources to
            drive your vision forward.
          </p>
        </div>

        <div className="animate-fade-in-right md:mt-7">
          <img
            src="https://abswebsiteassets.blob.core.windows.net/websiteimages/engagement-assets/engBg.svg"
            alt=""
            className="lg:w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default EngagementModelsHero;
