import React from "react";

import OurCulterCommon from "../../components/common/OurCulterCommon";

const OurCulture = () => {
  return (
    <div className="bg-[var(--background-color)] text-[var(--text-color)]">
      <div className="max-w-7xl mx-auto ">
        <h2 className="text-3xl mb-5 md:mb-14 flex flex-col items-center justify-center md:text-[56px] font-semibold">
          Our Culture
        </h2>
        <div className="lg:space-y-7">
          <OurCulterCommon
            subheading={"Working At"}
            heading={"Abstrabit Technologies"}
            description={
              "At Abstrabit  our vision centers on merging talent and innovation to create a positive impact within our communities. We've achieved the industry's lowest attrition rate by providing exceptional opportunities for learning, innovation, and growth. Tobuz values diversity, inviting individuals from all backgrounds to join our teams, thrive, enjoy their work, and make a meaningful difference. We're always excited to discover new and outstanding talent, so take a look at our available positions."
            }
            icon={
              "https://abswebsiteassets.blob.core.windows.net/websiteimages/career-assets/culture1.svg"
            }
            bgColor={"#161617"}
            imgDirection={"left"}
          />
          <OurCulterCommon
            subheading={"Our Journey"}
            heading={"Over 500+ Completed Work And Still Counting"}
            description={
              "Hiring a custom enterprise software development company is standard practice for businesses that want to keep up with the ever-evolving technological norms in the post-pandemic world. Robust, scalable, secure, and customized enterprise software solutions can make any business more transparent, scalable, and resource-efficient. "
            }
            icon={
              "https://abswebsiteassets.blob.core.windows.net/websiteimages/career-assets/culture2.svg"
            }
            bgColor={"#161617"}
            imgDirection={"right"}
          />
        </div>
      </div>
    </div>
  );
};

export default OurCulture;
