// src/components/EngagementTools.js

import React from "react";
import { FiChevronRight } from "react-icons/fi";

import { engagementToolsData } from "../../components/data/HomeData";
import { useNavigate } from "react-router-dom";

const EngagementTools = () => {
  const navigate = useNavigate();

  const handleGotoSubMenuRespectivePage = (heading) => {
    // Check heading to determine the correct section ID
    if (heading === "Fixed-Cost Product Development") {
      navigate(`/pricing#fixed-cost-product-development`);
    } else if (heading === "Dedicated Tech Team") {
      navigate(`/pricing#dedicated-tech-team`);
    } else if (heading === "Maintenance and Upgradation") {
      navigate(`/pricing#maintenance-and-upgradation`);
    }
  };

  return (
    <div className=" bg-[var(--background-color)] text-[var(--text-color)] px-6 md:px-16">
      <div className="mx-auto max-w-7xl">
        <h2 className="text-3xl md:text-[56px] md:leading-[56px] text-center font-semibold md:mb-14 mb-5">
          Tailored Solutions for Your AI Journey : Engagement Models at
          Abstrabit
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-8">
          {engagementToolsData.map((tool, index) => (
            <div
              key={index}
              className="rounded relative mb-10"
              style={{
                backgroundColor: `${tool.color}`,
              }}
            >
              <img
                src={tool.imageUrl}
                alt={tool.title}
                className="w-full h-auto rounded"
              />
              <div className="space-y-2  lg:space-y-4 p-5 mb-16">
                <h2 className="text-[var(--text-color)] text-2xl md:text-4xl font-semibold">
                  {tool.title}
                </h2>
                <p className="text-[var(--text-color)] font-medium md:text-xl text-base">
                  {tool.description}
                </p>
                <ul className="list-inside">
                  {tool.list.map((text, idx) => {
                    const formattedText = text
                      .replace(
                        /Ideal for/g,
                        '<span class="font-bold">Ideal for</span>'
                      )
                      .replace(
                        /Benefits/g,
                        '<span class="font-bold">Benefits</span>'
                      );

                    return (
                      <li
                        key={idx}
                        className="text-base  md:text-lg "
                        dangerouslySetInnerHTML={{ __html: formattedText }}
                      />
                    );
                  })}
                </ul>
              </div>
              <button
                onClick={() => handleGotoSubMenuRespectivePage(tool.title)}
                className="w-36 justify-center mx-5 my-5 absolute inset-x-0 bottom-0  flex items-center hover:bg-[var(--background-color-white)] hover:text-red-500 text-base md:text-lg font-semibold  border border-white rounded-3xl bg-transparent text-[#ffffff] py-2 lg:py-2 "
              >
                Read More <FiChevronRight className="mt-1" />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EngagementTools;
