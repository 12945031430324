import React from "react";

const StatsSection = () => {
  return (
    <div className="gradient-orange relative -right-5 lg:-right-16 ms-10 -mt-12 lg:-mt-20   text-[var(--text-color)] py-3 md:py-5">
      <div className="px-6 py-2 flex  gap-2 lg:gap-10 justify-center items-center">
        {/* First Column */}
        <div className="flex flex-col items-start md:mb-0">
          <div className="lg:text-5xl text-lg font-semibold">50+</div>
          <p className="md:text-base text-sm font-semibold">Global Clients</p>
        </div>

        {/* Second Column */}

        <div className="flex flex-col">
          <div className="lg:text-5xl text-lg font-semibold">100+</div>
          <p className="md:text-base text-sm  font-semibold">
            Projects Delivered
          </p>
        </div>

        <div className="">
          <div className="flex gap-1 mb-1">
            <div className="md:w-3 md:h-3 h-2 w-2 bg-[#FCFCFC99] rounded-full"></div>
            <div className="md:w-3 md:h-3 h-2 w-2 bg-[#FCFCFC99] rounded-full"></div>
            <div className="md:w-3 md:h-3 h-2 w-2 bg-[#FCFCFC99] rounded-full"></div>
          </div>

          <div className="flex items-end justify-end gap-1 mb-1">
            <div className="md:w-3 md:h-3 h-2 w-2 bg-[#FCFCFC99] rounded-full"></div>
            <div className="md:w-3 md:h-3 h-2 w-2 bg-[#FCFCFC99] rounded-full"></div>
          </div>
          <div className="flex justify-end">
            <div className="md:w-3 md:h-3 h-2 w-2 bg-[#FCFCFC99] rounded-full"></div>
          </div>
        </div>

        {/* Dot Pattern */}
      </div>
    </div>
  );
};

export default StatsSection;
