import React, { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import {
  dedicatedTeamSection1,
  dedicatedTeamSection2,
  dedicatedTeamSection3,
  dedicatedTeamSection4,
  dedicatedTeamSection5,
  fixedProductDevelopmentSection1,
  fixedProductDevelopmentSection2,
  fixedProductDevelopmentSection3,
  fixedProductDevelopmentSection4,
  fixedProductDevelopmentSection5,
  maintainUpgrageSection1,
  maintainUpgrageSection2,
  maintainUpgrageSection3,
  maintainUpgrageSection4,
  maintainUpgrageSection5,
} from "../../components/data/EngagementData";
import EngagementTableCommon from "../../components/common/EngagementTableCommon";

const EngagementTable = ({ setActiveTab, activeTab }) => {
  const tableOfContents = [
    {
      id: 1,
      title: "Fixed Cost Product Development",
      link: "#fixed-cost-product-development",
    },
    { id: 2, title: "Dedicated Tech Team", link: "#dedicated-tech-team" },
    {
      id: 3,
      title: "Maintenance and Upgradation",
      link: "#maintenance-and-upgradation",
    },
  ];

  const [animatingTab, setAnimatingTab] = useState(0);

  const handleTabClick = (index) => {
    setAnimatingTab(index);
    setTimeout(() => {
      setActiveTab(index);
    }, 200);
  };

  const [isMobileViewOpen, setIsMobileViewOpen] = useState(false);

  return (
    <div className="bg-[var(--background-color)] text-[var(--text-color)] px-6 md:px-5 lg:px-16 max-w-7xl mx-auto">
      <div
        onClick={() => setIsMobileViewOpen(true)}
        className="fixed   bottom-52 z-50  text-base font-bold cursor-pointer bg-white text-purple-600 px-4 py-2 rounded-l-lg block md:hidden  right-0"
      >
        Content
      </div>
      <Tabs
        className="grid grid-cols-1 overflow-visible  md:grid-cols-3 gap-8 lg:gap-16"
        selectedIndex={activeTab}
        onSelect={(index) => handleTabClick(index)}
      >
        <div className="md:col-span-2">
          {" "}
          <TabPanel>
            <div
              id="fixed-cost-product-development"
              className={`tab-content ${animatingTab === 0 ? "slide-in" : ""}`}
            >
              <div className="lg:order-first col-span-2">
                <EngagementTableCommon
                  section1={fixedProductDevelopmentSection1}
                  section2={fixedProductDevelopmentSection2}
                  section3={fixedProductDevelopmentSection3}
                  section4={fixedProductDevelopmentSection4}
                  section5={fixedProductDevelopmentSection5}
                  tab={"fixedProduct"}
                  activeTab={activeTab}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div
              id="dedicated-tech-team"
              className={`tab-content ${animatingTab === 1 ? "slide-in" : ""}`}
            >
              <div className="lg:order-first col-span-2">
                <EngagementTableCommon
                  section1={dedicatedTeamSection1}
                  section2={dedicatedTeamSection2}
                  section3={dedicatedTeamSection3}
                  section4={dedicatedTeamSection4}
                  section5={dedicatedTeamSection5}
                  tab={"dedicated"}
                  activeTab={activeTab}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div
              id="maintenance-and-upgradation"
              className={`tab-content ${animatingTab === 2 ? "slide-in" : ""}`}
            >
              <div className="lg:order-first col-span-2">
                <EngagementTableCommon
                  section1={maintainUpgrageSection1}
                  section2={maintainUpgrageSection2}
                  section3={maintainUpgrageSection3}
                  section4={maintainUpgrageSection4}
                  section5={maintainUpgrageSection5}
                  tab={"maintain"}
                  activeTab={activeTab}
                />
              </div>
            </div>
          </TabPanel>
        </div>
        <div className="min-h-screen md:block hidden">
          <aside className="z-40 sticky top-20 mt-20">
            <TabList className="bg-[#161617] px-3.5 py-5 flex-col flex justify-start items-start gap-2.5 relative">
              <h3 className="text-4xl font-semibold mb-4">Engagement Models</h3>
              {tableOfContents.map((item) => (
                <Tab
                  key={item.id}
                  selectedClassName="tableContent outline-none ps-3 cursor-pointer gradient-purple text-xl ps-3 p-2 rounded-2xl w-full"
                  className="tableContent outline-none cursor-pointer text-xl ps-3 p-2 rounded-2xl w-full"
                >
                  {item?.title}
                </Tab>
              ))}
            </TabList>
          </aside>
        </div>
        {isMobileViewOpen && (
          <div
            className={`fixed overflow-auto top-16 left-0 w-[393px] h-full bg-[#161617] bg-opacity-50 z-40 ${
              isMobileViewOpen ? "block" : "hidden"
            }`}
          >
            <div className="w-full p-2 md:hidden block my-16 mx-auto bg-[#161617] rounded-lg shadow-lg">
              <aside className="md:hidden block">
                <div className="text-white pe-6 flex items-center justify-between">
                  <div></div>
                  <span
                    onClick={() => setIsMobileViewOpen(false)}
                    className="text-xl cursor-pointer"
                  >
                    X
                  </span>
                </div>
                <TabList className="bg-[#161617] px-3.5 py-5 flex-col flex justify-start items-start gap-2.5">
                  <h3 className="md:text-4xl text-3xl font-semibold mb-4">
                    Engagement Models
                  </h3>
                  {tableOfContents.map((item) => (
                    <Tab
                      onClick={() => setIsMobileViewOpen(false)}
                      key={item.id}
                      selectedClassName="tableContent outline-none ps-3 cursor-pointer gradient-purple text-lg ps-3 p-2 rounded-2xl w-full"
                      className="tableContent outline-none cursor-pointer text-lg ps-3 p-2 rounded-2xl w-full"
                    >
                      {item?.title}
                    </Tab>
                  ))}
                </TabList>
              </aside>
            </div>
          </div>
        )}
      </Tabs>
    </div>
  );
};

export default EngagementTable;
