export const fixedProductDevelopmentSection1 = [
  {
    heading1: "Fixed Cost Product Development",
    heading2: "Predictable Path to",
    heading3: "Project Success",

    description:
      "Are you looking to build a new product with a clear vision and a fixed budget? ",
    subdescription:
      "Abstrabit's Fixed-Cost Product Development model is the perfect solution for you. This engagement model provides a structured and cost-effective approach to transforming your product idea into reality.",
  },
];

export const fixedProductDevelopmentSection2 = [
  {
    heading1: "What is",
    heading2: "Fixed Cost Product",
    heading3: "Development ?",
    description:
      "Our Fixed-Cost Product Development model offers a clear and defined scope of work with a predetermined budget and timeline. This approach provides you with:",

    list: [
      {
        heading: "Predictable Costs",
        list1:
          "We know exactly what you'll pay upfront, eliminating budget uncertainties.",
      },
      {
        heading: "Streamlined Process",
        list1:
          "Benefit from a focused development approach with clear deliverables.",
      },
      {
        heading: "Clear Timeline",
        list1:
          "Enjoy a defined project schedule with milestones for tracking progress.",
      },
    ],

    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/engagement-assets/fixedI.svg",
  },
];

export const fixedProductDevelopmentSection3 = [
  {
    heading1: "When to Choose",
    heading2: "Fixed Cost Product",
    heading3: "Development ?",
    description: "This model is ideal for projects where:",

    list: [
      {
        list1: "The product requirements are well-defined and documented.",
      },
      {
        list1: "There is a clear understanding of the desired outcome.",
      },
      {
        list1: "Budget constraints are a primary consideration.",
      },
    ],
  },
];

export const fixedProductDevelopmentSection4 = [
  {
    heading: "How it Works?",

    list: [
      {
        heading: "Project Scoping",
        list1:
          "We collaborate with you to define project goals, features, and deliverables.",
      },
      {
        heading: "Cost Estimation",
        list1:
          "We provide a detailed cost estimate based on the project scope.",
      },
      {
        heading: "Contract Finalisation",
        list1:
          "We create a comprehensive contract outlining project terms, timelines, and deliverables.",
      },
      {
        heading: "Development Phase",
        list1:
          "Our expert team executes the project according to the agreed-upon plan.",
      },
      {
        heading: "Quality Assurance",
        list1:
          "Rigorous testing ensures the product meets your specifications.",
      },
      {
        heading: "Delivery and Launch",
        list1: "We deliver the final product and provide necessary support.",
      },
    ],
  },
];

export const fixedProductDevelopmentSection5 = [
  {
    heading1: "Benefits of",
    heading2: "Fixed Cost Product",
    heading3: "Development ?",
    list: [
      {
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/engagement-assets/risk.svg",
        heading: "Reduced Risk",
      },
      {
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/engagement-assets/quanlity.svg",
        heading: "Improved Quality",
      },
      {
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/engagement-assets/collaboration.svg",
        heading: "Enhanced Collaboration",
      },
      {
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/engagement-assets/faster.svg",
        heading: "Faster Time-to-Market",
      },
    ],
  },
];

export const dedicatedTeamSection1 = [
  {
    heading1: "Dedicated Tech Team",
    heading2: "Scale Your Team,",
    heading3: "Accelerate Your Vision",

    description:
      "Need to ramp up your development efforts without the hassle of recruitment and management?",
    subdescription:
      "Abstrabit's Dedicated Tech Team model offers you a flexible and scalable solution. Our experienced engineers become an extension of your team, working seamlessly alongside your in-house teams to achieve your product goals.",
  },
];

export const dedicatedTeamSection2 = [
  {
    heading1: "What is a",
    heading2: "Dedicated",
    heading3: "Tech Team?",
    description:
      "Our Dedicated Tech Team model provides you with a tailored group of skilled engineers who focus exclusively on your project. This dedicated team operates as an extension of your in-house development function, providing:",

    list: [
      {
        heading: "Flexibility",
        list1: "Easily adjust team size based on project demands.",
      },
      {
        heading: "Expertise",
        list1:
          "Access a pool of specialized engineers with diverse skill sets.",
      },
      {
        heading: "Cost Efficiency",
        list1: "Optimize resource allocation and reduce overhead.",
      },
    ],

    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/engagement-assets/dedicated.svg",
  },
];

export const dedicatedTeamSection3 = [
  {
    heading1: "When to Choose a",
    heading2: "Dedicated",
    heading3: "Tech Team?",
    description: "This model is ideal for projects where:",

    list: [
      {
        list1: "Projects requiring specialized skills or expertise.",
      },
      {
        list1: "Scaling your development team to meet fluctuating workloads.",
      },
      {
        list1: "Accelerating time-to-market for new products or features.",
      },
      {
        list1: "Offloading development tasks to focus on core competencies.",
      },
    ],
  },
];

export const dedicatedTeamSection4 = [
  {
    heading: "How it Works?",

    list: [
      {
        heading: "Team Formation",
        list1:
          "We carefully select engineers based on your project requirements and cultural fit.",
      },
      {
        heading: "Onboarding",
        list1:
          "The team integrates seamlessly into your development environment and processes.",
      },
      {
        heading: "Collaboration",
        list1:
          "Our engineers work closely with your team to achieve shared objectives.",
      },
      {
        heading: "Continuous Improvement",
        list1:
          "We foster a culture of innovation and learning within the team.",
      },
    ],
  },
];

export const dedicatedTeamSection5 = [
  {
    heading1: "Benefits of",
    heading2: "Dedicated",
    heading3: "Tech Team?",
    list: [
      {
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/engagement-assets/productivity.svg",
        heading: "Increased Productivity",
      },
      {
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/engagement-assets/migration.svg",
        heading: "Risk Mitigation",
      },
      {
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/engagement-assets/coin.svg",
        heading: "Reduced Costs",
      },
      {
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/engagement-assets/innovation.svg",
        heading: "Enhanced Innovation",
      },
    ],
  },
];

export const maintainUpgrageSection1 = [
  {
    heading1: "Maintenance and Upgradation",
    heading2: "Extend Your Product’s",
    heading3: "Lifespan and Performance",

    description: "A successful product requires ongoing care and attention.",
    subdescription:
      "Abstrabit's Maintenance and Upgradation services ensure your product stays robust, secure, and aligned with evolving user needs. Our dedicated team provides comprehensive support to optimize your product's performance and longevity.",
  },
];

export const maintainUpgrageSection2 = [
  {
    heading1: "What is",
    heading2: "Maintainence and",
    heading3: "Upgradation",
    description:
      "Our Maintenance and Upgradation services cover a wide range of activities to keep your product running smoothly and competitively:",

    list: [
      {
        heading: "Bug Fixing",
        list1: "Identifying and resolving issues to enhance product stability.",
      },
      {
        heading: "System Optimization",
        list1: "Improving speed, responsiveness, and efficiency.",
      },
      {
        heading: "Feature Refinement",
        list1: "Adding new functionalities to meet changing user demands.",
      },
      {
        heading: "Technology Upgrades",
        list1:
          "Modernizing your product's technology stack for scalability and performance.",
      },
    ],

    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/engagement-assets/mainUpd.svg",
  },
];

export const maintainUpgrageSection3 = [
  {
    heading1: "When to Choose",
    heading2: " Maintainence and Upgradation?",
    heading3: "",
    description: "This model is ideal for projects where you need:",

    list: [
      {
        list1: "Adding new functionalities to meet changing user demands.",
      },
      {
        list1: "We implement preventive measures to avoid issues.",
      },
      {
        list1: "Quick turnaround times for bug fixes and emergencies.",
      },
      {
        list1: "Optimize your maintenance budget.",
      },
      {
        list1: "We focus on enhancing product performance and user experience.",
      },
    ],
  },
];

export const maintainUpgrageSection4 = [
  {
    heading: "How it Works?",

    list: [
      {
        heading: "Needs Assesment",
        list1:
          "We evaluate your product's current state and identify maintenance requirements.",
      },
      {
        heading: "Maintainence Plan",
        list1:
          "We create a customized maintenance plan tailored to your needs.",
      },
      {
        heading: "Ongoing Support",
        list1:
          "Our team provides regular maintenance, bug fixes, and performance optimization.",
      },
      {
        heading: "Upgradation Planning",
        list1:
          "We collaborate to identify opportunities for product enhancements.",
      },
      {
        heading: "Implementation",
        list1: "We execute upgrades and new feature development.",
      },
    ],
  },
];

export const maintainUpgrageSection5 = [
  {
    heading1: "Benefits of",
    heading2: "Maintenance",
    heading3: "and Upgradation",
    list: [
      {
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/engagement-assets/security.svg",
        heading: "Mitigated Security Risks  ",
      },
      {
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/engagement-assets/lifespan.svg",
        heading: "Increased Product Lifespan",
      },
      {
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/engagement-assets/userE.svg",
        heading: "Enhanced User Experience",
      },
      {
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/engagement-assets/downtime.svg",
        heading: "Reduce Downtime",
      },
    ],
  },
];
