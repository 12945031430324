import React from "react";
import MetaData from "../../components/layout/MetaData";
import AboutReviewSection from "./AboutReviewSection";
import StatsSection from "./StateSection";
import ButtonComponent from "../../components/common/ButtonComponent";

const AboutHero = () => {
  return (
    <div
      className="flex  bg-no-repeat  text-[#fcfcfc] flex-col items-start justify-start pt-14  lg:pt-20 bg-cover bg-center"
      style={{
        backgroundImage:
          "url(https://abswebsiteassets.blob.core.windows.net/websiteimages/about-assets/aboutbg.svg)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <MetaData title="About - Abstrabit Technologies" />
      <div className="flex px-6 md:px-16 max-w-7xl mx-auto lg:mb-10 gap-5 items-center md:items-start justify-center md:justify-between flex-col w-full">
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-14 mb-4">
          <div className="flex space-y-9 mt-14 animate-fade-in-down  flex-col items-center md:items-start justify-center md:justify-start">
            {/* <p className="text-[var(--text-color)] md:text-start text-center text-xl lg:text-4xl leading-6 font-semibold mb-5 mt-14">
                About Us
              </p> */}

            <div className="flex flex-col  lg:flex-row md:items-start items-center justify-center md:justify-start w-full relative font-semibold md:font-semibold text-4xl md:text-5xl lg:text-6xl">
              <p className=" text-center md:text-start lg:leading-[65px] flex flex-col">
                <span className=" mb-0"> Where </span>{" "}
                <span className="textColor mb-0"> Vision </span>{" "}
                <span className=" mb-0"> Meets </span>
                <span className=" mb-0"> Velocity </span>
              </p>
            </div>

            <p className="text-[var(--text-color)] md:text-start text-center text-base lg:text-xl leading-6 font-normal">
              We're on a mission to build safe, reliable AI that solves
              real-world problems. We translate complex data science into
              tangible results, bridging the gap between your business goals and
              the power of AI.
            </p>

            <ButtonComponent />
          </div>
          <div className="animate-fade-in-right flex flex-col items-center mt-10 lg:mt-14 justify-center">
            <img
              src="https://abswebsiteassets.blob.core.windows.net/websiteimages/about-assets/abg2.svg"
              alt=""
              className="w-[500px]"
            />
            <StatsSection />
          </div>
        </div>

        <AboutReviewSection page={"about"} />
      </div>
    </div>
  );
};

export default AboutHero;
