import React, { useState } from "react";
import PortolioDetailCommon from "../../components/common/PortfolioDetailCommon";
import PortfolioChallenges from "./PortfolioChallenges";
import OutCome from "./OutCome";
import ExploreMoreProject from "./ExploreMoreProject";
import ReadyToGrowBrand from "./ReadyToGrowBrand";
import MetaData from "../../components/layout/MetaData";

const PortfolioDetails = () => {
  const [name, setName] = useState("");

  return (
    <div className="flex flex-col bg-[var(--background-color)] text-[var(--text-color)] gap-20 md:gap-32">
      <PortolioDetailCommon setName={setName} />
      <PortfolioChallenges />
      <OutCome />
      <ExploreMoreProject />
      <ReadyToGrowBrand />
      <MetaData
        title={`${name} - Portfolio Details - Abstrabit Technologies`}
      />
    </div>
  );
};

export default PortfolioDetails;
