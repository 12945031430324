import React from "react";

const PowerTechnology = () => {
  return (
    <div className="bg-[var(--background-color)] text-[var(--text-color)]">
      <div className="max-w-7xl mx-auto ">
        <div className="px-6 md:px-16  text-center ">
          <h2 className="text-3xl md:-space-y-0 -space-y-4  lg:space-y-1 flex flex-col items-center justify-center md:text-[56px] font-semibold mb-5 md:mb-14">
            <span>We</span>
            <div className="lg:-space-y-3 md:-space-y-5 -space-y-9 flex flex-col">
              <span className="textColorTech font-semibold  md:leading-10">
                Build the Future with
              </span>
              <span className="textColorTech">Generative AI</span>
            </div>
          </h2>

          <div className="grid grid-cols-1 items-center justify-start md:grid-cols-2 gap-5 bg-[var(--background-color)] text-[var(--text-color)] md:px-8">
            <img
              src="https://abswebsiteassets.blob.core.windows.net/websiteimages/about-assets/pt.svg"
              alt=""
              className="h-full object-cover"
            />

            <div className="flex-1 text-center md:text-start lg:text-left lg:ml-8">
              <p className="text-base md:text-base lg:text-xl text-gray-300 ">
                At Abstrabit, we’re all about delivering exceptional customer
                experience. We’re passionate about being your partner in
                success, and we believe that
              </p>
              <h2 className="text-4xl md:text-start text-center md:text-4xl lg:text-[56px] leading-10  font-semibold textColor mt-5">
                <span className="lg:leading-[60px]">
                  When our customers thrive, We thrive too.
                </span>
              </h2>

              <div className="text-center flex justify-center  md:justify-start">
                {" "}
                <img
                  src="https://abswebsiteassets.blob.core.windows.net/websiteimages/about-assets/batch.svg"
                  alt=""
                  className="mt-5  w-24 md:w-28 text-center flex justify-center items-center"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PowerTechnology;
