export const projectsPortfolioData = [
  {
    title: "Voyage Pro",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/ow1.svg", // replace with the path to the image
    description:
      "Explore the wonders of VoyagePro, a feature-rich platform tailored for photographers and video creators.",
  },
  {
    title: "Soulplay",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/ow2.svg",
    description:
      "A unique mobile platform that blends friends' activities with exciting challenges, bringing joy to your fingertips.",
  },

  {
    title: "Thirty2Give",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/ow4.svg",
    description:
      "A mentoring platform that allows users to connect with mentors for guidance and support.",
  },
  {
    title: "Compliance Help",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/ow5.svg",
    description:
      "Compliance Help provides a toolkit for individuals and businesses needing assistance with regulatory compliance.",
  },
  {
    title: "Voyage Pro",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/ow1.svg",
    description:
      "Explore the wonders of VoyagePro, a feature-rich platform tailored for photographers and video creators.",
  },

  {
    title: "Karmikh",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/ow3.svg",
    description:
      "An e-commerce platform that connects buyers with hand-picked, handcrafted products created by skilled artisans.",
  },
];

export const portfolioChallengesData = [
  {
    id: 1,
    heading: "Poor Brand Visibility",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/cp1.svg",
    color: "linear-gradient(180deg, #281452 0%, #BB10A4 100%)",
    text: "We develop custom AI software to automate processes <br /> boosting efficiency and freeing your team to focus on strategic <br /> initiatives. Our collaborative approach ensures solutions align <br /> perfectly with your unique business needs. We validate ideas  <br /> rapidly with an MVP, minimizing risk and maximizing <br /> impact. This empowers your business to continuously innovate <br /> at scale...",
  },
  {
    id: 2,
    heading: "Low Organic Traffic",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/cp2.svg",
    color: "linear-gradient(180deg, #1288B0 0%, #2E7161 100%)",
    text: "We develop custom AI software to automate processes <br /> boosting efficiency and freeing your team to focus on strategic <br /> initiatives. Our collaborative approach ensures solutions align <br /> perfectly with your unique business needs. We validate ideas <br /> rapidly with an MVP, minimizing risk and maximizing <br /> impact. This empowers your business to continuously innovate <br /> at scale...",
  },
  {
    id: 3,
    heading: "Unoptimised Website",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/cp-3.svg",
    color: "linear-gradient(180deg, #A53DE5 0%, #0F26B4 100%)",
    text: "We develop custom AI software to automate processes <br /> boosting efficiency and freeing your team to focus on strategic <br /> initiatives. Our collaborative approach ensures solutions align <br /> perfectly with your unique business needs. We validate ideas <br /> rapidly with an MVP, minimizing risk and maximizing <br /> impact. This empowers your business to continuously innovate <br /> at scale...",
  },
  {
    id: 4,
    heading: "Confusing Navigation",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/cp-4.svg",
    color: "linear-gradient(180deg, #4637B6 0%, #3671C3 100%)",
    text: "Karmikh’s website did not properly showcase their extensive product range in a way that was valuable or engaging to website visitors, as it lacked proper categorization.",
  },
];
