export const Tab1Card = [
  {
    title: "Understanding Your Objectives",
    description:
      "We begin by comprehensively analyzing your business goals and challenges.",
  },
  {
    title: "Identifying Key Challenges",
    description:
      "Pinpoint specific obstacles that AI solutions can effectively address.",
  },
  {
    title: "Tailoring AI Solutions",
    description:
      "Crafting AI solutions precisely aligned with your unique business needs.",
  },
  {
    title: "Developing a Strategic Plan",
    description:
      "Creating a clear roadmap for AI integration into your processes.",
  },
  {
    title: "Implementing the Strategy",
    description:
      "Seamlessly executing the AI strategy to achieve desired outcomes.",
  },
  {
    title: "Continuous Optimization",
    description:
      "Regularly refining the AI solutions to ensure sustained success.",
  },
];

export const Tab2Card = [
  {
    title: "Analyzing Your Requirements",
    description:
      "Thoroughly understanding your specific needs to inform solution design.",
  },
  {
    title: "Identifying Key Functionalities",
    description:
      "Defining essential features that align with your operational goals.",
  },
  {
    title: "Tailoring Design Approach",
    description:
      "Customizing AI solution design to meet your unique requirements.",
  },
  {
    title: "Developing the Solution",
    description:
      "Building AI solutions that seamlessly integrate with your workflows.",
  },
  {
    title: "Ensuring Seamless Integration",
    description:
      "Implementing solutions to enhance and streamline your operations.",
  },
  {
    title: "Ongoing Enhancement",
    description:
      "Continuously refining solutions for optimal performance and adaptability.",
  },
];

export const Tab3Card = [
  {
    title: "Assessing Existing Systems",
    description:
      "Evaluating your current setup to plan effective AI integration.",
  },
  {
    title: "Identifying Integration Points",
    description: "Pinpointing where AI can enhance your existing workflows.",
  },
  {
    title: "Customizing Integration Plan",
    description:
      "Tailoring a strategy to ensure smooth and efficient AI integration.",
  },
  {
    title: "Implementing the Integration",
    description:
      "Seamlessly integrating AI with minimal disruption to operations.",
  },
  {
    title: "Testing and Validation",
    description:
      "Ensuring integrated AI solutions function flawlessly with your systems.",
  },
  {
    title: "Ongoing Support",
    description:
      "Providing continuous support to maintain and optimize integration.",
  },
];

export const Tab4Card = [
  {
    title: "Continuous Monitoring",
    description:
      "Regularly track AI system performance to ensure optimal functionality.",
  },
  {
    title: "Adaptive Optimization",
    description:
      "Update and enhance AI solutions to align with evolving needs.",
  },
  {
    title: "Proactive Maintenance",
    description:
      "Perform routine checks to prevent system disruptions and ensure reliability.",
  },
  {
    title: "User Training and Support",
    description:
      "Provide ongoing training to maximize AI solution effectiveness and usage.",
  },
  {
    title: "Ongoing Support and Optimization",
    description:
      "Continuously refine and support AI solutions to maintain effectiveness.",
  },
  {
    title: "Measuring Success and Impact",
    description: "Track KPIs and analyze AI's impact to ensure value delivery.",
  },
];

export let imgData = [
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/js.svg",
    text: "Javascript",
  },
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/react.svg",
    text: "React Js",
  },
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/io.svg",
    text: "Tableau",
  },
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/aws.svg",
    text: "AWS",
  },
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/un1.svg",
    text: "Flutter",
  },
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/un2.svg",
    text: "Tensorflow",
  },
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/un3.svg",
    text: "Cib Pytorch",
  },
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/figma.svg",
    text: "Figma",
  },
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/un4.svg",
    text: "Scikit-learn",
  },
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/js1.svg",
    text: "Node Js",
  },
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/un5.svg",
    text: "Keras",
  },
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/python.svg",
    text: "Python",
  },
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/un6.svg",
    text: "Matplotlib",
  },
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/un7.svg",
    text: "Devicon:R",
  },
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/un8.svg",
    text: "Numpy",
  },
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/c++.svg",
    text: "C++",
  },
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/java.svg",
    text: "",
  },
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/un9.svg",
    text: "Pandas",
  },
];

//AI Strategy & Consulting
export const aiStrategyConsultingDataHome = [
  {
    title: "Revolutionize your business with AI",
    description:
      "Optimize your business processes through AI-driven solutions.",
    list: [
      "Data-Driven AI Transformation",
      "Customized AI Solutions",
      "Proven AI Expertise",
    ],
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/spt.svg",
  },
];

export const aiStrategyConsultingDataSection2 = [
  {
    heading: "Unlock the full potential of AI for your business.",
    desc: "Our expert AI strategy and consulting services help you navigate the complex AI landscape and drive tangible business results.",
    list: [
      {
        title: ["Data-Driven", "AI Transformation"],
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/ai1.svg",
        hoverIcon:
          "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/hai.svg",
        description:
          "We turn your data into a competitive advantage. Our data-centric AI strategies optimize operations, predict market trends, and drive innovation.",
      },
      {
        title: ["Customized", "AI Solutions"],
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/ex1.svg",
        hoverIcon:
          "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/haie.svg",
        description:
          "We understand that every business is unique. Our customized AI solutions align with your specific goals, ensuring maximum ROI and business impact.",
      },
      {
        title: ["Proven", "AI Expertise"],
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/data1.svg",
        hoverIcon:
          "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/hdata.svg",
        description:
          "With years of experience and a proven track record, we deliver actionable AI strategies that drive tangible business outcomes.",
      },
    ],
  },
];

export const aiStrategyConsultingDataSection3 = [
  {
    id: 1,
    heading: "Optimize Supply Chain with AI",
    challenge:
      "Inefficient supply chain operations leading to high costs and stockouts.",
    solution:
      "Develop a comprehensive AI strategy to optimize inventory management, demand forecasting, and logistics. Implement AI-powered systems for predictive maintenance of equipment and real-time shipment tracking.",
  },
  {
    id: 2,
    heading: "Enhance Customer Experience through AI",
    challenge:
      "Difficulty in understanding customer needs and preferences, leading to low customer satisfaction.",
    solution:
      "Implement AI-driven customer analytics to gain insights into customer behavior and preferences. Develop personalized marketing campaigns and customer service strategies. Create AI-powered chatbots for efficient customer support.",
  },
  {
    id: 3,
    heading: "Accelerate Product Development with AI",
    challenge:
      "Slow and costly product development cycles, leading to delayed market entry.",
    solution:
      "Leverage AI for product design optimization, materials selection, and testing. Utilize AI-powered predictive analytics to identify potential product failures early in the development process. Implement AI-driven market research to identify customer needs and preferences.",
  },
];

export const aiStrategyConsultingDataFaqData = [
  {
    id: 1,
    question: "What is AI Consulting and how can it benefit my business?",
    answer:
      "AI Consulting involves working with experts to develop and implement an AI strategy tailored to your specific business goals. This can help you identify opportunities to use AI to improve efficiency, increase revenue, enhance customer experience, and gain a competitive edge. Our consultants will assess your business, identify potential AI applications, develop a roadmap, and support implementation.",
  },
  {
    id: 2,
    question: "How do I develop an AI strategy?",
    answer:
      "Developing an AI strategy requires a clear understanding of your business objectives, data capabilities, and the AI landscape. Our consultants follow a structured approach involving:",
    list: [
      "Business Assessment: Analyzing your industry, competitors, and internal operations.",
      "AI Opportunity Identification: Identifying potential AI applications aligned with your goals.",
      "Strategy Development: Creating a roadmap for AI implementation, including technology selection, data management, and talent development.",
      "Implementation Support: Providing guidance and support throughout the AI implementation process.",
    ],
  },
  {
    id: 3,
    question: "What are the key components of an AI strategy?",
    answer: "A comprehensive AI strategy typically includes:",
    list: [
      "Business Goals and Objectives: Clearly defined business outcomes you want to achieve with AI.",
      "Data Strategy: Assessing data quality, availability, and governance.",
      "Technology Roadmap: Selecting and prioritizing AI technologies based on your needs.",
      "Talent Development: Building or acquiring the necessary AI skills within your organization.",
      "Ethical Considerations: Addressing AI ethics and responsible AI practices.",
      "Risk Management: Identifying and mitigating potential risks associated with AI implementation.",
    ],
  },
  {
    id: 4,
    question: "How can AI drive business growth?",
    answer: "AI can drive business growth in various ways, including:",
    list: [
      "Process Optimization: Automating repetitive tasks and improving efficiency.",
      "Product Innovation: Developing new AI-powered products and services.",
      "Customer Experience Enhancement: Personalizing customer interactions and improving satisfaction.",
      "Data-Driven Decision Making: Leveraging AI for predictive analytics and insights.",
      "New Revenue Streams: Creating new business models based on AI capabilities.",
    ],
  },
  {
    id: 5,
    question: "What industries can benefit from AI consulting?",
    answer:
      "AI consulting can benefit businesses across a wide range of industries, including:",
    list: [
      "Healthcare: Improving diagnostics, drug discovery, and patient care.",
      "Finance: Enhancing fraud detection, risk assessment, and customer service.",
      "Retail: Optimizing inventory management, personalized marketing, and customer recommendations.",
      "Manufacturing: Improving production efficiency, predictive maintenance, and quality control.",
      "Marketing and Sales: Enhancing customer segmentation, lead scoring, and sales forecasting.",
    ],
  },
];

export const aiStrategyConsultingDataSection5 = [{}];

//generative ai

export const generativeAiDataHome = [
  {
    title: "Breakthrough Creativity. Powered by AI.",
    description:
      "Push the boundaries of what's possible. Our end-to-end generative AI solutions transform text, code, and data into entirely new creative content. Our generative AI solutions unlock a new era of creative possibilities for your business.",
    list: [
      "AI Expertise & Human Touch",
      "Customizable Solutions",
      "Scalable and Efficient",
    ],
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/spt2.svg",
  },
];

export const generativeAiDataSection2 = [
  {
    heading: "Generate. Refine. Perfect.",
    desc: "We guide you through the entire generative AI journey, from initial concept to final masterpiece. Leverage our expertise to tailor AI models, refine outputs, and achieve exceptional creative results.",
    list: [
      {
        title: ["AI Expertise", "& Human Touch"],
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/ai1.svg",
        hoverIcon:
          "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/hai.svg",
        description:
          "We combine cutting-edge generative AI models with human creativity and strategic guidance to deliver impactful results.",
      },
      {
        title: ["Customizable", "Solutions"],
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/ex1.svg",
        hoverIcon:
          "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/haie.svg",
        description:
          "We tailor our approach to your unique needs, whether you require text generation, code creation, or data-driven artistic exploration.",
      },
      {
        title: ["Scalable", "and Efficient"],
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/data1.svg",
        hoverIcon:
          "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/hdata.svg",
        description:
          "Automate repetitive tasks and generate high volumes of content efficiently, freeing up your team to focus on strategic initiatives.",
      },
    ],
  },
];

export const generativeAiDataSection3 = [
  {
    id: 1,
    heading: "Content Creation Powerhouse",
    challenge:
      "Generate unique and engaging marketing copy, product descriptions, and social media content.",
    solution:
      "Develop compelling scripts, storylines, and even poems fueled by AI creativity.",
    // Replace with the appropriate icon variable or path
  },
  {
    id: 2,
    heading: "The Future of Coding",
    challenge:
      "Generate code snippets, automate repetitive programming tasks, and assist with debugging processes.",
    solution:
      "Leverage AI to explore new coding possibilities and accelerate development cycles.",
    // Replace with the appropriate icon variable or path
  },
  {
    id: 3,
    heading: "Data-Driven Design Inspiration",
    challenge:
      "Translate data insights into visually stunning and innovative design concepts.",
    solution:
      "Generate original artwork, product designs, and marketing materials with unique AI-powered flair.",
    // Replace with the appropriate icon variable or path
  },
];

export const generativeDataFaqData = [
  {
    id: 1,
    question: "What type of content can generative AI create?",
    answer:
      "Generative AI can create a wide range of content, including text, code, images, music, and more.",
  },
  {
    id: 2,
    question: "Do I need any AI expertise to use your services?",
    answer:
      "No prior AI knowledge is necessary. Our team will guide you through the entire process.",
  },
  {
    id: 3,
    question: "Who owns the rights to the generated content?",
    answer:
      "You will own the rights to all content created through our generative AI solutions.",
  },
];

//ai chatbot solution

export const aiChatbotDataHome = [
  {
    title: "Engage Your Customers. 24/7. Effortlessly.",
    description:
      "Revolutionize your customer service with intelligent, personalized AI chatbots. We craft chatbots that understand your brand, answer questions, and drive results.",
    list: [
      "Custom-Built for Your Needs",
      "Advanced AI & NLP Technology",
      "Measurable ROI & Scalability",
    ],
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/spt3.svg",
  },
];

export const aiChatbotDataSection2 = [
  {
    heading: "The Future of Customer Interactions is Here.",
    desc: "Deliver exceptional customer experiences with the power of AI. Our chatbots engage your audience, automate tasks, and free up your team to focus on high-value interactions.",
    list: [
      {
        title: ["Custom-Built", "for Your Needs"],
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/ai1.svg",
        hoverIcon:
          "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/hai.svg",
        description:
          "We design chatbots that seamlessly integrate with your brand, messaging style, and specific customer touchpoints.",
      },
      {
        title: ["Advanced AI", "& NLP Technology"],
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/ex1.svg",
        hoverIcon:
          "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/haie.svg",
        description:
          "Leverage cutting-edge AI and Natural Language Processing (NLP) for accurate understanding and engaging conversations.",
      },
      {
        title: ["Measurable ROI", "& Scalability"],
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/data1.svg",
        hoverIcon:
          "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/hdata.svg",
        description:
          "Track chatbot performance, optimize interactions, and scale your AI-powered customer service effortlessly.",
      },
    ],
  },
];

export const aiChatbotDataSection3 = [
  {
    id: 1,
    heading: "Enhanced Customer Support",
    challenge:
      "Answer common questions 24/7, provide product information, and troubleshoot issues efficiently.",
    solution:
      "Offer personalized recommendations and support, leading to higher customer satisfaction.",
  },
  {
    id: 2,
    heading: "Streamlined Lead Generation",
    challenge:
      "Qualify leads, schedule appointments, and collect valuable customer data through engaging chatbot interactions.",
    solution:
      "Increase lead capture rates and conversion rates with personalized chatbot conversations.",
  },
  {
    id: 3,
    heading: "Interactive Brand Experiences",
    challenge:
      "Create engaging brand experiences that educate and entertain your audience.",
    solution:
      "Personalize interactions, answer product inquiries, and build stronger customer relationships.",
    // Replace with the appropriate icon variable or path
  },
];

export const chatbotFaqData = [
  {
    id: 1,
    question: "What platforms do your chatbots work on?",
    answer:
      "Our chatbots can be integrated with various platforms like websites, messaging apps, and social media channels.",
  },
  {
    id: 2,
    question: "Can I track the performance of my chatbot?",
    answer:
      "Absolutely! We provide detailed analytics and reporting to measure chatbot performance and identify areas for improvement.",
  },
  {
    id: 3,
    question:
      "What happens if my chatbot encounters a question it can't answer?",
    answer:
      "Our chatbots are designed to handle a wide range of inquiries. In rare cases, they can seamlessly hand off conversations to human agents for further assistance.",
  },
];

//ai assistant

export const aiAssistantDataHome = [
  {
    title: "Empower Your Clients with Instant Expertise.",
    description:
      "Revolutionize customer service with AI assistants tailored to your business. Deliver 24/7 support, improve knowledge accessibility, and boost customer satisfaction.",
    list: [
      "Brand-Specific Knowledge Base",
      "Seamless Integration",
      "Intelligent Search",
    ],
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/spt4.svg",
  },
];

export const aiAssistantDataSection2 = [
  {
    heading: "The Future of Customer Self-Service is Here.",
    desc: "Our custom AI knowledge assistants provide your customers with immediate access to the information they need, anytime, anywhere. Increase customer self-service rates and free up your team for more complex interactions.",
    list: [
      {
        title: ["Brand-Specific", "Knowledge Base"],
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/ai1.svg",
        hoverIcon:
          "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/hai.svg",
        description:
          "We build AI assistants with your specific knowledge in mind, including product information, FAQs, and troubleshooting guides.",
      },
      {
        title: ["Seamless", "Integration"],
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/ex1.svg",
        hoverIcon:
          "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/haie.svg",
        description:
          "Integrate the AI assistant seamlessly into your website, mobile app, or existing customer support channels.",
      },
      {
        title: ["Intelligent Search", "& Personalized Recommendations"],
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/data1.svg",
        hoverIcon:
          "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/hdata.svg",
        description:
          "Leverage AI to personalize customer interactions, deliver relevant information, and empower self-service.",
      },
    ],
  },
];

export const aiAssistantDataSection3 = [
  {
    id: 1,
    heading: "Enhanced Customer Self-Service",
    challenge:
      "Equip customers with 24/7 access to product knowledge, troubleshooting guides, and FAQs.",
    solution:
      "Reduce customer support tickets and calls, improving self-service resolution rates.",
    // Replace with the appropriate icon variable or path
  },
  {
    id: 2,
    heading: "Personalized Customer Journeys",
    challenge:
      "Utilize AI to understand customer needs and recommend relevant knowledge base articles or next best steps.",
    solution:
      "Deliver a personalized self-service experience that increases customer satisfaction.",
    // Replace with the appropriate icon variable or path
  },
  {
    id: 3,
    heading: "Proactive Customer Support",
    challenge:
      "Train AI assistants to identify and answer common customer questions before they arise.",
    solution:
      "Reduce customer frustration and improve overall support efficiency.",
    // Replace with the appropriate icon variable or path
  },
];

export const aiAssistantFaqData = [
  {
    id: 1,
    question: "Can I update the knowledge base in my AI assistant?",
    answer:
      "Absolutely! Your AI assistant is designed to be adaptable. You can easily update the knowledge base as your needs evolve.",
  },
  {
    id: 2,
    question: "How does this integrate with my existing support system?",
    answer:
      "Our AI assistants are designed for seamless integration with various customer support platforms.",
  },
  {
    id: 3,
    question:
      "What happens if a customer's question can't be answered by the AI assistant?",
    answer:
      "AI assistants can be programmed to route complex inquiries to human agents, ensuring a smooth customer experience.",
  },
];

//gpt ai automation

export const gptAiAutomationHome = [
  {
    title: "Supercharge Your Productivity with GPT Automation.",
    description:
      "Unleash the power of GPT-powered automation to streamline repetitive tasks, boost efficiency, and free up your team for strategic work.",
    list: [
      "GPT-Powered Intelligence",
      "Reduced Manual Work",
      "Improved Accuracy & Efficiency",
    ],
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/spt5.svg",
  },
];

export const gptAiAutomationSection2 = [
  {
    heading: "Work Smarter, Not Harder. Automate with GPT.",
    desc: "Our GPT automation solutions tackle time-consuming tasks, reduce errors, and empower your team to focus on high-value activities.",
    list: [
      {
        title: ["GPT-Powered", "Intelligence"],
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/ai1.svg",
        hoverIcon:
          "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/hai.svg",
        description:
          "Leverage the advanced capabilities of GPT-3 for intelligent automation, adaptable to your specific needs.",
      },
      {
        title: ["Reduced Manual", "Work"],
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/ex1.svg",
        hoverIcon:
          "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/haie.svg",
        description:
          "Eliminate repetitive tasks like data entry, report generation, and email scheduling, freeing up valuable time.",
      },
      {
        title: ["Improved Accuracy", "& Efficiency"],
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/data1.svg",
        hoverIcon:
          "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/hdata.svg",
        description:
          "Minimize human error and streamline processes, ensuring consistent and high-quality results.",
      },
    ],
  },
];

export const gptAiAutomationSection3 = [
  {
    id: 1,
    heading: "Automated Content Creation",
    challenge:
      "Generate reports, summaries, and marketing copy with exceptional speed and efficiency.",
    solution:
      "Ensure consistent brand voice and messaging across all content creation tasks.",
    // Replace with the appropriate icon variable or path
  },
  {
    id: 2,
    heading: "Data Processing & Analysis",
    challenge:
      "Automate data extraction, cleaning, and analysis, freeing up your team for data-driven insights.",
    solution:
      "Improve data accuracy and reduce time spent on manual data manipulation.",
    // Replace with the appropriate icon variable or path
  },
  {
    id: 3,
    heading: "Enhanced Customer Communication",
    challenge:
      "Develop personalized email responses, address FAQs with chatbots, and automate scheduling for a smoother customer experience.",
    solution:
      "Reduce response times and free up your customer service team for complex inquiries.",
    // Replace with the appropriate icon variable or path
  },
];

export const gptAutomationFaqData = [
  {
    id: 1,
    question: "Is GPT automation secure?",
    answer:
      "Absolutely! We prioritize data security and ensure all data is handled according to industry standards.",
  },
  {
    id: 2,
    question: "Can I customize the GPT automation solution?",
    answer:
      "Yes! We design solutions tailored to your specific needs and workflows.",
  },
  {
    id: 3,
    question: "What happens if the GPT automation encounters an error?",
    answer:
      "Our systems are designed to handle errors and provide clear notifications for human intervention when necessary.",
  },
];

//ai powered app development

export const appDevelopmentDataHome = [
  {
    title: "Transform Ideas into Digital Reality. Effortlessly.",
    description:
      "Elevate your business with intelligent, AI-powered web and mobile app solutions. We develop apps that resonate with your brand, drive user engagement, and streamline your operations.",
    list: [
      "Custom Development",
      "AI-Driven Efficiency",
      "Scalable & Future-Ready Solutions",
    ],
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/spt6.svg",
  },
];

export const appDevelopmentDataSection2 = [
  {
    heading: "Your Digital Journey Begins with Innovation.",
    desc: "Experience the future of digital solutions. Our AI-driven web and mobile apps are designed to enhance user experiences, automate tasks, and accelerate growth.",
    list: [
      {
        title: ["Custom Development", "Tailored to You"],
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/ai1.svg",
        hoverIcon:
          "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/hai.svg",
        description:
          "We craft web and mobile apps that align perfectly with your brand identity, functionality needs, and user experience goals.",
      },
      {
        title: ["AI-Driven Efficiency", "& Performance"],
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/ex1.svg",
        hoverIcon:
          "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/haie.svg",
        description:
          "Harness the power of AI to optimize app performance, streamline processes, and provide personalized user experiences.",
      },
      {
        title: ["Scalable", "& Future-Ready Solutions"],
        icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/data1.svg",
        hoverIcon:
          "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/hdata.svg",
        description:
          "Our apps are built to grow with your business, ensuring scalability and adaptability to future technological advancements.",
      },
    ],
  },
];

export const appDevelopmentDataSection3 = [
  {
    id: 1,
    heading: "Personalized User Experiences",
    challenge:
      "Deliver tailored content, product recommendations, and experiences to each user.",
    solution:
      "Increase user satisfaction and loyalty through personalized interactions.",
    // Replace with the appropriate icon variable or path
  },
  {
    id: 2,
    heading: "Automated Business Processes",
    challenge:
      "Automate routine tasks like data entry, notifications, and customer support.",
    solution:
      "Free up your team to focus on more strategic initiatives, boosting overall productivity.",
    // Replace with the appropriate icon variable or path
  },
  {
    id: 3,
    heading: "Enhanced Mobile Commerce",
    challenge:
      "Provide a seamless shopping experience with AI-powered features like voice search, predictive analytics, and personalized recommendations.",
    solution:
      "Drive higher conversion rates and customer retention through smarter mobile commerce strategies.",
    // Replace with the appropriate icon variable or path
  },
];

export const appDevelopmentFaqData = [
  {
    id: 1,
    question: "What platforms do you develop for?",
    answer:
      "We develop web and mobile apps for all major platforms, including iOS, Android, and web browsers.",
  },
  {
    id: 2,
    question: "How do you ensure the app meets our business needs?",
    answer:
      "We collaborate closely with you throughout the project to ensure the final product aligns with your business objectives and user expectations.",
  },
  {
    id: 3,
    question: "Can the apps be updated as our business grows?",
    answer:
      "Absolutely! We design our apps with scalability in mind, allowing for easy updates and enhancements as your business evolves.",
  },
];

//Ai journey

export const getHeadingData1 = (currentPage) => {
  switch (currentPage) {
    case "AI Strategy & Consulting":
      return "Insightful Business Assessment";
    case "Generative AI Solutions":
      return "Comprehensive Needs Assessment";
    case "AI Chatbot Solutions":
      return "Comprehensive Needs Analysis";
    case "AI Knowledge Assistants":
      return "In-Depth Needs Evaluation";
    case "Workflow Automation with GPT":
      return "Understanding Automation Needs";
    case "AI-Powered App Development":
      return "Understanding Business and User Needs";
    default:
      return "Insightful Business Assessment";
  }
};

export const getHeadingData2 = (currentPage) => {
  switch (currentPage) {
    case "AI Strategy & Consulting":
      return "Strategic AI Roadmap";
    case "Generative AI Solutions":
      return "AI Model Design & Customization";
    case "AI Chatbot Solutions":
      return "Custom Chatbot Design & Development";
    case "AI Knowledge Assistants":
      return "Tailored Knowledge Assistant Design";
    case "Workflow Automation with GPT":
      return "Customized GPT Solution Design";
    case "AI-Powered App Development":
      return "Tailored AI-Powered App Design";
    default:
      return "Strategic AI Roadmap";
  }
};

export const getHeadingData3 = (currentPage) => {
  switch (currentPage) {
    case "AI Strategy & Consulting":
      return "AI Solution Design & Development";
    case "Generative AI Solutions":
      return "Seamless AI Integration";
    case "AI Chatbot Solutions":
      return "Seamless System Integration";
    case "AI Knowledge Assistants":
      return "Effortless Integration with Existing Systems";
    case "Workflow Automation with GPT":
      return "Seamless Integration Across Systems";
    case "AI-Powered App Development":
      return "Seamless Integration and Development";
    default:
      return "AI Solution Design & Development";
  }
};

export const getHeadingData4 = (currentPage) => {
  switch (currentPage) {
    case "AI Strategy & Consulting":
      return "Ongoing Optimization & Success Measurement";
    case "Generative AI Solutions":
      return "Continuous Improvement & Impact Measurement";
    case "AI Chatbot Solutions":
      return "Ongoing Support & Performance Optimization";
    case "AI Knowledge Assistants":
      return "Continuous Improvement & Impact Measurement";
    case "Workflow Automation with GPT":
      return "Continuous Optimization & Impact Monitoring";
    case "AI-Powered App Development":
      return "Ongoing Support and Optimization";
    default:
      return "Ongoing Optimization & Success Measurement";
  }
};

export const getDescData1 = (currentPage) => {
  switch (currentPage) {
    case "AI Strategy & Consulting":
      return "We begin with a deep assessment of your business objectives and challenges, providing a solid foundation for crafting an AI strategy that fits your needs.";
    case "Generative AI Solutions":
      return "We start by understanding your unique business needs, ensuring our generative AI solutions are perfectly aligned with your goals and workflows.";
    case "AI Chatbot Solutions":
      return "We begin by deeply understanding your business needs, ensuring the chatbot solution aligns perfectly with your customer service objectives and workflow.";
    case "AI Knowledge Assistants":
      return "We begin by understanding your business needs and knowledge-sharing objectives to design AI assistants that streamline information access and enhance decision-making.";
    case "Workflow Automation with GPT":
      return "We begin by thoroughly assessing your business processes to identify areas where GPT-driven automation can streamline workflows and enhance operational efficiency.";
    case "AI-Powered App Development":
      return "We start by thoroughly understanding your business goals and user expectations to craft custom AI-powered web and mobile app solutions that drive growth and enhance user experience.";
    default:
      return "We begin with a deep assessment of your business objectives and challenges, providing a solid foundation for crafting an AI strategy that fits your needs.";
  }
};

export const getDescData2 = (currentPage) => {
  switch (currentPage) {
    case "AI Strategy & Consulting":
      return "We design a tailored roadmap that strategically guides AI integration into your business, ensuring alignment with both short-term and long-term goals.";
    case "Generative AI Solutions":
      return "We design and customize AI models to fit your exact needs, ensuring our generative AI solutions are optimized for your unique requirements.";
    case "AI Chatbot Solutions":
      return "We create customized chatbot solutions tailored to your specific needs, ensuring they are designed to provide intuitive and efficient customer interactions.";
    case "AI Knowledge Assistants":
      return "We design customized AI knowledge assistants that provide seamless access to information and facilitate intelligent decision-making within your organization.";
    case "Workflow Automation with GPT":
      return "We design tailored GPT-driven automation solutions, ensuring they are built to seamlessly integrate into your unique business processes and deliver tangible improvements.";
    case "AI-Powered App Development":
      return "We design customized, AI-powered web and mobile apps that deliver intuitive, user-friendly interfaces and enhance the overall user experience through intelligent features.";
    default:
      return "We design a tailored roadmap that strategically guides AI integration into your business, ensuring alignment with both short-term and long-term goals.";
  }
};

export const getDescData3 = (currentPage) => {
  switch (currentPage) {
    case "AI Strategy & Consulting":
      return "We design and develop customized AI solutions that fit seamlessly with your business needs, improving workflows and boosting operational efficiency.";
    case "Generative AI Solutions":
      return "We ensure that generative AI solutions are seamlessly integrated into your existing systems, enhancing workflows without disruption.";
    case "AI Chatbot Solutions":
      return "We ensure that your tailored AI chatbot solution integrates seamlessly with existing systems, providing a cohesive and enhanced customer service experience.";
    case "AI Knowledge Assistants":
      return "Our AI knowledge assistants seamlessly integrate with your existing systems, ensuring information is easily accessible without disrupting your current workflows.";
    case "Workflow Automation with GPT":
      return "We ensure smooth integration of GPT-driven automation with your existing systems, facilitating streamlined operations without disrupting current workflows.";
    case "AI-Powered App Development":
      return "We handle the seamless integration of AI technologies with your app, ensuring compatibility with existing systems and smooth development processes that enhance functionality.";
    default:
      return "We design and develop customized AI solutions that fit seamlessly with your business needs, improving workflows and boosting operational efficiency.";
  }
};

export const getDescData4 = (currentPage) => {
  switch (currentPage) {
    case "AI Strategy & Consulting":
      return "We provide continuous optimization and measure the impact of AI strategies, ensuring they remain effective and drive measurable business outcomes.";
    case "Generative AI Solutions":
      return "We provide ongoing optimization and measure the impact of our generative AI solutions to ensure they deliver long-term value and drive business growth.";
    case "AI Chatbot Solutions":
      return "We provide continuous support and performance optimization to ensure your AI chatbot evolves with your business and delivers long-term value.";
    case "AI Knowledge Assistants":
      return "We offer ongoing support and optimization to ensure your AI knowledge assistants evolve with your business, providing consistent value and improving efficiency.";
    case "Workflow Automation with GPT":
      return "We provide ongoing support to optimize and monitor your automated workflows, ensuring they continue to deliver high performance and business growth over time.";
    case "AI-Powered App Development":
      return "We offer continuous support and performance optimization, ensuring your AI-powered app evolves with your business needs and maintains peak performance.";
    default:
      return "We provide continuous optimization and measure the impact of AI strategies, ensuring they remain effective and drive measurable business outcomes.";
  }
};

const card11 = [
  {
    title: "Understanding Business Goals",
    description:
      "Analyze your business vision to align AI with your objectives.",
  },
  {
    title: "Identifying Key Challenges",
    description:
      "Pinpoint hurdles where AI solutions can provide the greatest impact.",
  },
  {
    title: "Assessing Current Technologies",
    description:
      "Evaluate your existing technology landscape to plan AI integration.",
  },
  {
    title: "Understanding Industry Trends",
    description:
      "Analyze market and industry trends to tailor AI strategies effectively.",
  },
  {
    title: "Evaluating Data Readiness",
    description:
      "Ensure your data infrastructure supports future AI implementations.",
  },
  {
    title: "Defining Strategic Objectives",
    description:
      "Establish clear, actionable objectives for AI-driven business transformation.",
  },
];

const card12 = [
  {
    title: "Setting Short-Term Goals",
    description:
      "Define immediate milestones that align with your business priorities.",
  },
  {
    title: "Planning Long-Term Strategy",
    description:
      "Design a comprehensive strategy for sustained AI integration.",
  },
  {
    title: "Outlining AI Opportunities",
    description:
      "Identify key AI opportunities within your organization for competitive advantage.",
  },
  {
    title: "Resource Allocation",
    description:
      "Ensure effective resource management for implementing AI initiatives.",
  },
  {
    title: "Risk Assessment and Mitigation",
    description:
      "Identify potential risks and devise strategies to address them.",
  },
  {
    title: "Creating a Scalable Plan",
    description:
      "Build a flexible plan that adapts to evolving business needs and technologies.",
  },
];

const card13 = [
  {
    title: "Analyzing Requirements",
    description:
      "Thoroughly understand the specific needs to tailor AI solutions.",
  },
  {
    title: "Identifying Key Functionalities",
    description: "Define core features essential for your business operations.",
  },
  {
    title: "Prototyping AI Solutions",
    description:
      "Develop prototypes to validate and refine AI functionalities.",
  },
  {
    title: "Customizing AI Models",
    description:
      "Design AI models uniquely suited to your business requirements.",
  },
  {
    title: "Implementing AI Solutions",
    description:
      "Seamlessly integrate AI technologies into existing workflows.",
  },
  {
    title: "Testing and Iteration",
    description:
      "Continuously test and refine AI solutions to ensure optimal performance.",
  },
];

const card14 = [
  {
    title: "Monitoring AI Performance",
    description:
      "Continuously track AI performance to ensure it meets business objectives.",
  },
  {
    title: "Adaptive AI Optimization",
    description:
      "Regularly refine AI strategies to align with technological advancements.",
  },
  {
    title: "Proactive System Maintenance",
    description: "Prevent system issues through routine checks and updates.",
  },
  {
    title: "User Training & Support",
    description: "Equip your team with the skills to maximize AI efficiency.",
  },
  {
    title: "Tracking Key Performance Indicators",
    description: "Measure the success of AI solutions with relevant KPIs.",
  },
  {
    title: "Ensuring Business Growth",
    description: "Analyze AI’s long-term impact on growth and profitability.",
  },
];

const card21 = [
  {
    title: "Understanding Business Objectives",
    description:
      "Identify key business goals to shape generative AI solutions effectively.",
  },
  {
    title: "Pinpointing Key Challenges",
    description:
      "Highlight specific operational or creative challenges for AI to address.",
  },
  {
    title: "Analyzing Current Processes",
    description:
      "Review existing workflows to integrate generative AI seamlessly.",
  },
  {
    title: "Evaluating Data Sources",
    description:
      "Assess data quality and availability for effective AI model training.",
  },
  {
    title: "Setting Solution Objectives",
    description:
      "Define clear, measurable objectives for generative AI implementation.",
  },
  {
    title: "Identifying Creative Opportunities",
    description:
      "Discover areas where generative AI can enhance creativity or efficiency.",
  },
];

const card22 = [
  {
    title: "Selecting AI Model Architecture",
    description:
      "Choose the most suitable generative AI model architecture for your goals.",
  },
  {
    title: "Customizing Model Features",
    description:
      "Tailor AI model features to your specific business and operational needs.",
  },
  {
    title: "Training the AI Model",
    description:
      "Train the model using relevant data to enhance performance and accuracy.",
  },
  {
    title: "Fine-Tuning for Precision",
    description:
      "Continuously refine the AI model to optimize results and outputs.",
  },
  {
    title: "Incorporating Feedback Loops",
    description:
      "Implement feedback mechanisms for ongoing AI model improvements.",
  },
  {
    title: "Validating Model Accuracy",
    description:
      "Test and validate the AI model to ensure it meets quality standards.",
  },
];

const card23 = [
  {
    title: "Assessing Existing Infrastructure",
    description:
      "Review your current system setup to plan smooth AI integration.",
  },
  {
    title: "Identifying Integration Points",
    description:
      "Pinpoint where generative AI can seamlessly fit into your operations.",
  },
  {
    title: "Customizing Integration Approach",
    description:
      "Tailor integration plans to ensure alignment with your existing workflows.",
  },
  {
    title: "Minimizing Operational Disruption",
    description:
      "Ensure the integration process causes minimal downtime or disruptions.",
  },
  {
    title: "Testing and Deployment",
    description:
      "Test generative AI solutions in your environment for smooth deployment.",
  },
  {
    title: "Providing Post-Integration Support",
    description:
      "Offer ongoing support to optimize the integration of generative AI solutions.",
  },
];

const card24 = [
  {
    title: "Monitoring Solution Performance",
    description:
      "Continuously monitor generative AI performance to ensure optimal functionality.",
  },
  {
    title: "Adapting to New Data",
    description:
      "Update AI models as new data becomes available for sustained accuracy.",
  },
  {
    title: "Regular Enhancements",
    description:
      "Improve generative AI models to keep up with evolving business needs.",
  },
  {
    title: "Proactive Maintenance",
    description:
      "Ensure solutions run smoothly through regular maintenance and checks.",
  },
  {
    title: "Tracking Business Impact",
    description:
      "Measure the impact of AI solutions using relevant business KPIs.",
  },
  {
    title: "Ensuring Long-Term Success",
    description:
      "Analyze long-term performance to ensure AI solutions drive sustained growth.",
  },
];

const card31 = [
  {
    title: "Understanding Business Goals",
    description:
      "Analyze your business objectives to design a chatbot that meets customer needs.",
  },
  {
    title: "Identifying Key Pain Points",
    description:
      "Discover areas where chatbots can enhance customer experience or efficiency.",
  },
  {
    title: "Defining Target Audience",
    description:
      "Determine your target audience’s preferences for an effective chatbot experience.",
  },
  {
    title: "Analyzing Customer Interaction Data",
    description: "Review past customer interactions to shape chatbot features.",
  },
  {
    title: "Setting Solution Objectives",
    description:
      "Define clear goals for the chatbot’s role in your customer service strategy.",
  },
  {
    title: "Outlining Desired Functionalities",
    description:
      "Highlight key chatbot functionalities such as query handling and personalization.",
  },
];

const card32 = [
  {
    title: "Designing Conversational Flows",
    description:
      "Craft chatbot dialogues that align with natural user interactions and queries.",
  },
  {
    title: "Customizing Features",
    description:
      "Tailor features such as personalized responses, FAQs, and self-service options.",
  },
  {
    title: "Developing Multi-Platform Compatibility",
    description:
      "Ensure chatbot functionality across web, mobile, and messaging platforms.",
  },
  {
    title: "NLP and AI Features",
    description:
      "Incorporate advanced natural language processing for improved user interaction.",
  },
  {
    title: "Testing and Refining Conversations",
    description:
      "Continuously test and fine-tune chatbot responses for smooth interactions.",
  },
  {
    title: "Building User-Centric Interface",
    description:
      "Design an intuitive interface that ensures an effortless customer experience.",
  },
];

const card33 = [
  {
    title: "Assessing Current Infrastructure",
    description:
      "Review your systems to ensure chatbot compatibility and smooth integration.",
  },
  {
    title: "Identifying Integration Points",
    description:
      "Determine where the chatbot fits best within your customer service operations.",
  },
  {
    title: "Customizing Integration Approach",
    description:
      "Tailor integration plans to ensure optimal performance in your infrastructure.",
  },
  {
    title: "Ensuring Data Synchronization",
    description:
      "Seamlessly connect the chatbot with your CRM, databases, and other systems.",
  },
  {
    title: "Implementing the Integration",
    description:
      "Deploy the chatbot solution without disrupting current business processes.",
  },
  {
    title: "Providing Post-Integration Support",
    description:
      "Offer continuous support to optimize integration and resolve any issues.",
  },
];

const card34 = [
  {
    title: "Monitoring Chatbot Performance",
    description:
      "Regularly track chatbot functionality to ensure optimal efficiency and accuracy.",
  },
  {
    title: "Gathering User Feedback",
    description:
      "Collect customer feedback to improve chatbot interactions and features.",
  },
  {
    title: "Continuous Feature Upgrades",
    description:
      "Regularly update chatbot functionalities to keep pace with changing needs.",
  },
  {
    title: "Proactive Issue Resolution",
    description:
      "Address any issues before they impact the customer experience.",
  },
  {
    title: "Analyzing Business Impact",
    description:
      "Measure the chatbot’s contribution to improving customer satisfaction and engagement.",
  },
  {
    title: "Ensuring Long-Term Success",
    description:
      "Continuously optimize the chatbot to meet evolving customer service goals.",
  },
];

const card41 = [
  {
    title: "Understanding Knowledge Gaps",
    description:
      "Identify areas where AI assistants can improve information accessibility.",
  },
  {
    title: "Assessing Business Processes",
    description:
      "Review your workflows to pinpoint where AI assistants can add value.",
  },
  {
    title: "Defining User Roles",
    description: "Determine key user groups and their knowledge requirements.",
  },
  {
    title: "Analyzing Data Sources",
    description:
      "Evaluate current data repositories for effective AI assistant integration.",
  },
  {
    title: "Setting Assistant Objectives",
    description:
      "Outline specific goals for AI assistants to achieve in knowledge management.",
  },
  {
    title: "Prioritizing Features",
    description:
      "Define essential functionalities like search capabilities, task automation, and data retrieval.",
  },
];

const card42 = [
  {
    title: "Creating Conversational Flows",
    description:
      "Design user-friendly interactions for intuitive and effective information retrieval.",
  },
  {
    title: "Customizing Functionality",
    description:
      "Build tailored features like real-time data access and natural language understanding.",
  },
  {
    title: "Ensuring Multi-Platform Support",
    description:
      "Develop knowledge assistants compatible with desktop, mobile, and other platforms.",
  },
  {
    title: "Incorporating NLP & AI",
    description:
      "Integrate advanced AI and NLP for accurate data comprehension and delivery.",
  },
  {
    title: "Testing & Refining Interactions",
    description:
      "Continuously test and refine to ensure optimal knowledge retrieval experiences.",
  },
  {
    title: "User-Centric Interface Design",
    description:
      "Build intuitive interfaces that simplify how users interact with the assistant.",
  },
];

const card43 = [
  {
    title: "Evaluating Existing Infrastructure",
    description:
      "Assess your current systems for smooth AI assistant integration.",
  },
  {
    title: "Identifying Key Integration Points",
    description:
      "Pinpoint where AI knowledge assistants can access relevant data.",
  },
  {
    title: "Customizing Integration Approach",
    description:
      "Tailor integration plans to ensure compatibility and optimal performance.",
  },
  {
    title: "Syncing with Data Repositories",
    description:
      "Connect AI assistants to databases and knowledge management platforms.",
  },
  {
    title: "Deploying the Solution",
    description:
      "Seamlessly integrate AI assistants into workflows with minimal disruptions.",
  },
  {
    title: "Post-Integration Support",
    description:
      "Provide continuous support to resolve issues and optimize assistant performance.",
  },
];

const card44 = [
  {
    title: "Monitoring Assistant Performance",
    description:
      "Track and measure how well the assistant meets knowledge-sharing goals.",
  },
  {
    title: "Collecting User Feedback",
    description:
      "Gather insights from users to refine and enhance the assistant’s functionality.",
  },
  {
    title: "Continuous Feature Enhancements",
    description:
      "Regularly update features to meet evolving user needs and business demands.",
  },
  {
    title: "Proactive Maintenance",
    description:
      "Conduct routine checks to ensure smooth operation and avoid disruptions.",
  },
  {
    title: "Analyzing Knowledge Impact",
    description:
      "Measure the impact on decision-making, productivity, and knowledge accessibility.",
  },
  {
    title: "Ensuring Long-Term Success",
    description:
      "Continuously optimize AI knowledge assistants for sustained growth and performance.",
  },
];

const card51 = [
  {
    title: "Identifying Key Workflow Bottlenecks",
    description:
      "Pinpoint inefficient processes where automation can drive productivity gains.",
  },
  {
    title: "Analyzing Current Workflows",
    description:
      "Review existing workflows to understand how GPT can improve efficiency.",
  },
  {
    title: "Mapping Business Goals",
    description:
      "Align automation efforts with your broader business objectives and KPIs.",
  },
  {
    title: "Evaluating Data & Resources",
    description:
      "Assess available data and resources for GPT automation integration.",
  },
  {
    title: "Defining Automation Priorities",
    description:
      "Prioritize key areas for automation based on business impact and feasibility.",
  },
  {
    title: "Setting Automation Objectives",
    description:
      "Establish clear goals for automation to improve speed and accuracy.",
  },
];
const card52 = [
  {
    title: "Designing Custom Automation Workflows",
    description:
      "Craft workflows tailored to your specific operational needs and tasks.",
  },
  {
    title: "Selecting GPT Capabilities",
    description:
      "Customize GPT features like text generation, data extraction, or summarization.",
  },
  {
    title: "Creating Task-Specific Automations",
    description:
      "Develop automations for routine tasks such as document generation or data handling.",
  },
  {
    title: "Integrating Workflow Tools",
    description:
      "Ensure GPT solutions are compatible with your existing tools and platforms.",
  },
  {
    title: "Testing Automation Efficiency",
    description:
      "Test workflows to ensure smooth operation and enhanced productivity.",
  },
  {
    title: "Refining for Optimal Performance",
    description:
      "Continuously refine automation workflows for maximum impact and efficiency.",
  },
];
const card53 = [
  {
    title: "Assessing System Compatibility",
    description:
      "Review current systems to ensure seamless integration of GPT automation.",
  },
  {
    title: "Identifying Integration Points",
    description:
      "Pinpoint areas where GPT can plug into existing workflows and systems.",
  },
  {
    title: "Customizing Integration Approach",
    description:
      "Tailor the integration strategy to match your technical infrastructure.",
  },
  {
    title: "Synchronizing with Data Sources",
    description:
      "Ensure automation pulls and updates data in real-time for accuracy.",
  },
  {
    title: "Implementing with Minimal Disruption",
    description:
      "Deploy GPT solutions while minimizing downtime and workflow interruptions.",
  },
  {
    title: "Post-Integration Support",
    description:
      "Offer continuous support to optimize integration and resolve any issues.",
  },
];
const card54 = [
  {
    title: "Monitoring Automation Performance",
    description:
      "Regularly track the effectiveness of GPT automation in achieving goals.",
  },
  {
    title: "Gathering User Feedback",
    description:
      "Collect input from users to improve and enhance the automation.",
  },
  {
    title: "Adapting to Evolving Needs",
    description:
      "Continuously adjust automation workflows to meet changing business demands.",
  },
  {
    title: "Introducing New Features",
    description:
      "Implement new GPT features as technology evolves for improved functionality.",
  },
  {
    title: "Measuring Business Impact",
    description:
      "Analyze the automation’s impact on key business metrics like efficiency and ROI.",
  },
  {
    title: "Ensuring Long-Term Success",
    description:
      "Ensure GPT automation continues to meet long-term operational goals and growth.",
  },
];

const card61 = [
  {
    title: "Defining Business Objectives",
    description:
      "Understand key business goals to align app functionality with growth strategies.",
  },
  {
    title: "Identifying User Preferences",
    description:
      "Pinpoint user expectations to design apps that meet their needs effectively.",
  },
  {
    title: "Analyzing Market Trends",
    description:
      "Research industry trends to ensure your app stays competitive and relevant.",
  },
  {
    title: "Mapping Core Features",
    description:
      "Identify critical features that will enhance user engagement and app performance.",
  },
  {
    title: "Gathering Stakeholder Inputs",
    description:
      "Incorporate feedback from key stakeholders to ensure alignment with business priorities.",
  },
  {
    title: "Setting Clear Development Goals",
    description:
      "Establish development milestones to ensure a focused and efficient build process.",
  },
];
const card62 = [
  {
    title: "Crafting User-Centric Interfaces",
    description:
      "Design sleek and intuitive interfaces for seamless user interaction and navigation.",
  },
  {
    title: "Incorporating AI Functionalities",
    description:
      "Integrate AI-powered features like chatbots, recommendation engines, or voice search.",
  },
  {
    title: "Designing for Scalability",
    description:
      "Build apps that are scalable, adaptable, and ready to grow with your business.",
  },
  {
    title: "Optimizing for Speed and Performance",
    description:
      "Ensure fast loading times and smooth performance across devices and platforms.",
  },
  {
    title: "Ensuring Cross-Platform Compatibility",
    description:
      "Create apps that function seamlessly on both iOS and Android for maximum reach.",
  },
  {
    title: "Testing for User Experience",
    description:
      "Continuously test designs for user-friendliness and ensure an intuitive experience.",
  },
];
const card63 = [
  {
    title: "Building a Robust Backend",
    description:
      "Develop a solid backend architecture that supports complex AI functionalities.",
  },
  {
    title: "Integrating AI Models",
    description:
      "Seamlessly integrate AI models like machine learning, NLP, and predictive analytics.",
  },
  {
    title: "Ensuring Data Security",
    description:
      "Implement stringent security protocols to protect user data and privacy.",
  },
  {
    title: "Connecting with Third-Party Systems",
    description:
      "Ensure smooth integration with your existing systems, databases, and APIs.",
  },
  {
    title: "Developing for Continuous Deployment",
    description:
      "Enable continuous updates and improvements through a streamlined deployment process.",
  },
  {
    title: "Providing Real-Time Data Access",
    description:
      "Ensure real-time data synchronization for features like user tracking and AI insights.",
  },
];
const card64 = [
  {
    title: "Monitoring App Performance",
    description:
      "Continuously track app performance to ensure stability and user satisfaction.",
  },
  {
    title: "Collecting User Feedback",
    description:
      "Regularly gather feedback to make informed enhancements to the app.",
  },
  {
    title: "Optimizing AI Features",
    description:
      "Fine-tune AI functionalities to improve accuracy, speed, and overall performance.",
  },
  {
    title: "Introducing New Updates",
    description:
      "Roll out updates and new features based on evolving business and user needs.",
  },
  {
    title: "Ensuring App Security",
    description:
      "Maintain up-to-date security protocols to safeguard user data.",
  },
  {
    title: "Measuring Business Impact",
    description:
      "Analyze the app’s contribution to user engagement, growth, and business success.",
  },
];

export const getTabData1 = (currentPage) => {
  switch (currentPage) {
    case "AI Strategy & Consulting":
      return card11;
    case "Generative AI Solutions":
      return card21;
    case "AI Chatbot Solutions":
      return card31;
    case "AI Knowledge Assistants":
      return card41;
    case "Workflow Automation with GPT":
      return card51;
    case "AI-Powered App Development":
      return card61;
    default:
      return "We provide continuous optimization and measure the impact of AI strategies, ensuring they remain effective and drive measurable business outcomes.";
  }
};

export const getTabData2 = (currentPage) => {
  switch (currentPage) {
    case "AI Strategy & Consulting":
      return card12;
    case "Generative AI Solutions":
      return card22;
    case "AI Chatbot Solutions":
      return card32;
    case "AI Knowledge Assistants":
      return card42;
    case "Workflow Automation with GPT":
      return card52;
    case "AI-Powered App Development":
      return card62;
    default:
      return "We provide continuous optimization and measure the impact of AI strategies, ensuring they remain effective and drive measurable business outcomes.";
  }
};
export const getTabData3 = (currentPage) => {
  switch (currentPage) {
    case "AI Strategy & Consulting":
      return card13;
    case "Generative AI Solutions":
      return card23;
    case "AI Chatbot Solutions":
      return card33;
    case "AI Knowledge Assistants":
      return card43;
    case "Workflow Automation with GPT":
      return card53;
    case "AI-Powered App Development":
      return card63;
    default:
      return "We provide continuous optimization and measure the impact of AI strategies, ensuring they remain effective and drive measurable business outcomes.";
  }
};
export const getTabData4 = (currentPage) => {
  switch (currentPage) {
    case "AI Strategy & Consulting":
      return card14;
    case "Generative AI Solutions":
      return card24;
    case "AI Chatbot Solutions":
      return card34;
    case "AI Knowledge Assistants":
      return card44;
    case "Workflow Automation with GPT":
      return card54;
    case "AI-Powered App Development":
      return card64;
    default:
      return "We provide continuous optimization and measure the impact of AI strategies, ensuring they remain effective and drive measurable business outcomes.";
  }
};
