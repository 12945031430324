// import xClose from "../../assests/image/x-close.svg";

const ShowMessage = ({ messageData, handleClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
      <div className="bg-white border-2 lg:w-1/2  text-black p-7 rounded-md shadow-md">
        <div className="flex items-center justify-between">
          <p className="text-2xl font-bold">Message</p>
          <button onClick={handleClose} className="text-xl font-bold">
            X
          </button>
        </div>

        <div className="flex text-black items-center justify-start gap-4 mt-7">
          <div className="flex flex-col gap-0">
            <p>{messageData}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowMessage;
