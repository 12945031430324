export const company = [
  { title: "About Us", link: "/about-us" },
  { title: "Portfolio", link: "/portfolio" },
  { title: "Blogs", link: "/resources/blogs" },
  { title: "Whitepapers", link: "/resources/whitepapers" },
  { title: "E-books", link: "/resources/ebooks" },
  { title: "Privacy Policy", link: "/privacy-policy" },
];

export const portfolioData = [
  { title: "Gen AI" },
  { title: "Web Apps" },
  { title: "Mobile Apps" },
];

export const portfolioDataHover = [
  { title: "Soul Pay" },
  { title: "Voyage Pro" },
  { title: "Thirty2Give" },
  { title: "Karmikh" },
  { title: "Compliancehelp" },
  { title: "Prosper Point" },
];

export const aiServices = [
  {
    title: "AI Strategy & Consulting",
    link: "/services/ai-strategy-consulting",
  },
  {
    title: "Generative AI Solutions",
    link: "/services/generative-ai-solutions",
  },
  {
    title: "AI Chatbot Solutions",
    link: "/services/ai-chatbot-solutions",
  },
  {
    title: "AI Knowledge Assistants",
    link: "/services/ai-knowledge-assistants",
  },
  {
    title: "Workflow Automation with GPT",
    link: "/services/workflow-automation-gpt",
  },
  {
    title: "AI-Powered App Development",
    link: "/services/ai-app-development",
  },
];

export const caseStudies = [
  {
    title: "AI Chatbots",
    link: "/solutions/ai-chatbots",
  },
  {
    title: "Generative AI",
    link: "/solutions/generative-ai",
  },
  {
    title: "AI Knowledge Assistants",
    link: "/solutions/ai-knowledge-assistants",
  },
  {
    title: "Workflow Automation",
    link: "/solutions/workflow-automation",
  },
  {
    title: "AI-Powered App Development",
    link: "/solutions/ai-powered-app-development",
  },
];
