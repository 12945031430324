import React, { useState } from "react";

import teamData from "../../components/data/AboutData";
import { Link } from "react-router-dom";


const MeetTeam = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  return (
    <div className="">
      <div className="  mx-auto max-w-7xl">
        <div className="flex px-6 md:px-16 flex-col  text-[var(--text-color)] ">
          <div className="flex flex-col items-center justify-center">
            <h1 className="md:text-[56px] text-3xl  text-center md:mb-14 mb-5 font-semibold">
              Abstrabit Team
            </h1>
          </div>

          <div className=" grid grid-cols-1 md:grid-cols-2 md:gap-5 lg:grid-cols-3 lg:gap-8">
            {teamData &&
              teamData.map((member, index) => (
                <div
                  key={index}
                  className=" text-[var(--text-color)] bg-[var(--background-gray-color)] p-6 flex flex-col items-start justify-between"
                >
                  <div>
                    <div
                      className={`flex  items-center outline-none border-0 rounded-tl-[180px] rounded-br-[180px]  justify-center  overflow-hidden mb-4 bg-gradient-to-r ${member.bgGradient}`}
                    >
                      <img
                        src={member.image}
                        alt={member.name}
                        className="w-full md:h-80  object-cover"
                      />
                    </div>
                    <h3 className="md:text-[28.7px] text-2xl font-bold">
                      {member.name}
                    </h3>
                    <p className="text-xl mt-1 md:text-xl text-left ">
                      {member.title}
                    </p>
                    <p className=" text-[#b4b3be] md:text-xl text-lg mt-4 text-start">
                      {member.description}
                    </p>
                    <p className=" text-[white]  text-lg font-semibold mt-4 text-start">
                      {member.experiences}
                    </p>
                  </div>

                  <Link
                    onClick={() => {
                      window.open(member.profile, "_blank");
                    }}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    className="mt-4 md:w-32 w-36  flex items-center justify-center border text-lg md:text-base hover:bg-[var(--background-color-white)] border-white   text-[var(--text-color)]  rounded-full px-0 py-2"
                  >
                    {hoveredIndex === index ? (
                      ""
                    ) : (
                      <div className="bg-[var(--background-color-white)] hover:text-purple-700 md:p-1.5 p-2 md:w-7 md:h-7 h-7 w-7  rounded-full flex items-center justify-center mr-2">
                        <img
                          src="https://abswebsiteassets.blob.core.windows.net/websiteimages/about-assets/linkedin.svg"
                          alt=""
                          className="pic"
                        />
                      </div>
                    )}
                    <span
                      className={`${
                        hoveredIndex === index
                          ? "textColorPurple font-bold leading-[17px]"
                          : ""
                      }`}
                    >
                      {" "}
                      {hoveredIndex === index ? "View Profile" : "LinkedIn"}
                    </span>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetTeam;
