import React from "react";
import { FiChevronRight } from "react-icons/fi";

const StandOutSection = () => {
  return (
    <div className="bg-[var(--background-color)]  text-[var(--text-color)] px-7 lg:px-16 mx-auto max-w-7xl">
      <h2 className="text-3xl md:text-[56px] text-center font-semibold md:mb-14 mb-5">
        Your Ideal AI Partner
      </h2>

      <div className="flex flex-col gap-7 items-center justify-center ">
        <div className="bg-[#7A1AE9]  mr-16 lg:mr-64  text-start sm:w-[50%] lg:w-[40%] card2 md:p-8 p-5  ">
          <h3 className="lg:text-4xl text-xl font-semibold ">Expert Talent</h3>
          <p className=" lg:text-xl text-base mt-3 font-normal ">
            Our passionate AI specialists possess extensive experience, having
            completed over 150 successful projects, showcasing their deep
            expertise, dedication, and commitment to delivering top-quality
            results for our clients.
          </p>
          <button className="lg:text-lg mt-3 text-base relative group transition-colors duration-300  float-end text-end font-normal flex items-center justify-center text-[#ffffff] ">
            Know More <FiChevronRight className="mt-1" />
            <span className="absolute left-0 bottom-0 w-full h-[2px] bg-[var(--background-color-white)] scale-x-0 group-hover:scale-x-100 transform origin-left transition-transform duration-300"></span>
          </button>
        </div>
        <div className="bg-[#F37257] ml-16 lg:ml-48 card1  text-start sm:w-[50%] lg:w-[40%] custom-shadow shadow-lg md:p-8 p-5  ">
          <h3 className="lg:text-4xl text-xl font-semibold ">
            Transparent & Optimal Cost
          </h3>
          <p className=" lg:text-xl text-base mt-3 font-normal ">
            Get started with our cost-effective solutions, starting around
            $20/hour. We prioritize transparency, ensuring no hidden fees or
            scope creep, providing you with clear, upfront cost details from the
            outset.
          </p>
          <button className="lg:text-lg text-base mt-3 relative group transition-colors duration-300  float-end text-end font-normal flex items-center justify-center text-[#ffffff] ">
            Know More <FiChevronRight className="mt-1" />
            <span className="absolute left-0 bottom-0 w-full h-[2px] bg-[var(--background-color-white)] scale-x-0 group-hover:scale-x-100 transform origin-left transition-transform duration-300"></span>
          </button>
        </div>
        <div className="bg-[#B827F1] mr-16 lg:mr-64 card3 sm:w-[50%] lg:w-[40%] custom-shadow shadow-lg  text-start md:p-8 p-5  ">
          <h3 className="lg:text-4xl text-xl font-semibold ">
            Know Your Costs Upfront
          </h3>
          <p className=" lg:text-xl text-base mt-3 font-normal ">
            We work collaboratively to define your project needs, ensuring a
            transparent, predictable budget from the start. This approach
            eliminates surprises and aligns with your financial expectations.
          </p>
          <button className="lg:text-lg mt-3 text-base relative group transition-colors duration-300  float-end text-end font-normal flex items-center justify-center text-[#ffffff] ">
            Know More <FiChevronRight className="mt-1" />
            <span className="absolute left-0 bottom-0 w-full h-[2px] bg-[var(--background-color-white)] scale-x-0 group-hover:scale-x-100 transform origin-left transition-transform duration-300"></span>
          </button>
        </div>
        <div className="bg-[#EE426A] card4 ml-16 lg:ml-48  md:p-8 p-5 sm:w-[50%]   lg:w-[40%] custom-shadow shadow-lg text-start ">
          <h3 className="lg:text-4xl text-xl font-semibold ">
            On-Time, High-Quality Delivery
          </h3>
          <p className=" lg:text-xl text-base mt-3 font-normal ">
            Expect timely project completion and exceptional results you can
            trust. Our commitment to punctuality and quality ensures your
            project meets deadlines without compromising on excellence.
          </p>
          <button className="lg:text-lg mt-3  text-base relative group transition-colors duration-300 float-end text-end font-normal flex items-center justify-center text-[#ffffff] ">
            Know More <FiChevronRight className="mt-1" />
            <span className="absolute left-0 bottom-0 w-full h-[2px] bg-[var(--background-color-white)] scale-x-0 group-hover:scale-x-100 transform origin-left transition-transform duration-300"></span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default StandOutSection;
