import React from "react";
import WorkingProcessCard from "./WorkingProcessCard";

const WorkingProcessCommon = ({ heading, icon, tabCard, desc }) => {
  return (
    <div
      className={`grid grid-cols-1 mt-14 md:grid-cols-3 gap-10 text-[var(--text-color)]`}
    >
      <div className="text-start w-full flex-1">
        <h2 className="text-3xl leading-custom-72 text-start md:text-4xl lg:text-5xl   font-semibold textColor">
          {heading}
        </h2>

        <p className="text-base font-normal md:text-base lg:text-xl my-2 md:my-5 ">
          {desc}
        </p>

        <img src={icon} alt="" className=" object-cover" />
      </div>

      <div className=" md:col-span-2">
        <WorkingProcessCard tabCard={tabCard} />
      </div>
    </div>
  );
};

export default WorkingProcessCommon;
