import React from "react";
import { Link } from "react-router-dom";

import AboutReviewSection from "../about-us/AboutReviewSection";

const PortfolioHero = () => {
  return (
    <div className=" bg-[var(--background-gray-color)] text-[var(--text-color)]">
      <div className="flex shadow-sm max-w-7xl pb-20 mx-auto px-6 md:px-12 lg:px-16 flex-col md:items-start md:justify-start items-center justify-center pt-14 md:pt-14 lg:pt-14">
        <div className="grid grid-cols-1 gap-10 md:grid-cols-2">
          <div className="flex animate-fade-in-down space-y-9 flex-col items-center md:items-start justify-center md:justify-start">
            <div className="flex flex-col  mt-14 lg:flex-row md:items-start items-center justify-center md:justify-start w-full relative font-bold md:font-semibold text-5xl lg:text-6xl">
              <p className=" text-center md:text-start  flex flex-col">
                <span className="textColorPurple font-semibold md:leading-[70px] lg:leading-[100px]">
                  {" "}
                  Transforming{" "}
                </span>{" "}
                <span className=" mb-0 lg:-mt-5">Brands</span>{" "}
                <span className=" mb-0"> Since 2013</span>
              </p>
            </div>
            <p className="text-[var(--text-color)] md:text-start max-w-1xl text-center text-base lg:text-xl leading-6 font-medium">
              Since 2013, we’ve been helping businesses like yours accelerate
              their journey to success, backed by the right transformative
              solutions.
            </p>

            <Link
              className="btn bg-cover hover:text-red-500 bg-opacity-50  bg-custom-gradient hover:bg-custom-gradient-hover text-base lg:text-lg rounded-full text-[var(--text-color)]
             font-semibold px-5 py-2.5 duration-500 md:static"
            >
              Request a Quote
            </Link>
          </div>

          <div className="animate-fade-in-right lg:ms-20 lg:mt-28  flex items-center justify-center">
            <div className="md:-right-10  relative">
              <img
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/portfolio-assets/port1.svg"
                }
                alt=""
                className=""
              />
            </div>
            <div className="flex items-start justify-start flex-col">
              <img
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/portfolio-assets/port2.svg"
                }
                alt=""
                className=""
              />
              <img
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/portfolio-assets/port3.svg"
                }
                alt=""
                className="-ms-16 -mt-20"
              />
            </div>
          </div>
        </div>

        <div className="lg:-mt-2 mt-10 animate-fade-in-down">
          {" "}
          <AboutReviewSection page={"portfolio"} />
        </div>
      </div>
    </div>
  );
};

export default PortfolioHero;
