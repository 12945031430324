import React from "react";

const PlaceholderPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black">
      <div className="text-center">
        <h1 className="lg:text-4xl text-2xl font-bold text-white mb-4">
          Coming Soon!
        </h1>
        <p className="lg:text-2xl text-xl text-white mb-8">
          We're working hard to bring you something amazing. Stay tuned!
        </p>
        <div className="animate-pulse">
          <div className="w-24 h-24 rounded-full bg-gray-300 mb-4 mx-auto"></div>
          <div className="h-2 bg-gray-300 rounded-full max-w-xs mb-2 mx-auto"></div>
          <div className="h-2 bg-gray-300 rounded-full max-w-sm mb-2 mx-auto"></div>
          <div className="h-2 bg-gray-300 rounded-full max-w-md mx-auto"></div>
        </div>
      </div>
    </div>
  );
};

export default PlaceholderPage;
