const CustomRightArrow = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="absolute bottom-0 p-2 md:p-3  ms-4 left-[50%] transform translate-x-[0%]  z-10 rounded-full text-xl font-semibold bg-[white]  text-[#7A1AE9] transition hover:bg-[#7A1AE9] hover:text-[var(--text-color)]"
    >
      <svg
        className="size-5 rtl:rotate-180"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 5l7 7-7 7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    </button>
  );
};

export default CustomRightArrow;
