import React from "react";
import BlogHero from "./BlogHero";
import BlogCard from "./BlogCard";
import PopularArtical from "./PopularArtical";
import ContactUs from "../contact-us/ContactUs";
import MetaData from "../../components/layout/MetaData";


const Blog = () => {

  return (
    <div className="flex flex-col  gap-20 md:gap-32  bg-[var(--background-color)]">
      <BlogHero />
      <BlogCard />
      <PopularArtical />
      <ContactUs heading={"Partner with Us to Bring Your Vision to Life"} />
      <MetaData title="Blog - Abstrabit Technologies" />
    </div>
  );
};

export default Blog;
