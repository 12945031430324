const teamData = [
  {
    name: "Shobhraj Shakdupia",
    title: "CEO and Founder",
    description:
      "Shobhraj Shakdupia is a driving force in AI innovation with over a 12 years of experience. As a visionary leader and a pioneer in the AI field, she is dedicated to harnessing the transformative power of artificial intelligence to empower businesses. Shobhraj’s expertise and passion for AI are evident in Abstrabit’s diverse range of tailored services, meticulously crafted to address each client's unique needs. Her leadership ensures that Abstrabit Technologies remains at the forefront of AI-driven solutions, setting new standards of excellence and fostering a culture of relentless innovation and growth.",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/about-assets/shobhraj.png", // Replace with the actual path to the image
    bgGradient: "gradient-orange",
    experiences: "Ex-Novartis, Walmart & ZS Associates",
    profile: "https://www.linkedin.com/in/shobhraj-shakdupia/",
  },
  {
    name: "Sawan Rauniyar",
    title: "CTO and Co-Founder",
    description:
      "Sawan Rauniyar is the visionary technical leader and co-founder behind Abstrabit Technologies' innovative solutions. With over 10 years of expertise in AI, Sawan transforms complex challenges into breakthrough advancements. His dedication to pushing technological boundaries has been pivotal in establishing Abstrabit as a trailblazer in AI. Sawan’s strategic leadership and passion for innovation ensure that Abstrabit continuously sets new benchmarks in AI-driven solutions, fostering a culture of relentless growth and cutting-edge development.",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/about-assets/sawan.jpeg", // Replace with the actual path to the image
    bgGradient: "gradient-purple",
    experiences: "Ex- Microsoft, Oracle & VMware",
    profile: "https://www.linkedin.com/in/sarauniyar/",
  },
  {
    name: "Manash Konwar",
    title: "Principal AI/ML Consultant",
    description:
      "Manash Konwar is an accomplished AI/ML expert with a robust foundation in data science and software development. With a career dedicated to developing innovative solutions for complex business challenges, Manash has made significant contributions across various industries. His experience includes leading technical teams at Accenture AI and Abstrabit, and he is currently co-founding Earth & We, where he spearheads AI-powered transformations in e-commerce and supply chain management. His leadership and expertise continue to push the boundaries of AI innovation.",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/about-assets/manas.png", // Replace with the actual path to the image
    bgGradient: "gradient-orange",
    experiences: "Ex-Accenture",

    profile: "https://www.linkedin.com/in/manashjkonwar/",
  },
];

export default teamData;
