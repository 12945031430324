import React from "react";

import MetaData from "../../components/layout/MetaData";
import PrivacyHero from "./PrivacyHero";

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col gap-20 md:gap-44   bg-[var(--background-color)]">
      <PrivacyHero />
      <MetaData title="Privacy Policy - Abstrabit Technologies" />
    </div>
  );
};

export default PrivacyPolicy;
