import React, { useEffect, useState } from "react";
import EngagementModelsHero from "./EngagementModelsHero";
import EngagementTable from "./EngagementTable";
import ContactUs from "../contact-us/ContactUs";
import MetaData from "../../components/layout/MetaData";
import { useLocation } from "react-router-dom";

const Pricing = () => {
  const [activeTab, setActiveTab] = useState(0);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let tabIndex;
      if (location.hash === "#fixed-cost-product-development") {
        tabIndex = 0;
      } else if (location.hash === "#dedicated-tech-team") {
        tabIndex = 1;
      } else {
        tabIndex = 2;
      }

      setActiveTab(tabIndex);

      // Then, wait for the DOM to update and scroll to the target
      setTimeout(() => {
        const element = document.querySelector(location.hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 520); // A short delay to ensure content is updated
    }
  }, [location]);

  return (
    <div className="flex flex-col   gap-20 md:gap-32 bg-[var(--background-color)]">
      <EngagementModelsHero />
      <EngagementTable setActiveTab={setActiveTab} activeTab={activeTab} />
      <ContactUs heading={"Ready to Get Started ?"} />
      <MetaData title="Pricing - Abstrabit Technology" />
    </div>
  );
};

export default Pricing;
