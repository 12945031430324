import { createContext, useContext, useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../../firebase";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loadingAuthState, setLoadingAuthState] = useState(true); // For checking initial user state
  const [loadingSignIn, setLoadingSignIn] = useState(false); // For Sign In loading
  const [loadingSignUp, setLoadingSignUp] = useState(false); // For Sign Up loading

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoadingAuthState(false); // Finished loading auth state
    });
    return unsubscribe;
  }, []);

  const login = async (email, password) => {
    setLoadingSignIn(true); // Start loading for sign in
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } finally {
      setLoadingSignIn(false); // Stop loading after sign in is finished
    }
  };

  const signup = async (email, password) => {
    setLoadingSignUp(true); // Start loading for sign up
    try {
      await createUserWithEmailAndPassword(auth, email, password);
    } finally {
      setLoadingSignUp(false); // Stop loading after sign up is finished
    }
  };

  const logout = async () => {
    return signOut(auth);
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        login,
        signup,
        logout,
        loadingAuthState,
        loadingSignIn,
        loadingSignUp,
      }}
    >
      {!loadingAuthState && children}
    </AuthContext.Provider>
  );
};

// Hook to use AuthContext
export const useAuth = () => useContext(AuthContext);
