import React from "react";
import ServiceHero from "./ServiceHero";
import OurCapabilities from "./OurCapabilities";
import OurTools from "./OurTools";
import OurWorksSection from "../home/OurWorksSection";
import ClientsSection from "../home/ClientsSection";
import WorkingProcess from "./WorkingProcess";
import ContactUs from "../contact-us/ContactUs";
import MetaData from "../../components/layout/MetaData";



const Service = () => {


  return (
    <div className="flex flex-col   bg-[var(--background-color)] gap-20 md:gap-32">
      <ServiceHero />
      <OurCapabilities />
      <OurTools />
      <WorkingProcess />
      <OurWorksSection />
      <ClientsSection />
      <ContactUs heading={"Partner with Us to Bring Your Vision to Life"} />
      <MetaData title="Services - Abstrabit Technologies" />
    </div>
  );
};

export default Service;
