import React, { useState } from "react";

const EngagementTableCommon = ({
  section1,
  section2,
  section3,
  section4,
  section5,
  tab,
  activeTab,
}) => {
  const [hoverIndex, setIsHoverIndex] = useState(null);

  return (
    <div
      className="md:col-span-2 space-y-14 lg:space-y-28 top-20 relative"
      id="#fixed-cost-product-development"
    >
      {/* Overview Section */}
      {section1 &&
        section1.map((item, index) => (
          <section key={index} className="md:space-y-3">
            <h1 className="lg:text-3xl  md:text-3xl text-3xl font-semibold">
              {item.heading1}
            </h1>

            <h2 className="text-3xl md:-space-y-3 -space-y-4 flex flex-col md:text-4xl md:text-[56px] font-semibold">
              <span className="md:leading-[40px] textColor">
                {" "}
                {item.heading2}
              </span>
              <span className="md:leading-[70px]  textColor">
                {" "}
                {item.heading3}
              </span>
            </h2>

            <div className="flex flex-col mt-2 items-start justify-start bg-[#161617] p-4">
              <p className="text-base md:text-base font-semibold lg:text-3xl">
                {item.description}
              </p>
              <p className="text-base mt-3 md:text-base font-normal lg:text-xl text-[#7D7C88]">
                {item.subdescription}
              </p>
            </div>
          </section>
        ))}

      {/* Review Their Technical Skills Section */}
      {section2 &&
        section2.map((item, index) => (
          <section key={index} id="review-technical-skills">
            <div className="bg-black text-white">
              <h1 className="text-3xl md:space-y-0 space-y-0 md:text-[56px] font-semibold flex flex-col items-start justify-start">
                <span className="lg:mb-3"> {item.heading1}</span>
                <span className="">
                  <span className="textColorPurple md:leading-[60px] font-semibold">
                    {item.heading2}
                  </span>
                  <span className="textColorPurple md:leading-[60px]  font-semibold">
                    {" "}
                    {item.heading3}
                  </span>
                </span>
              </h1>
              <p className="text-white mt-4 md:mt-6 text-base md:text-xl">
                {item.description}
              </p>

              <div className="flex flex-col md:flex-row mt-4 md:mt-12">
                <div className=" space-y-3 md:space-y-6">
                  {item.list &&
                    item.list.map((subitem, index) => (
                      <div key={index} className="flex items-start space-x-4">
                        <div>
                          <h3 className="text-xl flex gap-2 md:text-4xl font-semibold">
                            <img
                              src={
                                "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/sk.svg"
                              }
                              alt="active logo"
                              className="w-3 md:w-5"
                            />{" "}
                            {subitem.heading}
                          </h3>
                          <p className="text-[#7D7C88]  font-normal mt-2 text-base md:text-xl">
                            {subitem.list1}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
                <div className=" mt-6 md:mt-0 md:ml-4">
                  <img
                    src={item.icon} // replace this with the path to your image
                    alt="Business Meeting"
                    className="rounded-lg w-full h-full object-cover"
                  />
                </div>
              </div>
            </div>
          </section>
        ))}
      {section3 &&
        section3.map((item, index) => (
          <section key={index}>
            {" "}
            <div className="text-start">
              <h1 className="text-3xl tap-0 -space-y-1 md:text-[56px] font-semibold flex flex-col items-start justify-start">
                <span className="lg:mb-3"> {item.heading1}</span>
                <span className="textColorPurple font-semibold md:leading-[60px]">
                  {item.heading2}
                </span>
                <span className="textColorPurple font-semibold md:leading-[60px]">
                  {item.heading3}
                </span>
              </h1>

              <p className="text-base md:text-base lg:text-xl mt-3 md:mt-5 ">
                {item.description}
              </p>

              <div className="flex text-base md:text-xl mt-3 flex-col items-start justify-start gap-3 font-semibold">
                {item.list &&
                  item.list.map((subitem, ind) => (
                    <div key={ind}>
                      <div
                        key={ind}
                        className="flex items-center justify-center gap-2 text-base md:text-xl"
                      >
                        <img
                          className="w-3 md:w-6 md:h-4 h-3"
                          src={
                            "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/sk.svg"
                          }
                          alt="active logo"
                        />
                        {subitem.list1}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </section>
        ))}

      {section4 &&
        section4.map((item, index) => (
          <section key={index}>
            <div className="bg-black text-white ">
              <h2 className="text-3xl md:text-[56px] font-semibold text-white mb-8">
                {item.heading}
              </h2>
              <div className="space-y-6">
                {item.list &&
                  item.list.map((step, index) => (
                    <div
                      key={index}
                      onMouseEnter={() => setIsHoverIndex(index)}
                      onMouseLeave={() => setIsHoverIndex(null)}
                      className="flex hover:text-purple-700 hover:bg-white items-center space-x-3 lg:space-x-4 bg-[#161616] p-4 md:p-6 rounded-lg"
                    >
                      <div
                        className={` ${
                          hoverIndex === index ? "text-white bg-purple-700" : ""
                        }  bg-[var(--background-color)] text-purple-600 text-4xl lg:text-6xl font-semibold  p-10 lg:p-12 w-12 h-12 md:w-16 md:h-16 flex items-center justify-center rounded`}
                      >
                        {index + 1}
                      </div>
                      <div>
                        <h3 className="text-2xl md:text-4xl font-semibold">
                          {step.heading}
                        </h3>
                        <p className="text-[#7D7C88] mt-1 text-base md:text-xl">
                          {step.list1}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </section>
        ))}

      {section5 &&
        section5.map((item, index) => (
          <section key={index}>
            <div className=" flex flex-col justify-center items-center lg:items-start lg:justify-start">
              <h1 className="text-3xl md:text-[56px] font-semibold flex flex-col justify-center items-center lg:items-start lg:justify-start">
                <span className="lg:mb-1">{item.heading1}</span>
                <span className="textColorPurple font-semibold md:leading-[60px]">
                  {item.heading2}
                </span>
                <span className="textColorPurple font-semibold md:leading-[60px] lg:-mt-4">
                  {" "}
                  {item.heading3}
                </span>
              </h1>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-10">
                {item.list &&
                  item.list.map((subitem, index) => (
                    <div
                      key={index}
                      className="flex flex-col text-white hover:bg-white hover:text-purple-700 items-center p-6 bg-[#161617] rounded-lg"
                    >
                      <img
                        src={subitem.icon}
                        alt="Enhanced Collaboration"
                        className="mb-4"
                      />
                      <p className="text-xl ">{subitem.heading}</p>
                    </div>
                  ))}
              </div>
            </div>
          </section>
        ))}
    </div>
  );
};

export default EngagementTableCommon;
