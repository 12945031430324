export const headerData = [
  { name: "Home", link: "/" },
  { name: "About us", link: "/about-us" },
  {
    name: "Services",
    link: "/services",
    submenu: [
      {
        name: "AI Strategy & Consulting",
        link: "/services/ai-strategy-consulting",
      },
      {
        name: "Generative AI Solutions",
        link: "/services/generative-ai-solutions",
      },
      {
        name: "AI Chatbot Solutions",
        link: "/services/ai-chatbot-solutions",
      },
      {
        name: "AI Knowledge Assistants",
        link: "/services/ai-knowledge-assistants",
      },
      {
        name: "Workflow Automation with GPT",
        link: "/services/workflow-automation-gpt",
      },
      {
        name: "AI-Powered App Development",
        link: "/services/ai-app-development",
      },
    ],
  },
  {
    name: "Pricing",
    link: "/pricing",
    submenu: [
      {
        name: "Fixed-Cost Product Development",
        link: "/engagement-models/fixed-cost-product-development#fixed-cost-product-development",
      },
      {
        name: "Dedicated Tech Team",
        link: "/engagement-models/dedicated-tech-team",
      },
      {
        name: "Maintenance and Upgradation",
        link: "/engagement-models/maintenance-upgradation",
      },
    ],
  },
  {
    name: "Resources",
    link: "#",
    submenu: [
      { name: "Case Studies", link: "/portfolio" },
      {
        name: "Blogs",
        link: "/resources/blogs",
      },
      {
        name: "Whitepapers",
        link: "/resources/whitepapers",
      },
      {
        name: "E-books",
        link: "/resources/e-books",
      },
    ],
  },
  { name: "Careers", link: "/careers" },
];
