import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function HoveredSubMenuItem({ name, link, active, setOpen }) {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(link);
        setOpen(false);
      }}
      className={`my-2 cursor-pointer rounded-md p-2 ${
        active ? "bg-gray-300" : "hover:bg-indigo-50"
      }`}
    >
      <div className="flex items-center justify-center">
        <span className="text-primary-500 ml-3 w-28 text-start">{name}</span>
      </div>
    </div>
  );
}

export default function SidebarItem({
  name,
  active = false,
  link,
  expanded = false,
  submenu = null,
  setOpen = () => {},
  onClick = () => {},
}) {
  const [expandSubMenu, setExpandSubMenu] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!expanded) {
      setExpandSubMenu(false);
    }
  }, [expanded]);

  const subMenuHeight = expandSubMenu
    ? `${(submenu?.length || 0) * 40 + (submenu ? 15 : 0)}px`
    : "0px";

  const subMenuHeightServices = expandSubMenu
    ? `${(submenu?.length || 0) * 40 + (submenu ? 48 : 0)}px`
    : "0px";

  return (
    <>
      <li
        onClick={() => {
          navigate(link, {
            state: name,
          });
          setOpen(false);
        }}
        className=""
      >
        <button
          className={`
            my-1 flex w-full cursor-pointer
            items-center rounded-md 
           ${expandSubMenu ? "py-2" : "py-2"}  transition-colors
            ${active ? "text-primary-500 bg-purple-700" : "text-white "}
            ${!expanded && "hidden sm:flex"}
          `}
          onClick={(e) => {
            if (submenu) {
              //   e.stopPropagation(); // Prevent navigation when clicking the arrow
              setExpandSubMenu((curr) => expanded && !curr);
            }
            onClick();
          }}
        >
          <div
            className={`flex items-center justify-between w-full transition-all ${
              expanded ? "ml-3" : "w-0"
            }`}
          >
            <span className="flex-grow text-start">{name}</span>
            {submenu && (
              <div
                onClick={(e) => {
                  e.stopPropagation(); // Prevent parent onClick when clicking arrow
                  setExpandSubMenu((curr) => expanded && !curr);
                }}
                className={`bg-white flex items-center justify-center w-5 h-5 rounded-full transition-transform ${
                  expandSubMenu ? "rotate-90" : "rotate-0"
                }`}
              >
                <ChevronRightIcon className="text-black h-4 w-4" />
              </div>
            )}
          </div>

          {!expanded && (
            <div
              className={`
                text-purple-500 invisible absolute left-full  -translate-x-3
                rounded-md bg-indigo-100 px-2
                py-1 text-sm opacity-20 transition-all
                group-hover:visible group-hover:translate-x-0 group-hover:opacity-100
              `}
            >
              {!submenu
                ? name
                : submenu.map((item, index) => (
                    <HoveredSubMenuItem
                      key={index}
                      name={item.name}
                      link={item.link}
                      active={item.active}
                      setOpen={setOpen}
                    />
                  ))}
            </div>
          )}
        </button>
      </li>
      <ul
        className="sub-menu pl-6"
        style={{
          height: `${
            name === "Services" ? subMenuHeightServices : subMenuHeight
          }`,
        }}
      >
        {expanded &&
          submenu?.map((item, index) => (
            <SidebarItem
              key={index}
              {...item}
              expanded={expanded}
              setOpen={setOpen}
            />
          ))}
      </ul>
    </>
  );
}
