import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ProjectSectionCommon from "../../components/common/ProjectSectionCommon";
import { clientProjectsData } from "../../components/data/HomeData";

const OurWorksSection = () => {
  const [showAll, setShowAll] = useState(false);
  const [displayedProjects, setDisplayedProjects] = useState([]);

  const updateDisplayedProjects = () => {
    const isDesktop = window.innerWidth >= 1024;
    const maxVisible = isDesktop ? 9 : 3;
    setDisplayedProjects(
      showAll ? clientProjectsData : clientProjectsData.slice(0, maxVisible)
    );
  };

  useEffect(() => {
    updateDisplayedProjects();
  }, [showAll]);

  useEffect(() => {
    const handleResize = () => updateDisplayedProjects();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="bg-black">
      <div className="text-[var(--text-color)] my-8 md:my-16 flex items-center justify-center flex-col ">
        <h2 className="text-center  md:text-[56px] text-3xl font-semibold  md:mb-14 mb-5">
          From Vision to Reality
        </h2>
        <Tabs className="bg-black">
          <TabList className="flex justify-center space-x-2 md:space-x-4 mb-8">
            <Tab
              selectedClassName="bg-gradient-to-r outline-none text-[var(--text-color)]  from-pink-500 to-orange-500 text-[#ffffff] md:px-7   px-3.5 py-2 rounded-full"
              className="md:px-7   px-3.5 text-center flex items-center justify-center md:py-2.5 py-2  md:text-xl text-xs  rounded-full cursor-pointer bg-[#FFFFFF] text-[#04010066]"
            >
              Featured
            </Tab>
            <Tab
              selectedClassName="bg-gradient-to-r outline-none text-[var(--text-color)]  from-pink-500 to-orange-500 text-[#ffffff]md:px-7   px-3.5 py-2 rounded-full"
              className="md:px-7   px-3.5 text-center flex items-center justify-center  md:py-2.5 py-2  md:text-xl text-xs  rounded-full cursor-pointer bg-[#FFFFFF] text-[#04010066]"
            >
              Gen AI
            </Tab>
            <Tab
              selectedClassName="bg-gradient-to-r outline-none text-[var(--text-color)]  from-pink-500 to-orange-500 text-[#ffffff]md:px-7   px-3.5 py-2 rounded-full"
              className="md:px-7   px-3.5 text-center flex items-center justify-center  md:py-2.5 py-2  md:text-xl text-xs  rounded-full cursor-pointer bg-[#FFFFFF] text-[#04010066]"
            >
              Web Apps
            </Tab>
            <Tab
              selectedClassName="bg-gradient-to-r outline-none text-[var(--text-color)]  from-pink-500 to-orange-500 text-[#ffffff] md:px-7 px-3.5 py-2 rounded-full"
              className="md:px-7  px-3.5 text-center flex items-center justify-center  md:py-2.5 py-2 md:text-xl text-xs  rounded-full cursor-pointer bg-[#FFFFFF] text-[#04010066]"
            >
              Mobile Apps
            </Tab>
          </TabList>

          <TabPanel className="gap-0 ">
            <ProjectSectionCommon displayedProjects={displayedProjects} />
          </TabPanel>

          <TabPanel>
            <p className="text-center">NO DATA FOUND</p>
          </TabPanel>
          <TabPanel>
            <p className="text-center">NO DATA FOUND</p>
          </TabPanel>
          <TabPanel>
            <p className="text-center">NO DATA FOUND</p>
          </TabPanel>

          {/* Repeat TabPanel for other tabs if content varies */}
        </Tabs>

        {/* {!showAll && (
          <div className="flex items-center justify-center mt-2 md:mt-8">
            <button
              className="btn font-semibold px-5 py-2 md:py-2.5 duration-500 md:static hover:text-red-500 bg-opacity-50  bg-custom-gradient hover:bg-custom-gradient-hover text-sm lg:text-lg rounded-full text-[var(--text-color)]"
              onClick={() => setShowAll(!showAll)}
            >
              <span className="mb-0">View More</span>
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default OurWorksSection;
