import React from "react";
import AboutHero from "./AboutHero";
import AboutDynamic from "./AboutDynamic";
import ClientsSection from "../home/ClientsSection";
import DynamicContentSection from "../home/DynamicContentSection";
import ConnectSection from "../home/ConnectionSection";
import Achievement from "./Achievement";
import MeetTeam from "./MeetTeam";
import FutureWithGenerativeAI from "./FutureWithGenerativeAI";
import ExperienceComponent from "./ExperienceComponent";
import AIToTransForm from "./AIToTransForm";


const AboutUs = () => {

  return (
    <div className="flex flex-col overflow-hidden  bg-[var(--background-color)] gap-20 md:gap-44">
      <AboutHero />
      <AIToTransForm />
      <FutureWithGenerativeAI />
      <ExperienceComponent />
      <AboutDynamic />
      <MeetTeam />
      <Achievement />
      <ClientsSection />
      <DynamicContentSection />
      <ConnectSection />
    </div>
  );
};

export default AboutUs;
