import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import profile from "../assets/Profile.png";
import { FaEye } from "react-icons/fa";
import { IoIosEyeOff } from "react-icons/io";
import toast from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";

import { useAuth } from "../../components/context/AuthContext";
import Loader from "../../components/layout/loading/Loader";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState(false);

  const { login, loadingSignIn } = useAuth();

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token); // Store the reCAPTCHA token
    setRecaptchaError(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      setRecaptchaError(true);
      return;
    }

    try {
      await login(email, password);
      toast.success("Login Successfully");
      navigate("/admin/all-users");
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-black">
      <div className="bg-[#161617] p-8 rounded-lg shadow-lg w-full max-w-sm mt-20">
        <div className="mb-3 text-center flex items-center justify-center flex-col"></div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-white mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="w-full p-2 text-gray-900 bg-gray-200 rounded"
              type="email"
              id="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Email id"
            />
          </div>
          <div className="mb-6">
            <label className="block text-white mb-2" htmlFor="password">
              Password
            </label>
            <div className="flex items-center justify-center">
              <input
                className="w-full p-2 text-gray-900 bg-gray-200 rounded"
                type={showPassword ? "text" : "password"}
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                placeholder="Enter Password"
              />
              {showPassword ? (
                <FaEye
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute text-xl translate-x-36"
                />
              ) : (
                <IoIosEyeOff
                  className="absolute text-xl translate-x-36"
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </div>
          </div>
          <div className="flex items-center justify-end gap-1">
            {/* <span className="text-white">Don't have an account?</span> */}
            <Link
              to="/signup"
              className="text-teal-200 hover:text-teal-400 hover:underline"
              title="not implemented"
            >
              {/* Sign Up */}
            </Link>
          </div>
          <div className="w-full">
            {" "}
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={handleRecaptchaChange}
            />
            {recaptchaError && (
              <p className="text-red-500 text-sm mt-2">
                Please complete the reCAPTCHA.
              </p>
            )}
          </div>
          <div className="flex items-center justify-center mt-6">
            <button
              type="submit"
              className="w-full py-2  gradient-orange text-white font-semibold rounded"
            >
              LOGIN {loadingSignIn ? <Loader /> : ""}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
