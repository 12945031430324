import React from "react";
import RemoteTeamSection from "./RemoteTeamSection";
import StandOutSection from "./StandOutSection";
import OurWorksSection from "./OurWorksSection";
import ClientsSection from "./ClientsSection";
import TestimonialSection from "./TestimonialSection";
import DynamicContentSection from "./DynamicContentSection";
import EngagementTools from "./EngagementTools";
import OurParterns from "./OurParterns";
import ExploreCareer from "./ExploreCareer";
import BlogSection from "./BlogSection";
import Faq from "./Faq";
import ConnectSection from "./ConnectionSection";
import OurCapabilities from "./OurCapabilities";
import HeroPage from "./HeroPage";
import MetaData from "../../components/layout/MetaData";
import { faqData } from "../../components/data/HomeData";


const Home = () => {



  return (
    <div className="flex flex-col gap-20 md:gap-44  bg-[#040100]">
      <MetaData title="Home" />
      <HeroPage />
      <RemoteTeamSection />
      <StandOutSection />
      <OurCapabilities />
      <OurWorksSection />
      <ClientsSection />
      <TestimonialSection />
      <DynamicContentSection />
      <EngagementTools />
      <OurParterns />
      <ExploreCareer />
      <BlogSection />
      <Faq faqData={faqData} />
      <ConnectSection />
    </div>
  );
};

export default Home;
