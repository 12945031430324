import React, { useEffect, useState } from "react";

import { contentDataDynamicSection } from "../../components/data/HomeData";

const DynamicContentSection = () => {
  const [activeContent, setActiveContent] = useState(
    contentDataDynamicSection[0]
  );
  const [animatingTab, setAnimatingTab] = useState(activeContent.id); // Initialize with the first tab's ID
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getTranslateValue = () => {
    if (windowWidth >= 1000) {
      return "270px";
    } else if (windowWidth >= 768) {
      return "190px";
    } else {
      return "100px";
    }
  };

  useEffect(() => {
    if (animatingTab !== activeContent.id) {
      const timeout = setTimeout(() => {
        setAnimatingTab(activeContent.id); // Update animatingTab after animation duration
      }, 500); // Match with your animation duration

      return () => clearTimeout(timeout);
    }
  }, [activeContent, animatingTab]);

  return (
    <div className=" xl:px-16  text-[var(--text-color)]">
      <div className="max-w-7xl mx-auto md:px-16 lg:px-0 2xl:px-16">
        <h2 className="text-3xl ps-16 md:leading-[60px] space-y-2  mx-auto md:text-[56px] text-center font-semibold md:mb-28 mb-10">
          Clear AI Solutions, <br /> Real Business Results
        </h2>

        <div className="grid grid-cols-2 bg-[var(--background-color)] text-[var(--text-color)] items-center justify-center">
          <div className="flex w-[257px] md:w-[300px] lg:w-[360px] md:h-[400px] -ms-28 md:-ms-20 lg:-ms-16 h-[240px] lg:h-[550px] rounded-e-full items-center justify-center bg-[var(--background-gray-color)]">
            <h1 className="text-2xl p-3 md:text-5xl ms-24 md:-ms-24 lg:-ms-48 md:pe-40 lg:pe-32 lg:text-6xl font-semibold text-start lg:text-start">
              Why <br /> Choose <br /> Us?
            </h1>
            <div className="flex flex-col justify-center md:-ms-48 lg:-ms-60 gap-8 md:gap-5">
              {contentDataDynamicSection.map((item, index) => {
                const isActive = activeContent.id === item.id;
                const scaleValue = isActive ? 1.3 : 1;

                return (
                  <img
                    key={item.id}
                    src={item.icon}
                    alt={`Icon ${item.id}`}
                    className={`cursor-pointer -ms-24 md:-ms-24 absolute border-solid border-transparent border-[2.61px] md:border-[7px] rounded-full w-12 h-12 md:w-24 md:h-24 lg:w-28 lg:h-28 transition-transform duration-300 ${
                      isActive
                        ? "p-2 border-gradient-rounded md:p-5"
                        : "p-2 md:p-5 border-gradient-rounded1"
                    }`}
                    onClick={() => {
                      setActiveContent(item);
                    }}
                    style={{
                      transform: `rotate(${
                        index * (180 / (contentDataDynamicSection.length - 1)) -
                        90
                      }deg) translate(${getTranslateValue()}, 0) rotate(${
                        90 -
                        index * (180 / (contentDataDynamicSection.length - 1))
                      }deg) scale(${scaleValue})`,
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div className={` flex-1 text-start lg:text-left pe-6 lg:me-20`}>
            <h2
              className={`text-3xl md:text-4xl lg:text-7xl ${
                activeContent.heading === "Clear Communication, Every Step"
                  ? "lg:leading-[75px]"
                  : "lg:leading-[60px]"
              } font-semibold textColorPurple`}
            >
              {activeContent.heading}
            </h2>
            <p className="text-sm md:text-base lg:text-xl text-gray-300 mt-2 lg:mt-4">
              {activeContent.text}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicContentSection;
