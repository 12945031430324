import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { blogs } from "../../components/data/BlogData";

// Example testimonial data

const BlogCard = () => {
  const navigate = useNavigate();

  const handleGoToBlogDetailsPage = (id, blogItem) => {
    navigate(`/blog/blog-details/${id}`, {
      state: blogItem,
    });
  };

  function convertToSlug(text) {
    return text
      .toLowerCase() // Convert the text to lowercase
      .replace(/[^a-z0-9\s]/g, "") // Remove any non-alphanumeric characters except spaces
      .trim() // Trim any leading or trailing spaces
      .replace(/\s+/g, "-"); // Replace spaces with hyphens
  }

  return (
    <div className="">
      <section className="mx-auto max-w-7xl px-6 md:px-12 lg:px-16">
        <div className="flex flex-col text-[var(--text-color)]">
          <h2 className="md:text-center text-3xl md:text-[56px] text-center font-semibold md:mb-14 mb-5">
            Blogs
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-3 md:gap-5 gap-10">
            {blogs &&
              blogs.map((item, index) => (
                <div
                  key={item.id}
                  className="flex relative pb-20 bg-[#2D2D2D] flex-col items-start justify-start"
                >
                  <img
                    src={item.image}
                    alt=""
                    className="w-full lg:h-72 object-cover"
                  />
                  <div className="px-4 mt-5  sm:px-5 lg:px-8 flex flex-col items-start justify-start">
                    <div className="flex md:flex-col lg:flex-row text-[#7D7C88] text-sm md:text-lg font-normal items-start gap-2 justify-start">
                      <h4 className="text-left ">{item?.author}</h4>
                      <span>•</span>
                      <h5 className="text-start">{item?.date}</h5>
                    </div>

                    <p className="md:mt-2 mt-2 text-start text-lg md:text-xl lg:text-xl font-normal leading-6 md:leading-7 text-[var(--text-color)]">
                      {item?.title}
                    </p>
                  </div>

                  <button
                    onClick={() =>
                      handleGoToBlogDetailsPage(convertToSlug(item.title), item)
                    }
                    className="flex ms-7 text-base bottom-5 absolute lg:bottom-5 hover:bg-[var(--background-color-white)] hover:text-red-500  items-center md:text-lg font-semibold justify-center border border-white rounded-3xl text-[#ffffff] px-4 py-2 "
                  >
                    Read More <FiChevronRight className="mt-1" />
                  </button>
                </div>
              ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogCard;
