import React, { useState } from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";

import { Link, useNavigate } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { aiServices, caseStudies, company } from "./data/FooterData";
import axios from "axios";

const Footer = () => {
  const navigate = useNavigate();

  const handleGotoSubMenuRespectivePage = (link, heading) => {
    navigate(`${link}`, {
      state: heading,
    });
  };

  const [formData, setFormData] = useState({
    email: "",
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  function isValiEmail(val) {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      return "Enter a valid email address";
    }
  }

  const validate = () => {
    let errors = {};

    // Email validation
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (isValiEmail(formData.email)) {
      errors.email = "Enter a valid email address";
    }

    return errors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (value.trim() !== "") {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      try {
        const dataForm = {
          ...formData,
          date: new Date().toLocaleDateString(),
        };

        const config = { headers: { "Content-Type": "application/json" } };

        const data = await axios.post(
          `https://abstratbit-default-rtdb.firebaseio.com/subscribedUsers.json`,
          dataForm,
          config
        );

        console.log(data, "kashif");

        if (data.status === 200) {
          setSuccessMessage("Updates are on the way!");
          setFormData({
            email: "",
          });
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
      }
    } else {
      setErrors(validationErrors);
      setSuccessMessage("");
    }
  };

  return (
    <footer className="bg-[var(--background-color)] text-[var(--text-color)]">
      <div
        style={{
          backgroundImage: `url(https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/homelogo.svg)`,
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        }}
        className="bg-center bg-cover"
      >
        <div className="gradient-orange">
          <div className="py-3 lg:py-4 px-6 md:px-12 lg:px-16 mx-auto max-w-7xl flex flex-col items-start justify-start lg:flex-row lg:items-center lg:justify-between   text-[var(--text-color)]">
            <p className="font-semibold sm:text-base lg:text-base md:text-sm text-sm text-start">
              Subscribe to our newsletter for the latest updates in Generative
              AI and exclusive insights for SMEs
            </p>

            <div className=" flex flex-col justify-center w-full lg:w-[37%] ">
              <div
                className={`${
                  successMessage
                    ? "bg-[#0B8534] text-white border-2 border-white"
                    : "bg-[var(--background-color-white)]"
                }  flex mt-3 lg:mt-3  items-center justify-center   rounded-full ${
                  errors.email ? "border-red-500 border-2" : ""
                }`}
              >
                {successMessage ? (
                  <p className=" m-3 text-base text-center lg:text-xl">
                    {successMessage}
                  </p>
                ) : (
                  <>
                    {" "}
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Enter email address"
                      className={`py-3 ps-5 pe-2 w-full text-base md:text-xl text-gray-600 rounded-l-full placeholder-[#B1B5BB] focus:outline-none`}
                    />
                    <button
                      onClick={handleSubmit}
                      className="gradient-purple1 p-3 m-0.5 w-32 md:w-52 text-[var(--text-color)] text-sm md:text-base font-semibold rounded-full"
                    >
                      Subscribe
                    </button>
                  </>
                )}
              </div>
              {errors.email && (
                <span className="text-red-500 p-1 w-1/2 text-center rounded-lg bg-white bg-opacity-30 text-base ms-3">
                  {errors.email}
                </span>
              )}
            </div>
          </div>
        </div>

        {/* Main Footer Section */}
        <div className="py-8 md:py-10  px-6 md:px-16 mx-auto max-w-7xl text-[var(--text-color)]">
          <div className="flex md:gap-8 lg:gap-6 gap-6 flex-col md:flex-wrap md:flex-row lg:flex-row items-start justify-start">
            {" "}
            <div className="">
              <img
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/footer-logo.svg"
                }
                alt="Footer Logo"
                className="w-[177px] md:w-[306px] lg:h-[94px]"
              />

              <a
                onClick={() => {
                  window.open(
                    "https://www.google.com/maps/place/WeWork/@12.9960689,77.667027,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae1170822ba09d:0xca99fa490d3a8eef!8m2!3d12.9960689!4d77.6692157",
                    "_blank"
                  );
                }}
                className="flex link-text hover:font-semibold cursor-pointer items-center justify-start mt-2 lg:mt-3 gap-2"
              >
                <FaLocationDot className="lg:text-2xl text-[var(--text-color)]" />
                <p className=" text-sm md:text-sm font-medium leading-5">
                  WeWork Salarpuria Magnificia,
                  <br className="md:block hidden" /> 78 Old Madras Road,
                  Mahadevapura, <br className="md:block hidden" /> Bengaluru,
                  Karnataka- 560016
                </p>
              </a>
              <p
                onClick={() => {
                  window.location = "mailto:info@abstrabit.com";
                }}
                title="Email Id"
                className="lg:mt-4 mt-2  link-text hover:font-semibold cursor-pointer  flex items-center justify-start gap-2 italic text-sm md:text-base font-semibold"
              >
                <MdOutlineEmail className="text-[var(--text-color)]" />{" "}
                info@abstrabit.com
              </p>
            </div>
            <div>
              <h4 className="mb-2 md:mb-4 text-xl md:text-2xl font-semibold">
                Company
              </h4>
              <ul className="space-y-2 text-sm md:text-base font-medium">
                {company.map((item, index) => (
                  <li key={index}>
                    <Link
                      to={item.link}
                      className="link-text hover:font-semibold"
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="relative lg:-right-7">
              <h4 className="mb-2 md:mb-4 text-xl md:text-2xl font-semibold">
                Services
              </h4>
              <ul className="space-y-2 text-sm md:text-base font-medium">
                {aiServices.map((item, index) => (
                  <li key={index}>
                    <a
                      onClick={() =>
                        handleGotoSubMenuRespectivePage(item.link, item.title)
                      }
                      className="link-text hover:font-semibold cursor-pointer"
                    >
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="relative lg:-right-9">
              <h4 className="mb-2 md:mb-4 text-xl md:text-2xl font-semibold">
                Case Studies
              </h4>
              <ul className="space-y-2 text-sm md:text-base font-medium">
                {caseStudies.map((item, index) => (
                  <li key={index}>
                    <Link
                      to={item.link}
                      className="link-text hover:font-semibold"
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h4 className="mb-2 md:mb-4 text-xl md:text-2xl font-semibold">
                Follow us
              </h4>
              <div className="flex space-x-3">
                <Link
                  onClick={() => {
                    window.open("https://www.facebook.com/abstrabit", "_blank");
                  }}
                  className="transition-transform transform hover:scale-125"
                >
                  <FaFacebookF className="text-black  iconBackColor hover:text-[var(--text-color)] w-8 h-8  rounded-full p-2" />
                </Link>
                <Link
                  onClick={() => {
                    window.open(
                      "https://www.instagram.com/abstrabittechnologies/",
                      "_blank"
                    );
                  }}
                  className="transition-transform transform hover:scale-125"
                >
                  {" "}
                  <FaInstagram className="text-black iconBackColor hover:text-[var(--text-color)] w-8 h-8  rounded-full p-2" />
                </Link>
                <Link
                  onClick={() => {
                    window.open("  https://twitter.com/abstrabit", "_blank");
                  }}
                  className="transition-transform transform hover:scale-125"
                >
                  <FaTwitter className="text-black iconBackColor hover:text-[var(--text-color)] w-8 h-8  rounded-full p-2" />
                </Link>
                <Link
                  onClick={() => {
                    window.open(
                      "https://www.linkedin.com/company/abstrabit/mycompany/",
                      "_blank"
                    );
                  }}
                  className="transition-transform transform hover:scale-125"
                >
                  <FaLinkedinIn className="text-black iconBackColor hover:text-[var(--text-color)] w-8 h-8  rounded-full p-2" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Bottom Footer */}
        <div className="bg-[var(--background-gray-color)] text-sm md:text-base text-[#7D7C88] text-center py-4">
          &copy; 2024 Abstrabit Technologies
        </div>
      </div>
    </footer>
  );
};

export default Footer;
