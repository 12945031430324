import React from "react";

import { exploreCareerData } from "../../components/data/HomeData";

const ExploreCareer = () => {
  return (
    <div className="">
      <div className="bg-[var(--background-gray-color)]  ">
        <div className="flex mx-auto max-w-7xl pb-10 px-6 md:px-16 flex-col  text-[var(--text-color)] ">
          <div className="flex flex-col items-center pt-5 lg:-space-y-2 justify-center md:mb-14 mb-5">
            <h1 className="md:text-[56px] md:leading-[60px]  text-3xl lg:leading-[90px] textColor text-center font-bold">
              Shape the Future of AI with Us
            </h1>
            <p className="md:text-xl text-base font-medium text-center">
              Discover exciting career opportunities and grow with our expert
              team.
            </p>
          </div>

          <div className=" grid grid-cols-2 md:grid-cols-4  gap-5 ">
            {/* First Image */}
            <img
              className="w-full lg:w-[264px] h-auto lg:h-[463px] object-cover"
              src={
                "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/ec1.svg"
              }
              alt="vcb"
            />

            {/* Middle Section with Two Images */}
            <div className="space-y-3">
              <img
                className="w-full lg:w-[264px] h-auto lg:h-[226px] object-cover rounded-lg"
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/ec2.svg"
                }
                alt="fggf"
              />
              <img
                className="w-full lg:w-[264px] h-auto lg:h-[226px] object-cover rounded-lg"
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/ec3.svg"
                }
                alt="ert"
              />
            </div>

            {/* Third Image */}
            <img
              className="w-full flex-grow lg:w-[441px] h-full rounded-lg lg:h-[463px] object-cover"
              src={
                "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/ec4.svg"
              }
              alt="er"
            />

            {/* Last Section with Two Images */}
            <div className="space-y-3">
              <img
                className="w-full lg:w-[264px] h-auto lg:h-[226px] object-cover rounded-lg"
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/ec5.svg"
                }
                alt="fd"
              />
              <img
                className="w-full lg:w-[264px] h-auto lg:h-[226px] object-cover rounded-lg"
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/ec6.svg"
                }
                alt="dsf"
              />
            </div>
          </div>

          <div className="flex flex-col items-center justify-center ">
            <h1 className="md:text-[56px] text-3xl text-center md:my-16 my-9 font-semibold">
              Why Join Us?
            </h1>
            <div className="grid md:grid-cols-3 grid-cols-1 gap-8 md:gap-12 max-w-5xl">
              {exploreCareerData.map((section, index) => (
                <div key={index} className="">
                  <div className="text-[var(--text-color)] gap-3 font-semibold flex  items-center justify-center md:justify-start text-xl md:text-2xl">
                    {section.title}
                    <img
                      src={section.icon}
                      className={`fas ${section.icon} w-3 md:w-auto`}
                      alt="exp"
                    />
                  </div>
                  <div className="text-[#7D7C88] font-medium text-center md:text-start text-base md:text-lg leading-6">
                    <p
                      dangerouslySetInnerHTML={{ __html: section.description }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-center my-7 md:my-10">
              <button className="btn font-semibold px-5 py-2 duration-500 md:static hover:text-red-500 bg-opacity-50  bg-custom-gradient hover:bg-custom-gradient-hover text-base lg:text-lg rounded-full text-[var(--text-color)]">
                <span className="mb-0">See All Vacancies</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreCareer;
