import React from "react";
import { Link } from "react-router-dom";

import AboutReviewSection from "../about-us/AboutReviewSection";

const CareersHero = () => {
  return (
    <div className="bg-[var(--background-color)] text-[var(--text-color)]">
      <div
        style={{
          backgroundImage: `url(https://abswebsiteassets.blob.core.windows.net/websiteimages/career-assets/carbg.svg)`,
        }}
        className="flex   overflow-hidden pt-14 md:pt-20 bg-cover bg-center"
      >
        <div className="lg:pb-28 max-w-7xl px-6 md:px-12 lg:px-16 mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 mt-14">
            <div className="flex   animate-fade-in-down space-y-4  lg:space-y-10  flex-col items-center md:items-start justify-center md:justify-start">
              <div className="flex flex-col lg:flex-row md:items-start items-center justify-center md:justify-start w-full relative font-semibold md:font-semibold text-5xl lg:text-6xl">
                <p className=" text-center md:text-start flex flex-col">
                  <span className="">Together</span>{" "}
                  <span className="textColorPurple mb-0">We Abstract</span>
                  <span className="textColorPurple -mt-2"> the Bits!</span>
                </p>
              </div>
              <p className="text-[var(--text-color)] md:text-start text-center text-base lg:text-xl  font-medium">
                Explore our expert-crafted articles and insights to stay updated
                on the latest trends, innovative solutions, and best practices
                driving technology success.
              </p>

              <Link
                className="btn bg-cover hover:text-red-500 bg-opacity-50  bg-custom-gradient hover:bg-custom-gradient-hover text-base lg:text-lg rounded-full text-[var(--text-color)]
               font-semibold px-5 py-2.5 duration-500 md:static"
              >
                Request a Quote
              </Link>
            </div>

            <div className="animate-fade-in-right lg:ms-20 mt-10 md:mt-0">
              <img
                src="https://abswebsiteassets.blob.core.windows.net/websiteimages/career-assets/iq.svg"
                alt=""
                className=""
              />
            </div>
          </div>
          <div className="lg:-mt-64 max-w-2xl animate-fade-in-down">
            {" "}
            <AboutReviewSection page={"portfolio"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersHero;
