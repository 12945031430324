import React, { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import WorkingProcessCommon from "../../components/common/WorkingProcessCommon";
import {
  getDescData1,
  getDescData2,
  getDescData3,
  getDescData4,
  getHeadingData1,
  getHeadingData2,
  getHeadingData3,
  getHeadingData4,
  getTabData1,
  getTabData2,
  getTabData3,
  getTabData4,
  Tab1Card,
  Tab2Card,
  Tab3Card,
  Tab4Card,
} from "../../components/data/ServiceData";

const WorkingProcess = ({ title, desc, page, currentPage }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [animatingTab, setAnimatingTab] = useState(0);

  const handleTabClick = (index) => {
    setAnimatingTab(index); // Set the tab that's starting the animation
    setTimeout(() => {
      setActiveTab(index); // Update the active tab after animation is done
    }, 200); // Match this to the animation duration
  };

  return (
    <div className="bg-[var(--background-color)]">
      <div className="max-w-7xl mx-auto px-6 md:px-12 lg:px-16 ">
        <div className=" text-[var(--text-color)]  flex items-center justify-center flex-col ">
          <h2
            className={`text-center  ${
              page === "serviceDetails" ? "textColor md:leading-[60px]" : ""
            } md:text-[56px] text-3xl font-medium mb-3 md:mb-3`}
          >
            {page === "serviceDetails" ? title : " How We Work"}
          </h2>

          {page === "serviceDetails" ? (
            <p className="text-center max-w-2xl md:text-xl text-base font-medium mb-7 md:mb-14">
              {desc}
            </p>
          ) : (
            <p className="text-center md:text-xl text-base font-normal mb-14">
              We combine deep industry knowledge with cutting-edge AI
              technologies to deliver <br className="md:block hidden" />{" "}
              tailored solutions that drive real results.
            </p>
          )}

          <Tabs
            selectedIndex={activeTab}
            onSelect={(index) => handleTabClick(index)}
          >
            <TabList className="-ms-10 md:-ms-0 md:space-x-14 flex justify-center items-center space-x-10 lg:space-x-28 mb-2 relative">
              <div className="absolute left-3 lg:left-16 inset-0 flex items-center justify-center">
                <div className="lg:w-[45%] md:w-[45%] w-[68%] border md:border-[1.3px] border-dotted border-gray-400"></div>
              </div>
              <Tab
                selectedClassName=" outline-none text-[var(--text-color)] gradient-purple md:w-14 md:h-14 w-10 h-10 rounded-full flex items-center justify-center text-[10px] md:text-xl"
                className="bg-[var(--background-color-white)] p-2 md:p-1 z-10 md:w-14 md:h-14 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer text-gray-600"
              >
                {activeTab === 0 ? (
                  <img
                    src={
                      "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/wt1.svg"
                    }
                    alt=""
                  />
                ) : (
                  <img
                    src={
                      "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/wt12.svg"
                    }
                    alt=""
                  />
                )}
              </Tab>
              <Tab
                selectedClassName="gradient-purple outline-none text-[var(--text-color)]  md:w-14 md:h-14 w-10 h-10 rounded-full flex items-center justify-center text-[10px] md:text-xl"
                className="bg-[var(--background-color-white)] p-2 md:p-1 z-10 md:w-14 md:h-14 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer text-gray-600"
              >
                {activeTab === 1 ? (
                  <img
                    src={
                      "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/wt2.svg"
                    }
                    alt=""
                  />
                ) : (
                  <img
                    src={
                      "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/wt6.svg"
                    }
                    alt=""
                  />
                )}
              </Tab>
              <Tab
                selectedClassName="gradient-purple outline-none text-[var(--text-color)]  md:w-14 md:h-14 w-10 h-10 rounded-full flex items-center justify-center text-[10px] md:text-xl"
                className="bg-[var(--background-color-white)] z-10 p-2 md:p-1 md:w-14 md:h-14 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer text-gray-600"
              >
                {activeTab === 2 ? (
                  <img
                    src={
                      "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/wt3.svg"
                    }
                    alt=""
                  />
                ) : (
                  <img
                    src={
                      "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/wt7.svg"
                    }
                    alt=""
                  />
                )}
              </Tab>
              <Tab
                selectedClassName="gradient-purple outline-none text-[var(--text-color)]  md:w-14 md:h-14 w-10 h-10 rounded-full flex items-center justify-center text-[10px] md:text-xl"
                className="bg-[var(--background-color-white)] p-2 md:p-1 z-10 md:w-14 md:h-14 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer text-gray-600"
              >
                {activeTab === 3 ? (
                  <img
                    src={
                      "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/wt4.svg"
                    }
                    alt=""
                  />
                ) : (
                  <img
                    src={
                      "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/wt8.svg"
                    }
                    alt=""
                  />
                )}
              </Tab>
            </TabList>

            <TabPanel>
              <div
                className={`tab-content ${
                  animatingTab === 0 ? "slide-in" : ""
                }`}
              >
                <WorkingProcessCommon
                  heading={
                    page === "serviceDetails"
                      ? getHeadingData1(currentPage)
                      : "Tailored AI Consulting Excellence"
                  }
                  icon={
                    "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/da.svg"
                  }
                  tabCard={
                    page === "serviceDetails"
                      ? getTabData1(currentPage)
                      : Tab1Card
                  }
                  desc={
                    page === "serviceDetails"
                      ? getDescData1(currentPage)
                      : "We provide customized AI consulting services, starting with understanding your needs and strategically planning to integrate AI into your business processes, ensuring alignment with your goals."
                  }
                />
              </div>
            </TabPanel>
            <TabPanel>
              <div
                className={`tab-content ${
                  animatingTab === 1 ? "slide-in" : ""
                }`}
              >
                <WorkingProcessCommon
                  heading={
                    page === "serviceDetails"
                      ? getHeadingData2(currentPage)
                      : "Precision in AI Design"
                  }
                  icon={
                    "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/sd.svg"
                  }
                  tabCard={
                    page === "serviceDetails"
                      ? getTabData2(currentPage)
                      : Tab2Card
                  }
                  desc={
                    page === "serviceDetails"
                      ? getDescData2(currentPage)
                      : "We craft customized AI solutions tailored to your unique needs, ensuring seamless integration that enhances your operations."
                  }
                />
              </div>
            </TabPanel>
            <TabPanel>
              <div
                className={`tab-content ${
                  animatingTab === 2 ? "slide-in" : ""
                }`}
              >
                <WorkingProcessCommon
                  heading={
                    page === "serviceDetails"
                      ? getHeadingData3(currentPage)
                      : "Flawless AI System Integration"
                  }
                  icon={
                    "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/ai.svg"
                  }
                  tabCard={
                    page === "serviceDetails"
                      ? getTabData3(currentPage)
                      : Tab3Card
                  }
                  desc={
                    page === "serviceDetails"
                      ? getDescData3(currentPage)
                      : "We specialize in seamlessly integrating AI technologies with your existing systems, ensuring minimal disruption and maximum efficiency."
                  }
                />
              </div>
            </TabPanel>

            <TabPanel>
              <div
                className={`tab-content ${
                  animatingTab === 3 ? "slide-in" : ""
                }`}
              >
                <WorkingProcessCommon
                  heading={
                    page === "serviceDetails"
                      ? getHeadingData4(currentPage)
                      : "Sustained AI Success Framework"
                  }
                  icon={
                    "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/ep.svg"
                  }
                  tabCard={
                    page === "serviceDetails"
                      ? getTabData4(currentPage)
                      : Tab4Card
                  }
                  desc={
                    page === "serviceDetails"
                      ? getDescData4(currentPage)
                      : "We deliver continuous support and optimize AI solutions, ensuring lasting business impact and growth."
                  }
                />
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default WorkingProcess;
