import React from "react";
import ContactUs from "./ContactUs";

const ContactUsMain = () => {
  return (
    <div className="pt-16 md:pt-24 bg-[#161616]">
      <ContactUs heading={"Partner with Us to Bring Your Vision to Life"} />
    </div>
  );
};

export default ContactUsMain;
