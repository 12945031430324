import React, { useState } from "react";

import { FiChevronRight } from "react-icons/fi";
import { capabilitiesContentData } from "../../components/data/HomeData";
import { useNavigate } from "react-router-dom";

const OurCapabilities = () => {
  const [activeContent, setActiveContent] = useState(
    capabilitiesContentData[0]
  );
  const [isClicked, setIsclicked] = useState(capabilitiesContentData[0]);

  const navigate = useNavigate();

  return (
    <div className="">
      <div className=" bg-[#0C0C0E]  text-[var(--text-color)] ">
        <div className="flex flex-col  mx-auto max-w-7xl py-16 px-6 md:px-16  items-start justify-start gap-14">
          <div className="flex flex-col gap-3   text-left  justify-start items-start">
            <h1 className="text-3xl md:text-[56px] text-[var(--text-color)] font-semibold  mb-4">
              Our Capabilities
            </h1>
            <p className=" text-base text-left md:text-xl max-w-xl">
              We are a leading AI solutions provider dedicated to empowering
              businesses with the transformative power of artificial
              intelligence. We offer a comprehensive suite of services,
              including AI Consulting, designed to meet your unique needs and
              goals.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2">
            <div className="flex flex-col md:flex-row items-center md:items-center justify-between">
              <div className="flex flex-col gap-5 md:gap-9">
                {capabilitiesContentData.map((item) => (
                  <div
                    key={item.id}
                    className={`cursor-pointer gap-2 transition-transform duration-500 text-2xl md:text-4xl ${
                      activeContent.id === item.id
                        ? "font-semibold flex flex-col"
                        : "text-[#7D7C88] font-semibold"
                    }`}
                    onClick={() => {
                      setActiveContent(item);
                      setIsclicked(true);
                    }}
                    style={{
                      transform:
                        activeContent.id === item.id ? "scale(1)" : "scale(1)",
                      transition: "transform 0.3s ease-in-out",
                    }}
                  >
                    <div
                      className={`flex items-center ${
                        activeContent.id === item.id ? "gap-4" : "gap-2"
                      } transition-all duration-500 ease-in-out`}
                    >
                      {/* Icon appears when the heading is active */}
                      {activeContent.id === item.id && (
                        <img
                          className="w-4 md:w-auto rotate-90 md:rotate-0"
                          src={
                            "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/sk.svg"
                          }
                          alt="active logo"
                        />
                      )}
                      {/* Heading */}
                      <span className="lg:-ms-2">{item.heading}</span>
                    </div>

                    {/* Content rendering when the heading is clicked */}
                    {activeContent.id === item.id && isClicked && (
                      <div
                        style={{ background: activeContent.color }}
                        className={`lg:hidden block transition-all duration-500 ease-out opacity-100 pt-3 md:pt-6 pb-9 px-3 md:px-6 flex-col md:flex-row items-center justify-center`}
                      >
                        <div className="rounded-lg w-full">
                          <div
                            className="w-auto h-[100px] md:h-[185px] bg-cover"
                            style={{
                              background: `url(${activeContent.icon}) center center / cover no-repeat`,
                            }}
                          ></div>
                          <p className="text-[var(--text-color)] font-normal text-base md:text-lg mt-3">
                            {activeContent.text}
                          </p>
                          <button
                            onClick={() =>
                              navigate(`${activeContent.link}`, {
                                state: activeContent.heading,
                              })
                            }
                            className="mt-4 flex hover:bg-[var(--background-color-white)] hover:text-red-500 items-center text-base md:text-lg font-semibold justify-center border border-white rounded-3xl bg-transparent text-[var(--text-color)] px-4 py-2"
                          >
                            Read More <FiChevronRight className="mt-1" />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* Desktop view content */}
            <div
              style={{ background: activeContent.color }}
              className={`lg:block hidden pt-3 md:pt-6 pb-9 px-3 md:px-6 flex-col md:flex-row items-center justify-center transition-all duration-500 ease-in-out`}
            >
              <div className="rounded-lg ">
                <div
                  className="w-auto h-[100px] md:h-[185px] bg-cover"
                  style={{
                    background: `url(${activeContent.icon}) center center / cover no-repeat`,
                  }}
                ></div>
                <p className="text-[var(--text-color)] text-[10px] sm:text-sm md:text-lg mt-3 leading-4 md:leading-7">
                  {activeContent.text}
                </p>
                <button
                  onClick={() =>
                    navigate(`${activeContent.link}`, {
                      state: activeContent.heading,
                    })
                  }
                  className="mt-4 flex hover:bg-[var(--background-color-white)] hover:text-red-500 items-center text-xs md:text-lg font-semibold justify-center border border-white rounded-3xl bg-transparent text-[var(--text-color)] px-4 py-2"
                >
                  Read More <FiChevronRight className="mt-1" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurCapabilities;
