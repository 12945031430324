import React from "react";

import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import ReviewSection from "../../pages/home/ReviewSection";

const ServiceDetailsCommon = ({ name, homeData }) => {
  console.log(homeData, "kashifraza");

  return (
    <div className="bg-[var(--background-gray-color)]  pt-14 md:pt-20">
      {homeData &&
        homeData.map((item) => (
          <div
            key={item.id}
            className="px-6 md:px-12 lg:px-16 max-w-7xl mx-auto"
          >
            <div className="flex animate-fade-in-down items-center justify-start md:text-xl text-base font-normal pt-6 md:py-7 text-[#7D7C88]">
              <Link to="/">Home</Link>
              <FiChevronRight className="md:mt-1" />
              <Link to="/services">Services</Link>
              <FiChevronRight className="md:mt-1" />

              <div className="textColor cursor-pointer">{item.title}</div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 items-start justify-start gap-10 lg:gap-20 text-[var(--text-color)]">
              <div className="text-start animate-fade-in-down">
                <h2 className="text-4xl md:text-4xl lg:text-[56px]  font-semibold textColor">
                  <span className="lg:leading-[60px]">{item.title}</span>
                </h2>

                <p className="text-base md:text-base lg:text-xl mt-5">
                  {item.description}
                </p>

                <div className="flex text-base md:text-xl my-4 md:my-10 flex-col items-start justify-start gap-3 font-semibold">
                  {item.list &&
                    item.list.map((subitem, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-center gap-2 text-base md:text-xl"
                      >
                        <img
                          className="w-3 md:w-6 md:h-4 h-3"
                          src={
                            "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/sk.svg"
                          }
                          alt="active logo"
                        />
                        {subitem}
                      </div>
                    ))}
                </div>

                <Link
                  to="#"
                  className="btn bg-cover text-center w-1/2 lg:w-1/3 hover:text-red-500 bg-opacity-50 bg-custom-gradient hover:bg-custom-gradient-hover text-sm lg:text-lg rounded-full text-[var(--text-color)] font-semibold px-5 py-3 duration-500"
                >
                  Request a Quote
                </Link>
              </div>

              <div className="lg:mt-5 h-full w-full object-cover animate-fade-in-right ">
                <img
                  src={item.icon}
                  alt=""
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            </div>
            <ReviewSection />
          </div>
        ))}
    </div>
  );
};

export default ServiceDetailsCommon;
