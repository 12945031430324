import React, { useState } from "react";

import ButtonComponent from "../../components/common/ButtonComponent";

const TransFormYourBusiness = ({ getTransFormBusinessData }) => {
  const [activeContent, setActiveContent] = useState(
    getTransFormBusinessData[0]
  );
  const [isClicked, setIsclicked] = useState(getTransFormBusinessData[0]);

  const [isHover, setIsHover] = useState(false);
  const [isHover1, setIsHover1] = useState(false);

  return (
    <div>
      <div className=" bg-[var(--background-gray-color)] text-[var(--text-color)] ">
        <div className="flex mx-auto max-w-7xl  flex-col  px-6 md:px-12 lg:px-16 py-4 pb-12 items-start justify-start ">
          <div className="flex flex-col text-left  justify-start items-start mb-5">
            <h1 className="text-2xl md:text-5xl text-[var(--text-color)] md:my-6 my-2 items-start font-semibold flex flex-col">
              <span className="textColor text-2xl md:text-5xl">
                Transform Your Business
              </span>
              <span>With AI Driven Solutions</span>
            </h1>
            <p className="text-base text-start md:text-xl max-w-xl mb-5">
              Our expert AI consulting services help you harness the potential
              of artificial intelligence to drive growth and efficiency.
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <div className="flex flex-col  items-start md:items-start justify-between w-full ">
              <div className="flex flex-col mb-10 md:mb-10 gap-5 md:gap-7">
                {getTransFormBusinessData.map((item) => (
                  <div
                    style={{
                      animationDirection: "normal",
                    }}
                    key={item.id}
                    className={`cursor-pointer  gap-2 transition-transform duration-300 text-xl md:text-[34px] ${
                      activeContent.id === item.id
                        ? "font-semibold flex flex-col"
                        : "text-[#7D7C88] font-semibold"
                    }`}
                    onClick={() => {
                      setActiveContent(item);
                      setIsclicked(true);
                    }}
                  >
                    <div
                      className={`flex items-center ${
                        activeContent.id === item.id ? "gap-4" : "gap-2"
                      } transition-all md:leading-10 mb-2 duration-500 ease-in-out`}
                    >
                      {activeContent.id === item.id && (
                        <img
                          className="w-3 h-6 md:w-6 rotate-90 md:rotate-0"
                          src={
                            "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/sk.svg"
                          }
                          alt="active logo"
                        />
                      )}
                      {item.heading}
                    </div>
                    {activeContent.id === item.id && isClicked && (
                      <div className="space-y-5 lg:hidden block w-full md:w-auto bg-[var(--background-color)] p-3 md:p-5">
                        <div
                          onMouseEnter={() => setIsHover(true)}
                          onMouseLeave={() => setIsHover(false)}
                          className={`flex items-center ${
                            isHover
                              ? "hover:bg-[#EE426A]"
                              : "bg-[var(--background-gray-color)]"
                          } justify-center gap-5 p-4 rounded-lg `}
                        >
                          <div
                            className={`flex-shrink-0 md:w-12 md:h-12 w-9 h-9  `}
                          >
                            {isHover ? (
                              <img
                                src={
                                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/challP.svg"
                                }
                                alt="challw"
                              />
                            ) : (
                              <img
                                src={
                                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/challW.svg"
                                }
                                alt="challw"
                              />
                            )}
                          </div>
                          <p className="text-[var(--text-color)] text-[10.85px] md:text-xl">
                            {activeContent.challenge}
                          </p>
                        </div>
                        <div
                          onMouseEnter={() => setIsHover1(true)}
                          onMouseLeave={() => setIsHover1(false)}
                          className={`flex items-center ${
                            isHover1
                              ? "hover:bg-[#0B8534]"
                              : "bg-[var(--background-gray-color)]"
                          }   justify-center gap-5 p-4 rounded-lg bg-[var(--background-gray-color)]`}
                        >
                          <div
                            className={`flex-shrink-0 md:w-12 md:h-12 w-9 h-9  `}
                          >
                            {isHover1 ? (
                              <img
                                src={
                                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/solutionW.svg"
                                }
                                alt="challw"
                              />
                            ) : (
                              <img
                                src={
                                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/solutionG.svg"
                                }
                                alt="challw"
                              />
                            )}
                          </div>
                          <p className="text-[var(--text-color)] text-[10.85px] md:text-xl">
                            {activeContent.solution}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <ButtonComponent />
            </div>

            <div className="space-y-5 lg:block hidden flex-grow w-full bg-[var(--background-color)] p-3 md:p-5 min-h-[200px]">
              <div
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                className={`flex items-center ${
                  isHover
                    ? "hover:bg-[#EE426A]"
                    : "bg-[var(--background-gray-color)]"
                } justify-center gap-5 p-4 rounded-lg `}
              >
                <div className={`flex-shrink-0 md:w-12 md:h-12 w-9 h-9  `}>
                  {isHover ? (
                    <img
                      src={
                        "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/challP.svg"
                      }
                      alt="challw"
                    />
                  ) : (
                    <img
                      src={
                        "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/challW.svg"
                      }
                      alt="challw"
                    />
                  )}
                </div>
                <p className="text-[var(--text-color)] text-[10.85px] md:text-xl">
                  {activeContent.challenge}
                </p>
              </div>
              <div
                onMouseEnter={() => setIsHover1(true)}
                onMouseLeave={() => setIsHover1(false)}
                className={`flex items-center ${
                  isHover1
                    ? "hover:bg-[#0B8534]"
                    : "bg-[var(--background-gray-color)]"
                }   justify-center gap-5 p-4 rounded-lg bg-[var(--background-gray-color)]`}
              >
                <div className={`flex-shrink-0 md:w-12 md:h-12 w-9 h-9  `}>
                  {isHover1 ? (
                    <img
                      src={
                        "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/solutionW.svg"
                      }
                      alt="challw"
                    />
                  ) : (
                    <img
                      src={
                        "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/solutionG.svg"
                      }
                      alt="challw"
                    />
                  )}
                </div>
                <p className="text-[var(--text-color)] text-[10.85px] md:text-xl">
                  {activeContent.solution}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransFormYourBusiness;
