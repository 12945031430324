import React, { useState } from "react";

import { portfolioChallengesData } from "../../components/data/PortfolioData";

// Sample data for the images and corresponding content

const PortfolioChallenges = () => {
  const [activeContent, setActiveContent] = useState(
    portfolioChallengesData[0]
  );
  const [isClicked, setIsclicked] = useState(portfolioChallengesData[0]);

  return (
    <div>
      <div className=" bg-[#0C0C0E]  text-[var(--text-color)] ">
        <div className="flex flex-col  mx-auto max-w-7xl px-6 md:px-16 py-4 pb-12 items-start justify-start ">
          <div className="flex flex-col   text-left  justify-start items-start mb-5">
            <h1 className="text-3xl md:text-[56px] text-[var(--text-color)] md:my-6 my-2 font-semibold">
              Challenges
            </h1>
            <p className=" text-base text-left md:text-xl max-w-xl">
              Karmikh faced several challenges with their outdated website, from
              poor product presentation to low brand visibility.
            </p>
          </div>

          <div className="flex flex-col md:flex-row items-center justify-center ">
            <div className="flex flex-col lg:flex-row items-start md:items-start justify-between w-full ">
              <div className="flex flex-col gap-5 md:gap-7">
                {portfolioChallengesData.map((item) => (
                  <div
                    style={{
                      animationDirection: "normal",
                    }}
                    key={item.id}
                    className={`cursor-pointer  gap-2 transition-transform duration-300 text-xl md:text-4xl ${
                      activeContent.id === item.id
                        ? "font-semibold flex flex-col"
                        : "text-[#7D7C88] font-semibold"
                    }`}
                    onClick={() => {
                      setActiveContent(item);
                      setIsclicked(true);
                    }}
                  >
                    <div className="flex items-center gap-2">
                      {activeContent.id === item.id && (
                        <img
                          className="w-4 md:w-5 lg:w-auto rotate-90 md:rotate-0"
                          src={
                            "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/sk.svg"
                          }
                          alt="active logo"
                        />
                      )}
                      {item.heading}
                    </div>
                    {activeContent.id === item.id && isClicked && (
                      <div
                        style={{ background: activeContent.color }}
                        className={`lg:hidden block w-full  pt-3 md:pt-6 lg:w-[900px] pb-9 px-3 md:px-6 flex-col md:flex-row items-center justify-center`}
                      >
                        <div className="rounded-lg w-full">
                          <div
                            className="w-auto h-[100px] md:h-[185px]"
                            style={{
                              background: `url(${activeContent.icon}) center center / cover no-repeat`,
                            }}
                          >
                            {/* <h2 className="text-[var(--text-color)] pt-2 md:pt-5 ps-3  text-xl md:text-4xl font-semibold mb-2">
                              {activeContent.heading}
                            </h2> */}
                          </div>
                          <p
                            className="text-[var(--text-color)] text-base md:text-base mt-3 leading-4 md:leading-7"
                            // dangerouslySetInnerHTML={{ __html: activeContent.text }}
                          >
                            Karmikh’s website did not properly showcase their
                            extensive <br className="md:block hidden" /> product
                            range in a way that was valuable or engaging to{" "}
                            <br className="md:block hidden" /> website visitors,
                            as it lacked proper categorization.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div
              style={{ background: activeContent.color }}
              className={`mt-9 lg:block hidden  pt-3 md:pt-6 lg:w-[900px] pb-9 px-3 md:px-6 flex-col md:flex-row items-center justify-center`}
            >
              <div className="rounded-lg  w-full">
                <div
                  className="w-auto h-[100px] md:h-[185px]"
                  style={{
                    background: `url(${activeContent.icon}) center center / cover no-repeat`,
                  }}
                ></div>
                <p
                  className="text-[var(--text-color)] text-base md:text-base mt-3 leading-4 md:leading-7 "
                  // dangerouslySetInnerHTML={{ __html: activeContent.text }}
                >
                  Karmikh’s website did not properly showcase their extensive{" "}
                  <br className="md:block hidden" /> product range in a way that
                  was valuable or engaging to <br className="md:block hidden" />{" "}
                  website visitors, as it lacked proper categorization.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioChallenges;
