import React from "react";
import { FaStar } from "react-icons/fa";

const ReviewSection = () => {
  return (
    <div className=" text-[var(--text-color)] flex flex-col items-center justify-center lg:items-start lg:justify-start py-6 mt-36 md:mt-7  mb-14">
      <img
        className="mb-5 lg:block hidden"
        src={
          "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/fr.svg"
        }
        alt=""
      />

      <div className="lg:flex-row flex flex-col gap-3 md:gap-0 items-start  justify-start md:justify-start space-y-0 md:items-start lg:space-x-4 md:space-x-3">
        {/* Clutch Section */}
        <div className="flex items-center me-10 md:me-0 space-x-2">
          <img
            src={
              "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/clutch.svg"
            }
            alt="Clutch"
            className="h-7 md:h-8"
          />
          <div className="-space-y-1">
            <p className="flex items-center space-x-1">
              <span className="md:text-sm text-sm font-semibold">4.9</span>
              <span className="flex items-center gap-1">
                <FaStar className="text-[#B827F1] w-3 h-3 " />
                <FaStar className="text-[#B827F1] w-3 h-3 " />
                <FaStar className="text-[#B827F1] w-3 h-3 " />
                <FaStar className="text-[#B827F1] w-3 h-3 " />
                <FaStar className="text-[#B827F1] w-3 h-3 " />
              </span>
            </p>
            <p className="text-[#B0B1B8] font-normal md:text-sm text-sm">
              Average Review Rating
            </p>
          </div>
        </div>

        {/* Divider */}
        <div className="border-r border-gray-600  md:h-12 "></div>

        {/* DesignRush Section */}
        <div className="flex items-center space-x-2">
          <img
            src={
              "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/de.svg"
            }
            alt="Clutch"
            className="h-9 md:h-9"
          />
          <div className="-space-y-1">
            <p className="flex items-center space-x-1 mb-1">
              <span className="md:text-sm text-sm font-semibold">
                DesignRush.com
              </span>
              <span className="flex items-center  gap-1">
                <FaStar className="text-[#B827F1] w-3 h-3 " />
                <FaStar className="text-[#B827F1] w-3 h-3  " />
                <FaStar className="text-[#B827F1] w-3 h-3 " />
                <FaStar className="text-[#B827F1] w-3 h-3 " />
                <FaStar className="text-[#B827F1] w-3 h-3 " />
              </span>
            </p>
            <p className="text-[#B0B1B8] font-normal md:text-sm text-sm">
              Top Mobile App Development Companies
            </p>
          </div>
        </div>

        {/* Divider */}
        <div className="border-r border-gray-600 md:h-12"></div>

        {/* Upwork Section */}
        <div className="flex items-center space-x-2">
          <img
            src={
              "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/upw.svg"
            }
            alt="Clutch"
            className="h-8 md:h-8"
          />
          <div>
            <p className="flex items-center font-normal space-x-2 text-[#AFAFAF] md:text-sm text-sm">
              <img
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/king.svg"
                }
                alt="Clutch"
                className=""
              />
              <span>100% Job Success</span>
            </p>
            <p className="text-[#AFAFAF] font-normal flex md:text-sm text-sm">
              {" "}
              <img
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/co.svg"
                }
                alt="Clutch"
              />{" "}
              <span className="ms-2.5">Top Rated Plus</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewSection;
