import React from "react";

const PrivacyHero = () => {
  return (
    <div className="max-w-7xl mx-auto ">
      <div className="bg-black text-white px-6 md:px-16 lg:px-48 py-10 pt-32">
        <h1 className="text-[56px] leading-[57px] font-bold text-center mb-4">
          Privacy Policy
        </h1>
        <p className="text-center text-base mb-8">
          Last Updated On 11 September 2024
        </p>
        <p className="text-center mb-12 font-semibold">
          Abstrabit Is Committed To Respecting Your Privacy And Choices While
          Using Our Website. This Policy Applies To Information Collected By Us
          On Our Website.
        </p>

        <section className="mb-10">
          <h2 className="text-3xl font-bold mb-4">I. Personal Information:</h2>
          <p className="mb-4 text-base md:text-lg">
            We do not collect personally identifiable information about you,
            unless you choose to fill your personal information on our website.
            Predominantly, you may browse our website without providing any
            personally identifiable information about yourself. However, we
            collect information that you supply to us when you personally choose
            to contact us for more information. You supply the information
            directly to us or indirectly by using credentials from third parties
            such as Facebook, Twitter, Google+ or LinkedIn. This data includes
            your name, date of birth, email address, contact number, contact
            address or other similar information. This information collected by
            us is used to respond to your inquiry to develop products, services,
            and programs or process an order.
          </p>
          <p className="mb-4 text-base md:text-lg">
            We do gather certain non-personally identifiable information such as
            standard web log information for functionality, device and software
            information, carrier, and connection information for all visitors of
            our websites. We may use this information to analyze trends and
            statistics so as to help us provide you with better services.
          </p>
          <p className="mb-4 text-base md:text-lg">
            We may share your information with other Abstrabit offices worldwide
            or with our partners. We may occasionally transfer or share your
            information with third parties who perform certain services on our
            behalf. However, such third parties have agreed to only use such
            information on behalf of Abstrabit. We reserve the right to disclose
            your information with legal and regulatory bodies or pursuant to a
            judicial order for compliance.
          </p>
          <p className="mb-4 text-base md:text-lg">
            Further, in the event we decide to reorganize, sell or transfer all
            or part of our business, we may share your information with third
            parties involved in such transactions, even in such circumstances we
            will obtain written assurances that the information provided by you
            will be appropriately protected.
          </p>
        </section>

        <section>
          <h2 className="text-3xl font-bold mb-4">II. Use of Cookies:</h2>
          <p className="mb-4 text-base md:text-lg">
            Our website uses cookies. Cookies are small text files of
            information that save and retrieve information about your visit to
            our website. Cookies, by themselves, do not give us any personally
            identifiable information about you unless you choose to provide this
            information to us. A cookie is like an identification card. It is
            unique to your computer and can only be read by the server that gave
            it to you.
          </p>
          <p className="mb-4 text-base md:text-lg">
            We use cookies to understand site usage and improve the content and
            offerings on our website. We use cookies to personalize your
            experience on our webpage.
          </p>
        </section>

        <section>
          <h2 className="text-3xl font-bold mb-4">III. Security:</h2>
          <p className="mb-4 text-base md:text-lg">
            We use reasonable administrative, technical and physical security
            measures to protect the information from unauthorized access, use,
            loss or destruction. Information provided by you is stored on a
            secured web server managed by Abstrabit or an agent of Abstrabit
            performing services on our behalf.
          </p>
          <p className="mb-4 text-base md:text-lg">
            Despite, having created a secured and reliable web-site for you, the
            confidentiality of any information, communication or material
            transmitted over the internet, especially unencrypted data can be
            read by third parties. Therefore, we request you to exercise utmost
            discretion while providing sensitive confidential information. You
            also acknowledge that the use of internet and this web-site is
            solely at your own risk.
          </p>
        </section>

        <section>
          <h2 className="text-3xl font-bold mb-4">
            IV. Links to other Web-Sites:
          </h2>
          <p className="mb-4 text-base md:text-lg">
            Abstrabit may provide links to other third-party web-sites and
            services. However, Abstrabit is not responsible for privacy
            statements, practices or the contents of such third party web-sites.
          </p>
        </section>

        <section>
          <h2 className="text-3xl font-bold mb-4">
            V. Access to your Personal Information:
          </h2>
          <p className="mb-4 text-base md:text-lg">
            You may access, update or correct your Personal Information that we
            hold, including your profile and preferences. You may do so by
            contacting us at info@abstrabit.com.
          </p>
          <p className="mb-4 text-base md:text-lg">
            You may also request us to delete your personal information by
            sending a request at info@abstrabit.com. We will implement
            reasonable efforts to delete your personal information, though there
            may be some delay in deleting such data and we don’t guarantee
            complete or comprehensive removal of the content or information you
            may have posted or otherwise made otherwise available through our
            web-site.
          </p>
        </section>

        <section>
          <h2 className="text-3xl font-bold mb-4">VI. Authenticity:</h2>
          <p className="mb-4 text-base md:text-lg">
            Abstrabit shall not be responsible for the authenticity of the
            information you provide and therefore it is your duty to ensure that
            the information provided by you is authentic.
          </p>
        </section>

        <section>
          <h2 className="text-3xl font-bold mb-4">VII. How to Contact Us:</h2>
          <p className="mb-4 text-base md:text-lg">
            If you have any question with regards to our Privacy Policy, you may
            Contact Us. Contact Details: Email info@abstrabit.com
          </p>
        </section>

        <section>
          <h2 className="text-3xl font-bold mb-4">
            VIII. Grievance Redressal:
          </h2>
          <p className="mb-4 text-base md:text-lg">
            Any grievances or complains, in relation to processing of
            information, should be addressed to Abstrabit in writing to the
            following contact email: info@abstrabit.com. Grievances shall be
            redressed as expeditiously as possible.
          </p>
        </section>

        <section>
          <h2 className="text-3xl font-bold mb-4">
            IX. Changes to this Policy:
          </h2>
          <p className="mb-10 text-base md:text-lg">
            We reserve the right to update our Privacy Policy as and when need
            arises so as to reflect our current privacy practices. When we make
            certain changes, we will revise the “last updated” date at the top
            of this page. But our commitment to protect the privacy of website
            users will continue to remain.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyHero;
