import React, { useState } from "react";

import MetaData from "../../components/layout/MetaData";
import { Link } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";
import Loader from "../../components/layout/loading/Loader";
import "react-phone-number-input/style.css";
import { motion } from "framer-motion";

import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import axios from "axios";

const ContactUs = ({ heading }) => {
  const [formData, setFormData] = useState({
    name: "",
    companyName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  function isValiEmail(val) {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      return "Enter a valid email address";
    }
  }

  const handleChange = (phone) => {
    setFormData({ ...formData, phone });
    if (phone) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: "",
      }));
    }
  };

  const validate = () => {
    let errors = {};

    if (!formData.name) errors.name = "Name is required";
    if (!formData.companyName) errors.companyName = "Company Name is required";

    // Email validation
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (isValiEmail(formData.email)) {
      errors.email = "Enter a valid email address";
    }

    if (!formData.phone) {
      errors.phone = "Phone number is required";
    } else if (!isValidPhoneNumber(formData.phone)) {
      errors.phone = "Enter a valid phone number";
    }
    if (!formData.message) errors.message = "Message is required";

    return errors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (value.trim() !== "") {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      try {
        setLoading(true);

        const dataForm = {
          ...formData,
          date: new Date().toLocaleDateString(),
        };

        const config = { headers: { "Content-Type": "application/json" } };

        const data = await axios.post(
          `https://abstratbit-default-rtdb.firebaseio.com/users.json`,
          dataForm,
          config
        );

        console.log(data, "kashif");

        if (data.status === 200) {
          setSuccessMessage("Your message has been successfully sent!");
          setFormData({
            name: "",
            companyName: "",
            email: "",
            phone: "",
            message: "",
          });
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setErrors(validationErrors);
      setSuccessMessage("");
    }
  };

  return (
    <div className="bg-[#161616] min-h-screen">
      <div className=" text-[var(--text-color)]">
        <div className="max-w-7xl mx-auto bg-[var(--background-color)]">
          <MetaData title="Contact Us - Abstrabit Technologies" />
          <div className=" md:bg-[var(--background-gray-color)] text-center md:pb-8 pb-10 md:px-12 px-6 lg:px-16">
            <div className="grid grid-cols-1 md:grid-cols-2 py-5  md:py-10 text-[var(--text-color)]">
              <div className="text-start  gap-5 md:gap-7 ">
                <h2 className="text-3xl flex flex-col md:text-4xl lg:text-5xl font-bold">
                  <span className="textColor lg:leading-[54px] max-w-xl">
                    {heading}
                  </span>{" "}
                </h2>

                <div className="grid grid-cols-1 gap-10 mt-5">
                  <div className="bg-[#1F2530]  gap-3 p-4 lg:p-5 flex ">
                    <div className="flex flex-1 text-base md:text-xl my-3 md:my-0  flex-col items-start justify-start gap-2 font-normal">
                      <h2 className="text-2xl mb-3 md:text-[28px] text-start leading-6 md:leading-8  font-semibold">
                        Have a vision or a challenge?
                      </h2>

                      <div className="flex   items-start justify-start gap-1 text-base lg:text-xl">
                        <img
                          className="w-5 md:w-6 mt-1.5 md:h-4 h-4"
                          src={
                            "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/sk.svg"
                          }
                          alt="active logo"
                        />
                        Fill the form and we’ll get back to you within 12 hours.
                      </div>

                      <div className="text-center mx-auto">OR</div>

                      <div className="flex flex-col md:flex-row items-start justify-start">
                        <div className="flex text-base lg:text-xl">
                          <img
                            className="w-5 mt-1 md:w-6 md:h-4 h-4"
                            src={
                              "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/sk.svg"
                            }
                            alt="active logo"
                          />
                          Connect Directly with Our Tech Expert{" "}
                        </div>
                        <Link
                          onClick={() => {
                            window.open(
                              "https://meetings-eu1.hubspot.com/sawan?uuid=4258a6db-4584-4b31-9931-358445599c87",
                              "_blank"
                            );
                          }}
                          className="whitespace-nowrap underline text-base lg:text-lg ms-5 text-start items-start justify-start flex underline-offset-4  lg:ms-2 text-blue-600"
                        >
                          Schedule a call <MdArrowOutward className="mt-1" />
                        </Link>
                      </div>
                    </div>
                    <img
                      src={
                        "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/arrowB.svg"
                      }
                      alt=""
                    />
                  </div>

                  <div className=" py-7 p-5 bg-[#1F2530] text-base md:text-xl flex-col items-start justify-start gap-3 font-normal">
                    <h2 className="text-2xl md:text-[28px] text-start leading-6 md:leading-8 font-semibold">
                      We are committed to excellence{" "}
                      <br className="lg:block hidden" /> in everything we do
                    </h2>

                    <div className="flex w-full lg:w-[95%] items-center gap-2 lg:gap-7 mt-7 p-1.5 md:p-5 justify-center bg-[var(--background-color-white)] rounded-xl">
                      <div className="flex textColorPurple flex-col items-center justify-center ">
                        <span className="text-lg md:text-[28px] font-bold">
                          50+
                        </span>
                        <p className="md:text-sm text-center font-semibold text-sm">
                          Global Clients
                        </p>
                      </div>
                      <div className="border-r border-purple-600 border-[1.3px] h-14 "></div>
                      <div className="flex textColorPurple flex-col items-center justify-center ">
                        <span className="text-lg md:text-[28px] font-bold">
                          100+
                        </span>
                        <p className="md:text-sm text-center font-semibold text-sm">
                          Projects Delivered
                        </p>
                      </div>
                      <div className="border-r border-purple-600 border-[1.3px] h-14 "></div>
                      <div className="flex textColorPurple flex-col items-center justify-center ">
                        <span className="text-lg whitespace-nowrap md:text-[28px] font-bold">
                          100M+
                        </span>
                        <p className="md:text-sm text-center font-semibold text-sm">
                          Raised for Clients
                        </p>
                      </div>
                      <div className="border-r border-purple-600 border-[1.3px] h-14 "></div>
                      <div className="flex textColorPurple flex-col items-center justify-center ">
                        <span className="text-lg md:text-[28px] font-bold">
                          95%
                        </span>
                        <p className="md:text-sm text-center font-semibold text-sm">
                          Client Retention
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Right Section - Form */}
              <div className=" lg:ms-24 md:ms-10  mt-10 md:mt-4 ">
                <h2 className="text-3xl lg:text-4xl text-center font-semibold md:text-start mb-4">
                  Let’s Build Something{" "}
                  <span className="textColorPurple font-bold">Amazing</span>{" "}
                  Together.
                </h2>
                <form
                  onSubmit={handleSubmit}
                  className="md:space-y-4 space-y-2.5 w-full text-start"
                >
                  <div className="space-y-1">
                    <label
                      htmlFor="name"
                      className="text-base md:text-lg font-normal"
                    >
                      Name *
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="Enter your name here"
                      className={`w-full p-3 outline-none  rounded-md md:text-base text-base bg-[#2D2D2D] text-[var(--text-color)] placeholder-[#7D7C88] ${
                        errors.name ? "border-red-500 border" : ""
                      }`}
                    />
                    {errors.name && (
                      <p className="text-red-500 text-sm">{errors.name}</p>
                    )}
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="companyName"
                      className="text-base md:text-lg font-normal"
                    >
                      Company Name *
                    </label>
                    <input
                      type="text"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleInputChange}
                      placeholder="Enter your company name here"
                      className={`w-full p-3 outline-none rounded-md md:text-base text-base bg-[#2D2D2D] text-[var(--text-color)] placeholder-[#7D7C88] ${
                        errors.companyName ? "border-red-500 border" : ""
                      }`}
                    />
                    {errors.companyName && (
                      <p className="text-red-500 text-sm">
                        {errors.companyName}
                      </p>
                    )}
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="email"
                      className="text-base md:text-lg font-normal"
                    >
                      Email *
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Enter your email here"
                      className={`w-full p-3 outline-none rounded-md md:text-base text-base bg-[#2D2D2D] text-[var(--text-color)] placeholder-[#7D7C88] ${
                        errors.email ? "border-red-500 border" : ""
                      }`}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-sm">{errors.email}</p>
                    )}
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="phone"
                      className="text-base md:text-lg font-normal"
                    >
                      Phone *
                    </label>
                    <PhoneInput
                      value={formData.phone}
                      onChange={handleChange}
                      defaultCountry="IN"
                      placeholder="Phone (eg. +91 1234567891)"
                      className={`w-full p-1.5 outline-none rounded-md md:text-base text-base bg-[#2D2D2D] text-[var(--text-color)] placeholder-[#7D7C88] ${
                        errors.phone ? "border-red-500 border" : ""
                      }`}
                    />
                    {errors.phone && (
                      <p className="text-red-500 text-sm">{errors.phone}</p>
                    )}
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="message"
                      className="text-base md:text-lg font-normal"
                    >
                      Message *
                    </label>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                      placeholder="Your Message"
                      className={`w-full p-3 outline-none rounded-md md:text-base text-base bg-[#2D2D2D] text-[var(--text-color)] placeholder-[#7D7C88] h-42 ${
                        errors.message ? "border-red-500 border" : ""
                      }`}
                    />
                    {errors.message && (
                      <p className="text-red-500 text-sm">{errors.message}</p>
                    )}
                  </div>

                  <motion.button
                    whileTap={{ scale: 0.85 }}
                    type="submit"
                    className="btn flex items-center justify-center text-center bg-cover hover:text-red-500 bg-opacity-50 bg-custom-gradient hover:bg-custom-gradient-hover text-base lg:text-lg rounded-full text-[var(--text-color)] font-semibold px-5 py-2.5 duration-500 md:static"
                  >
                    Get In Touch {loading ? <Loader /> : ""}
                  </motion.button>

                  {successMessage && (
                    <p className="mt-5 bg-gray-900 p-3 lg:p-5 text-green-500 text-base lg:text-xl">
                      {successMessage}
                    </p>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
