import React, { useState } from "react";

// Sample data for the images and corresponding content
const contentData = [
  {
    id: 1,
    heading: "Our Mission",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/about-assets/vision.svg",

    text: "We are committed to pioneering AI innovation by exploring the frontiers of technology to develop cutting-edge solutions that address real-world business challenges. Our focus on building inclusive and ethical AI ensures transparency and explainability, aligning our work with ethical principles. Above all, we prioritize delivering measurable results, driving business growth, optimizing processes, and maximizing your return on investment.",
  },
  {
    id: 2,
    heading: "Our Vision",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/cp2.svg",

    text: "To be the catalyst that empowers businesses to achieve transformative results through the responsible and effective application of AI. We strive to lead the industry in innovation, setting the standard for ethical AI practices while ensuring our solutions drive real, measurable growth for our clients. By fostering a culture of continuous improvement and collaboration, we aim to unlock the full potential of AI, helping businesses navigate the complexities of the digital age with confidence and success.",
  },
  {
    id: 3,
    heading: "Our Value",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/cp-3.svg",

    text: "Our core values shape everything we do, from delivering exceptional client results to fostering a supportive work environment. We prioritize client success, value teamwork and open communication, and ensure that your contributions make a real impact. Honesty and transparency are key, and we continually strive for excellence, providing growth opportunities and celebrating achievements.",
  },
];

const AboutDynamic = () => {
  const [activeContent, setActiveContent] = useState(contentData[0]);
  const [isClicked, setIsclicked] = useState(contentData[0]);

  return (
    <div>
      <div className="mx-auto max-w-7xl text-[var(--text-color)] ">
        <div className="flex flex-col  px-6 md:px-16 items-start justify-start ">
          <div className="grid grid-cols-1 lg:grid-cols-2 items-start justify-start">
            <div className="flex flex-col md:flex-row items-start md:items-start justify-between ">
              <div className="flex flex-col gap-5 md:gap-7">
                {contentData.map((item) => (
                  <div
                    key={item.id}
                    className={`cursor-pointer gap-2 transition-transform duration-300 text-2xl md:text-4xl ${
                      activeContent.id === item.id
                        ? " flex flex-col  font-semibold"
                        : "text-[#7D7C88] font-semibold"
                    }`}
                    onClick={() => {
                      setActiveContent(item);
                      setIsclicked(true);
                    }}
                    style={{
                      transform:
                        activeContent.id === item.id ? "scale(1)" : "scale(1)",
                      transition: "transform 0.3s ease-in-out",
                    }}
                  >
                    <div
                      className={`flex items-center ${
                        activeContent.id === item.id ? "gap-4" : "gap-2"
                      } transition-all duration-500 ease-in-out`}
                    >
                      {activeContent.id === item.id && (
                        <img
                          className="w-4 md:w-auto rotate-90 md:rotate-0"
                          src={
                            "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/sk.svg"
                          }
                          alt="active logo"
                        />
                      )}
                      <span className="lg:-ms-2">{item.heading}</span>
                    </div>
                    {activeContent.id === item.id && isClicked && (
                      <div
                        style={{ background: activeContent.color }}
                        className={`  lg:hidden block   lg:w-[900px]  flex-col md:flex-row items-center justify-center`}
                      >
                        <div className="rounded-lg  w-full">
                          <div
                            className="w-auto h-[100px] md:h-[185px]"
                            style={{
                              background: `url(${activeContent.icon}) center center / cover no-repeat`,
                            }}
                          ></div>
                          <p
                            className="text-[var(--text-color)] text-base md:text-base mt-3  md:leading-7 "
                            dangerouslySetInnerHTML={{
                              __html: activeContent.text,
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div
              style={{ background: activeContent.color }}
              className={` lg:block hidden  flex-col md:flex-row items-center justify-center`}
            >
              <div className="rounded-lg  w-full">
                <div
                  className="w-auto h-[100px] md:h-[185px]"
                  style={{
                    background: `url(${activeContent.icon}) center center / cover no-repeat`,
                  }}
                ></div>
                <p
                  className="text-[var(--text-color)] text-base md:text-base mt-3  md:leading-7 "
                  dangerouslySetInnerHTML={{
                    __html: activeContent.text,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutDynamic;
