export const perksBenefitsCard = [
  {
    id: "1",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/career-assets/pbi1.svg",
    title: "Performance Measurement",
    description:
      "Tracking AI model performance and key performance indicators (KPIs).",
  },
  {
    id: "2",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/career-assets/pbi2.svg",
    title: "Continuous Improvement",
    description: "Refining AI models and strategies based on data insights.",
  },
  {
    id: "3",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/career-assets/pbi3.svg",
    title: "Scaling AI",
    description: "Expanding AI initiatives across the organization.",
  },
  {
    id: "4",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/career-assets/pbi4.svg",
    title: "Performance Measurement",
    description:
      "Tracking AI model performance and key performance indicators (KPIs).",
  },
  {
    id: "5",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/career-assets/pbi5.svg",
    title: "Continuous Improvement",
    description: "Refining AI models and strategies based on data insights.",
  },
  {
    id: "6",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/career-assets/pbi6.svg",
    title: "Scaling AI",
    description: "Expanding AI initiatives across the organization.",
  },
];

export const recruitmentProcess = [
  {
    title: ["CV", "Submission"],
    description:
      "Read the job description thoroughly and follow all directions to send us your CV. It’s the first step in forming an exceptional connection.",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/career-assets/cv.svg",
    hoverIcon:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/career-assets/hcv.svg",
  },
  {
    title: ["Skill", "Assessment"],
    description:
      "You will be going through a series of tests that allows us to evaluate and screen prospective job candidates on the skills needed to succeed in their role.",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/career-assets/skill.svg",
    hoverIcon:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/career-assets/hskill.svg",
  },
  {
    title: ["Final", "Interview"],
    description:
      "We invite qualified candidates to a final interview to identify the best fit for our organization before we make our job offer.",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/career-assets/finalI.svg",
    hoverIcon:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/career-assets/hfinalI.svg",
  },
];

export const jobData = [
  {
    id: 1,
    title: "Front End Developer",
    location: "Bangalore, Full Time",
    ans: "AI Strategy & Consulting helps businesses identify how artificial intelligence can benefit their specific goals. Our experts will work with you to develop a tailored plan to unlock new opportunities, optimize operations, and achieve a competitive advantage through AI implementation.",
  },
  {
    id: 2,
    location: "Bangalore, Full Time",
    title: "Full Stack Developer",
    ans: "Generative AI can unlock entirely new possibilities. We can use these cutting-edge AI technologies to enhance your products, boost operational productivity, and give you a competitive edge in the market.",
  },
  {
    id: 3,
    location: "Bangalore, Full Time",
    title: "UI/UX Design Intern",
    ans: "Tailored AI chatbots provide 24/7 customer support and engagement. They can answer questions intelligently, improve user experience by offering instant assistance, and streamline communication, ultimately leading to higher customer satisfaction and business efficiency.",
  },
  {
    id: 4,
    location: "Bangalore, Full Time",
    title: "Social Media Marketing Intern",
    ans: "AI-powered knowledge assistants give your team access to instant and accurate information, allowing them to automate routine tasks and make smarter decisions. This frees up valuable time for your team to focus on strategic initiatives.",
  },
];
