import React from "react";
import PortfolioHero from "./PortfolioHero";
import OurWorksSection from "../home/OurWorksSection";
import Achievement from "../about-us/Achievement";
import ClientsSection from "../home/ClientsSection";
import ContactUs from "../contact-us/ContactUs";
import MetaData from "../../components/layout/MetaData";


const Portfolio = () => {


  return (
    <div className="flex flex-col gap-20 md:gap-44   bg-[var(--background-color)]">
      <PortfolioHero />
      <OurWorksSection />
      <Achievement />
      <ClientsSection />
      <ContactUs heading={"Partner with Us to Bring Your Vision to Life"} />
      <MetaData title="Portfolio - Abstrabit Technologies" />
    </div>
  );
};

export default Portfolio;
