import React from "react";
import Marquee from "react-fast-marquee";

const ClientsSection = () => {
  const clients = [
    {
      logo: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/image%20(8).png",
    },
    {
      logo: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/image%20(9).png",
    },
    {
      logo: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/image%20(10).png",
    },
    {
      logo: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/image%20(11).png",
    },
    {
      logo: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/image%20(12).png",
    },
    {
      logo: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/image%20(13).png",
    },
    {
      logo: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/image%20(14).png",
    },
    {
      logo: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/image%20(15).png",
    },
    {
      logo: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/image%20(16).png",
    },
    {
      logo: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/image%20(17).png",
    },
    {
      logo: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/image%20(18).png",
    },
    {
      logo: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/image%20(19).png",
    },
    {
      logo: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/image%20(20).png",
    },
    {
      logo: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/image%20(21).png",
    },
    {
      logo: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/image%20(22).png",
    },
    {
      logo: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/image%20(23).png",
    },
    {
      logo: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/image%20(24).png",
    },
    {
      logo: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/image%20(25).png",
    },
    {
      logo: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/image%20(26).png",
    },
  ];

  const reverseClients = [...clients].reverse();

  return (
    <div className="bg-[var(--background-color)]">
      <div className="flex flex-col 2xl:px-16 items-center justify-center xl:mx-auto xl:max-w-7xl">
        <h2 className="text-center md:text-[56px] text-3xl font-semibold text-[var(--text-color)] md:mb-14 mb-5">
          Our Clients
        </h2>
        <Marquee gradient={false} speed={40} direction="left">
          {clients.map((client, index) => (
            <div
              key={index}
              className="lg:mx-4 mx-2.5 my-3 lg:my-5 h-20 lg:h-24 flex items-center justify-center bg-[var(--background-gray-color)] custom-shadow  rounded w-36 lg:w-52"
            >
              <img
                src={client.logo}
                alt="Client Logo"
                className="lg:h-20 h-16  lg:w-auto p-2"
              />
            </div>
          ))}
        </Marquee>
        <Marquee gradient={false} speed={40} direction="right">
          {reverseClients.reverse().map((client, index) => (
            <div
              key={index}
              className="lg:mx-4 mx-2.5 my-3 lg:my-5 h-20 lg:h-24 flex items-center justify-center bg-[var(--background-gray-color)] custom-shadow  rounded w-36 lg:w-52"
            >
              <img
                src={client.logo}
                alt="Client Logo"
                className="lg:h-20 h-16  lg:w-auto p-2"
              />
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default ClientsSection;
