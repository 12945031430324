import React, { useState } from "react";

const statsData = [
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/about-assets/global.svg",
    value: "50+",
    description: "Global Clients",
    bgGradient: "bg-custom-gradient-purple",
  },
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/about-assets/hand.svg",
    value: "95%",
    description: "Clients Retention",
    bgGradient: "bg-custom-gradient ",
  },
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/about-assets/prize.svg",
    value: "100+",
    description: "Projects Delivered",
    bgGradient: "bg-custom-gradient ",
  },
  {
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/about-assets/member.svg",
    value: "100 Million+ ",
    description: "Raised for Clients",
    bgGradient: "bg-custom-gradient-purple",
  },
];

const Achievement = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div className="bg-[var(--background-color)] text-[var(--text-color)]">
      <div className=" px-6 text-center md:px-16 max-w-3xl mx-auto ">
        <h2 className="text-3xl md:text-[56px] font-semibold mb-5 md:mb-14">
          Our Achievements
        </h2>

        <div className="md:py-5 py-1 grid grid-cols-1 md:grid-cols-2 gap-6">
          {statsData.map((stat, index) => (
            <div
              onMouseOver={() => setHoveredIndex(index)}
              onMouseOut={() => setHoveredIndex(null)}
              key={index}
              className={`hover:text-purple-600 ${stat.bgGradient}  border-white  hover:bg-custom-gradient-hover text-[var(--text-color)]  font-semibold duration-500 md:static  flex flex-col items-center justify-center p-8`}
            >
              <div
                className={`md:w-24 mb-5 ${
                  hoveredIndex === index ? "border-purple-600" : ""
                } md:h-24 h-auto w-auto border-4 rounded-full flex items-center justify-center`}
              >
                <img
                  src={stat.icon}
                  alt={stat.description}
                  className="w-16 h-16 m-2"
                />
              </div>
              <div className="lg:text-4xl text-3xl font-semibold">
                {stat.value}
              </div>
              <div className="lg:text-lg text-base font-semibold">
                {stat.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Achievement;
