import React, { useState } from "react";
import { recruitmentProcess } from "../../components/data/CareerData";

const RecruitmentProcess = () => {
  const [isHover, setIsHover] = useState(null);

  return (
    <div className="bg-[var(--background-color)] text-[var(--text-color)]">
      <div className="max-w-7xl mx-auto ">
        <div className="px-6 md:px-12 lg:px-16  text-center">
          <h2 className="text-3xl items-center lg:gap-3 justify-center lg:flex-row flex flex-col  md:text-[56px] font-semibold mb-5 md:mb-14">
            <span className="textColor font-bold">Our Recruitment Process</span>
            {/* <span className="textColor font-bold">Process</span> */}
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-10 mt-10">
            {recruitmentProcess.map((card, index) => (
              <div
                key={index}
                onMouseEnter={() => setIsHover(index)}
                onMouseLeave={() => setIsHover(null)}
                className="bg-[var(--background-gray-color)] hover:bg-white lg:ps-9 lg:pe-10 p-5 flex flex-col items-start justify-start"
              >
                <div className="bg-[var(--background-color-white)] mb-7 p-2 md:p-0 z-10 md:w-12 md:h-12 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer text-gray-600">
                  <img
                    src={isHover === index ? card.hoverIcon : card.icon}
                    alt=""
                    className={`${
                      isHover === index
                        ? "md:w-20 md:h-20 w-11 h-11"
                        : "md:w-20 md:h-20 w-11 h-11"
                    }`}
                  />
                </div>
                <h3
                  className={`${
                    isHover === index ? "text-purple-600" : ""
                  } lg:text-4xl text-3xl flex flex-col text-left font-semibold mb-3 text-[var(--text-color)]`}
                >
                  {card.title.map((line, i) => (
                    <span key={i}>{line}</span>
                  ))}
                </h3>
                <p className="md:text-xl text-base text-left font-normal text-[#7D7C88]">
                  {card.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruitmentProcess;
