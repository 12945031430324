import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const ButtonComponent = ({ page, setOpen = () => {} }) => {
  const navigate = useNavigate();

  return (
    <motion.button
      whileTap={{ scale: 0.85 }}
      onClick={() => {
        setOpen(false);
        navigate("/contact-us");
      }}
      className={`btn text-center  hover:text-red-500 cursor-pointer z-20 bg-custom-gradient hover:bg-custom-gradient-hover text-base lg:text-lg rounded-full text-[var(--text-color)] ${
        page === "header" ? "md:ml-8" : ""
      }  font-semibold px-4 md:px-5 py-2 md:py-2 duration-500 md:static`}
    >
      Get In Touch
    </motion.button>
  );
};

export default ButtonComponent;
