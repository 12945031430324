// src/components/EngagementTools.js

import React from "react";

const OurPartners = () => {
  const toolsData = [
    {
      imageUrl:
        "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/ms.svg",
    },
    {
      imageUrl:
        "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/aws.svg",
    },
    {
      imageUrl:
        "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/gg.svg",
    },
  ];

  return (
    <div className="bg-[var( --background-color)] text-[var(--text-color)]">
      <div className="mx-auto max-w-7xl px-6 md:px-16">
        {/* <div className="text-[var(--text-color)] flex flex-col items-center justify-center py-8"> */}
        <h1 className="text-3xl md:text-[56px] font-semibold text-center md:mb-14 mb-5">
          Our Partners
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 md:p-6 gap-5">
          {toolsData.map((tool, index) => (
            <div
              key={index}
              className={`flex items-center justify-center bg-[var(--background-gray-color)] custom-shadow  lg:p-9  ${
                index === 1 ? "p-3" : index === 0 ? "p-5" : "p-4"
              } md:p-6 rounded-lg`}
            >
              <img
                className="max-w-full max-h-full object-contain"
                src={tool.imageUrl}
                alt={`Partner ${index}`}
              />
            </div>
          ))}
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default OurPartners;
