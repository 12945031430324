import React from "react";
import MetaData from "../../components/layout/MetaData";

import Portfolio from "../portfolio/Portfolio";

const CaseStudies = () => {
  return (
    <div className="flex flex-col gap-20 md:gap-44   bg-[var(--background-color)]">
      <Portfolio />
      <MetaData title="Case Studies - Abstrabit Technologies" />
    </div>
  );
};

export default CaseStudies;
