import React from "react";
import { FiChevronRight } from "react-icons/fi";

const blogs = [
  {
    id: 1,
    title:
      "How to Develop a Mobile App From Scratch in 2024: The Ultimate Guide",
    date: "April 20, 2024",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/bg1.svg", // Replace with actual image path or URL
    description:
      "A small business is only as good as its tools and it is totally true.",
  },
  {
    id: 2,
    title: "How to Choose a Custom Software Development Company?",
    date: "January 10, 2024",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/blog-assets/bg4.svg",
    description:
      "A small business is only as good as its tools and it is totally true.",
  },
  {
    id: 3,
    title:
      "How to Develop a Mobile App From Scratch in 2024: The Ultimate Guide",
    date: "June 29, 2024",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/bg3.svg",
    description:
      "A small business is only as good as its tools and it is totally true.",
  },
];

const PopularArtical = () => {
  return (
    <section className="mx-auto max-w-7xl md:px-12 px-6 lg:px-16 md:block hidden">
      <div className="flex flex-col text-[var(--text-color)]">
        <h2 className="md:text-start text-2xl md:text-[56px]  text-center font-semibold md:mb-14 mb-5">
          Popular Articles
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="w-full h-full object-cover">
            <div className=" flex relative overflow-hidden text-[var(--text-color)]">
              <img
                className="thumb-img w-full h-full object-cover"
                src="https://abswebsiteassets.blob.core.windows.net/websiteimages/blog-assets/popularA.svg"
                alt="Catz!"
              />
              <div className="relative w-full thumb-anim px-7">
                <h3 className=" text-xl  md:text-2xl font-semibold z-50 mt-5">
                  Karmikh
                </h3>

                <p className="text-lg font-medium mt-2 leading-6">
                  Soulpay Pvt.Ltd, a leading player in India's fintech
                  <br className="lg:block hidden" />
                  industry, operates as a B2B platform offering a diverse
                  <br className="lg:block hidden" />
                  range of financial services.
                </p>

                <button
                  style={{}}
                  className="flex text-xs hover:bg-[var(--background-color-white)] hover:text-red-500  mb-60  items-center md:text-lg font-semibold justify-center border border-white rounded-3xl my-4 text-[#ffffff] px-4 py-2 "
                >
                  Read More <FiChevronRight className="mt-1" />
                </button>
              </div>
            </div>
          </div>
          <div className="grid grid-rows-3 ms-7 space-y-3">
            {blogs &&
              blogs.map((item, index) => (
                <div
                  key={item.id}
                  className="flex items-start justify-start gap-5 w-full
                  h-full"
                >
                  <img
                    src={item.image}
                    alt=""
                    className="h-32 w-36 object-covecr"
                  />
                  <div className="flex flex-col items-start justify-start gap-0 space-y-1">
                    <div className="flex md:flex-col lg:flex-row text-[#7D7C88] text-base md:text-base font-normal items-start gap-2 justify-start">
                      <h4 className="text-left text-base">Sawan Rauniyar •</h4>
                      <h5 className="text-start text-base">{item.date}</h5>
                    </div>

                    <p className="text-start md:text-xl lg:text-2xl font-normal text-[var(--text-color)]">
                      {item.description}
                    </p>

                    <button className="flex text-xs  hover:bg-[var(--background-color-white)] hover:text-red-500  items-center md:text-xs font-semibold justify-center border border-white rounded-3xl text-[#ffffff] px-4 py-2.5 ">
                      Read More <FiChevronRight className="mt-0.5" />
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PopularArtical;
