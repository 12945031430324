import React from "react";

const RemoteTeamSection = () => {
  return (
    <div className="bg-[var(--background-color)] flex flex-col items-center justify-center text-[var(--text-color)] px-6 md:px-16 text-center max-w-7xl mx-auto">
      <h2 className="text-3xl md:text-[56px] font-semibold mb-5 md:mb-10">
        Who are we?
      </h2>

      <button className="btn relative mb-4 inline-flex font-semibold py-3 sm:py-3 md:py-3 lg:py-5 px-3 md:px-10 items-center justify-center overflow-hidden transition-all gradient-purple rounded hover:bg-[var(--background-color-white)] group">
        <span className="w-0 h-0 rounded bg-[var(--background-color-white)] absolute top-0 left-0 ease-out duration-500 transition-all group-hover:w-full group-hover:h-full -z-1"></span>{" "}
        <span className="w-full md:leading-[56px]  lg:mb-3 text-3xl md:text-[56px] text-[var(--text-color)] transition-colors duration-300 ease-in-out group-hover:text-purple-600 z-10">
          Your AI Partner for Real Results
        </span>
      </button>

      <p className="text-[var(--text-color)] text-base md:text-lg mb-8 lg:max-w-4xl text-center">
        We bridge the gap between cutting-edge AI and your business needs. Our
        team of experts translates complex technology into clear, actionable
        plans designed to propel your business forward. We focus on clear
        communication, AI Consulting, and results you can see. We have built our
        experience over a decade of working with big names like{" "}
        <strong> Microsoft, Walmart, Oracle, Cognizant, VMWare, </strong> and{" "}
        <strong> Novartis.</strong>
      </p>

      <div className="grid px-3 md:px-14 grid-cols-4 place-items-center md:grid-cols-7  gap-2">
        <img
          src={
            "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/mf.svg"
          }
          alt="Microsoft"
          className="lg:-me-5"
        />
        <img
          src={
            "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/wal.svg"
          }
          alt="Walmart"
        />
        <img
          src={
            "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/ora.svg"
          }
          alt="Oracle"
        />
        <img
          src={
            "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/cog.svg"
          }
          alt="Cognizant"
          className="lg:ms-3"
        />
        <img
          src={
            "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/vm.svg"
          }
          alt="VMWare"
        />
        <img
          src={
            "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/nov.svg"
          }
          alt="Novartis"
        />
        <img
          src={
            "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/zs.svg"
          }
          alt="ZS"
          className="lg:-ms-8 h-7 lg:h-10"
        />
      </div>
    </div>
  );
};

export default RemoteTeamSection;
