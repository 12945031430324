import React from "react";

const OurCulterCommon = ({
  subheading,
  heading,
  description,
  icon,
  bgColor,
  imgDirection,
}) => {
  return (
    <div className={`text-center px-6 md:px-12 lg:px-16`}>
      <div
        className={`${
          imgDirection === "right" ? "md:flex-row" : "md:flex-row-reverse"
        } flex flex-col lg:flex-row items-start   lg:gap-5 justify-center lg:justify-start text-[var(--text-color)]`}
      >
        <div
          className="text-start p-7 flex-grow flex flex-col justify-center"
          style={{
            backgroundColor: `${bgColor}`,
          }}
        >
          <h2
            className={`text-2xl  ${
              imgDirection === "right" ? "" : " -mb-5 "
            } md:text-3xl lg:text-4xl font-semisemibold`}
          >
            <span>{subheading}</span>
          </h2>

          <h2
            className={`text-3xl  md:text-4xl lg:text-[56px] font-semibold ${
              imgDirection === "right"
                ? "textColor lg:leading-[60px]"
                : "textColorTech lg:leading-[69px]"
            }`}
          >
            {heading}
          </h2>

          <p
            className={`text-base font-normal ${
              imgDirection === "right" ? "mt-4" : ""
            } md:text-base lg:text-xl text-[#7D7C88]`}
          >
            {description}
          </p>
        </div>
        <img
          src={icon}
          alt=""
          className={` ${
            imgDirection === "right"
              ? "md:h-[430px] lg:h-[380px]"
              : "lg:h-[370px] md:h-[470px]"
          } lg:w-[400px]  md:w-1/2 md:rounded-lg lg:flex-grow object-cover`}
        />
      </div>
    </div>
  );
};

export default OurCulterCommon;
