import React from "react";
import CareersHero from "./CareersHero";
import OurCulture from "./OurCulture";
import ExploreCareer from "../home/ExploreCareer";
import PerksBenefits from "./PerksBenefits";
import RecruitmentProcess from "./RecruitmentProcess";
import CurrentOpening from "./CurrentOpening";
import MetaData from "../../components/layout/MetaData";



const Careers = () => {

  return (
    <div className="flex flex-col   gap-20 md:gap-32 bg-[var(--background-color)]">
      <CareersHero />
      <OurCulture />
      <ExploreCareer />
      <PerksBenefits />
      <RecruitmentProcess />
      <CurrentOpening />
      <MetaData title="Careers - Abstrabit Technology" />
    </div>
  );
};

export default Careers;
