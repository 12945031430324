import React, { useEffect, useState } from "react";
import ServiceDetailsCommon from "../../components/common/ServiceDetailsCommon";
import ContactUs from "../contact-us/ContactUs";
import ClientsSection from "../home/ClientsSection";
import DynamicContentSection from "../home/DynamicContentSection";
import FAQ from "../home/Faq";
import AIJourneyExpertise from "./AIJourneyExpertise";
import TransFormYourBusiness from "./TransFormYourBusiness";
import UnlockPotentialBusiness from "./UnlockPotentialBusiness";
import MetaData from "../../components/layout/MetaData";
import {
  aiAssistantDataHome,
  aiAssistantDataSection2,
  aiAssistantDataSection3,
  aiAssistantFaqData,
  aiChatbotDataHome,
  aiChatbotDataSection2,
  aiChatbotDataSection3,
  aiStrategyConsultingDataFaqData,
  aiStrategyConsultingDataHome,
  aiStrategyConsultingDataSection2,
  aiStrategyConsultingDataSection3,
  appDevelopmentDataHome,
  appDevelopmentDataSection2,
  appDevelopmentDataSection3,
  appDevelopmentFaqData,
  chatbotFaqData,
  generativeAiDataHome,
  generativeAiDataSection2,
  generativeAiDataSection3,
  generativeDataFaqData,
  gptAiAutomationHome,
  gptAiAutomationSection2,
  gptAiAutomationSection3,
  gptAutomationFaqData,
} from "../../components/data/ServiceData";
import { useLocation } from "react-router-dom";

const ServiceDetails = () => {
  const [name, setName] = useState("");

  const location = useLocation();
  console.log(location, "abs");

  const [currentPage, setCurrentPage] = useState("AI Strategy & Consulting"); // Default to page 1

  useEffect(() => {
    setCurrentPage(location.state);
    setName(location.state);
  }, [location.state]);

  const getFaqData = () => {
    switch (currentPage) {
      case "AI Strategy & Consulting":
        return aiStrategyConsultingDataFaqData;
      case "Generative AI Solutions":
        return generativeDataFaqData;
      case "AI Chatbot Solutions":
        return chatbotFaqData;
      case "AI Knowledge Assistants":
        return aiAssistantFaqData;
      case "Workflow Automation with GPT":
        return gptAutomationFaqData;
      case "AI-Powered App Development":
        return appDevelopmentFaqData;
      default:
        return aiStrategyConsultingDataFaqData;
    }
  };

  const getUnlockPotentialBusiness = () => {
    switch (currentPage) {
      case "AI Strategy & Consulting":
        return aiStrategyConsultingDataSection2;
      case "Generative AI Solutions":
        return generativeAiDataSection2;
      case "AI Chatbot Solutions":
        return aiChatbotDataSection2;
      case "AI Knowledge Assistants":
        return aiAssistantDataSection2;
      case "Workflow Automation with GPT":
        return gptAiAutomationSection2;
      case "AI-Powered App Development":
        return appDevelopmentDataSection2;
      default:
        return aiStrategyConsultingDataSection2;
    }
  };

  const getHomeData = () => {
    switch (currentPage) {
      case "AI Strategy & Consulting":
        return aiStrategyConsultingDataHome;
      case "Generative AI Solutions":
        return generativeAiDataHome;
      case "AI Chatbot Solutions":
        return aiChatbotDataHome;
      case "AI Knowledge Assistants":
        return aiAssistantDataHome;
      case "Workflow Automation with GPT":
        return gptAiAutomationHome;
      case "AI-Powered App Development":
        return appDevelopmentDataHome;
      default:
        return aiStrategyConsultingDataHome;
    }
  };

  const getTransFormBusiness = () => {
    switch (currentPage) {
      case "AI Strategy & Consulting":
        return aiStrategyConsultingDataSection3;
      case "Generative AI Solutions":
        return generativeAiDataSection3;
      case "AI Chatbot Solutions":
        return aiChatbotDataSection3;
      case "AI Knowledge Assistants":
        return aiAssistantDataSection3;
      case "Workflow Automation with GPT":
        return gptAiAutomationSection3;
      case "AI-Powered App Development":
        return appDevelopmentDataSection3;
      default:
        return aiStrategyConsultingDataSection3;
    }
  };

  return (
    <div className=" flex flex-col gap-20 md:gap-44">
      <ServiceDetailsCommon name={name} homeData={getHomeData()} />
      <UnlockPotentialBusiness
        getUnlockPotentialBusinessData={getUnlockPotentialBusiness()}
      />
      <TransFormYourBusiness
        getTransFormBusinessData={getTransFormBusiness()}
      />
      <AIJourneyExpertise currentPage={currentPage} />
      <DynamicContentSection />
      <ClientsSection />
      <FAQ faqData={getFaqData()} />
      <ContactUs heading={"Partner with Us to Bring Your Vision to Life"} />
      <MetaData
        title={`${location.state} - Service Details - Abstrabit Technologies`}
      />
    </div>
  );
};

export default ServiceDetails;
