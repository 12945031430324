import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";

import { parsePhoneNumber } from "libphonenumber-js";

import Loader from "../layout/loading/Loader";

const DownloadUserList = () => {
  const [rows, setRows] = useState([]); // Rows for DataGrid

  const getCountryName = (phoneNumber) => {
    if (phoneNumber) {
      const phoneNumberObj = parsePhoneNumber(phoneNumber);
      return phoneNumberObj.country;
    }
    return "";
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://abstratbit-default-rtdb.firebaseio.com/downloadUsers.json"
        );
        const fetchedData = response.data;

        const transformedRows = Object.keys(fetchedData)
          .reverse()
          .map((key) => ({
            id: key, // Key as the unique id for each row

            email: fetchedData[key]?.email || "",
            dateCreated: fetchedData[key]?.date || "",

            name: fetchedData[key]?.name || "",
            phoneNo: fetchedData[key]?.phone || "",
            country: fetchedData[key]?.phone,
          }));

        setRows(transformedRows); // Store rows for DataGrid
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Stop loading once data is fetched
      }
    };
    fetchData();
  }, []);
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 180,
      renderCell: (params) => (
        <div className="mt-5">
          <h1 className="text-[#52637D] text-base leading-6 font-medium">
            {params.value}
          </h1>
        </div>
      ),
    },

    {
      field: "email",
      headerName: "Email",
      width: 250,
      renderCell: (params) => (
        <div className="mt-5">
          <h1 className="text-[#52637D] text-base leading-6 font-medium">
            {params.value}
          </h1>
        </div>
      ),
    },

    {
      field: "dateCreated",
      headerName: "Date Added",
      width: 130,
      renderCell: (params) => (
        <div className="mt-5">
          <h1 className="text-[#52637D] text-base leading-6 font-medium">
            {params.value}
          </h1>
        </div>
      ),
    },
    {
      field: "phoneNo",
      headerName: "Phone No",
      width: 150,
      renderCell: (params) => (
        <div className="mt-5">
          <h1 className="text-[#52637D] text-base leading-6 font-medium">
            {params.value}
          </h1>
        </div>
      ),
    },

    {
      field: "country",
      headerName: "Country",
      width: 100,
      renderCell: (params) => (
        <div className="mt-5">
          <h1 className="text-[#52637D] text-base leading-6 font-medium">
            {getCountryName(params.value)}
          </h1>
        </div>
      ),
    },
  ];

  //searching filtering technique -------------------------------------------------------------------------------------------------->

  const [searchQuery, setSearchQuery] = useState("");

  const filteredOnSearchAndFilerRows = rows?.filter((row) => {
    const lowerCaseSearchQuery = searchQuery?.toLowerCase();

    const matchesName = row.name?.toLowerCase().includes(lowerCaseSearchQuery);
    const matchesEmail = row.email
      ?.toLowerCase()
      .includes(lowerCaseSearchQuery);

    return matchesName || matchesEmail;
  });

  return (
    <div className="min-h-screen pb-10  bg-black ">
      <div className="lg:flex-row flex flex-col items-center justify-between">
        <h1 className="text-3xl flex justify-center items-center gap-2 md:text-start text-white font-bold text-center">
          Total Downloaded Users{" "}
          <span className="text-xl mt-1 font-normal">
            ({filteredOnSearchAndFilerRows.length})
          </span>
        </h1>
        <div className="pt-2 mb-5 relative flex items-center justify-between text-gray-600">
          <div>
            <input
              className="border-2 w-full md:w-[400px] border-gray-300 bg-white h-10 px-5 pr-10 rounded-lg text-base focus:outline-none"
              type="search"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              placeholder="Search by name or email"
            />
            <button className="absolute -translate-x-9 top-0 mt-5">
              <FaSearch />
            </button>
          </div>
        </div>
      </div>

      {/* Display Cards for each user */}
      <div className="bg-[#fff] h-auto z-10 w-full flex items-center justify-center mb-5">
        {filteredOnSearchAndFilerRows?.length ? (
          <DataGrid
            rows={filteredOnSearchAndFilerRows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            rowHeight={60}
          />
        ) : loading ? (
          <Loader />
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <div className="pb-10">
              <img
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/notfound.svg"
                }
                alt="noDataImg"
              />
              <div className="text-[#818181] text-center text-[40px] leading-[28px] font-semibold">
                No Data Found
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DownloadUserList;
