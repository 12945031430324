import React from "react";

import MetaData from "../../components/layout/MetaData";
import PlaceholderPage from "./PlaceholderPage";

const WhitePapers = () => {
  return (
    <div className="flex flex-col gap-20 md:gap-44   bg-[var(--background-color)]">
      <PlaceholderPage />
      <MetaData title="WhitePapers - Abstrabit Technologies" />
    </div>
  );
};

export default WhitePapers;
