import React from "react";
import { useNavigate } from "react-router-dom";

const ServiceCommon = ({
  heading,
  icon,
  bgColor,
  imgDirection,
  desc,
  list,
  link,
}) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        backgroundColor: `${bgColor}`,
      }}
      className={`text-center  `}
    >
      <div
        className={`${
          imgDirection === "right" ? "md:flex-row" : "md:flex-row-reverse"
        }   gap-5 max-w-7xl mx-auto px-6 md:px-12 lg:px-16  flex flex-col  items-center lg:gap-10 justify-center lg:justify-start py-2 md:py-16 text-[var(--text-color)]`}
      >
        <div className="text-start md:w-[50%] lg:w-[60%] space-y-5 lg:space-y-6 mb-10">
          <h2 className="text-4xl md:text-4xl  lg:text-[56px] font-semibold textColorPurple">
            <span className="lg:leading-[60px]"> {heading}</span>
          </h2>

          <p className="text-base md:text-base lg:text-xl">{desc}</p>

          <div className="flex text-base md:text-xl mb-4 flex-col items-start justify-start gap-2 font-medium">
            {list &&
              list.map((item, index) => (
                <div
                  key={index}
                  className="flex items-start justify-start gap-2 text-base md:text-xl"
                >
                  <img
                    className="w-3 md:w-6 md:h-6 h-3 mt-1"
                    src={
                      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/sk.svg"
                    }
                    alt="active logo"
                  />
                  {item}
                </div>
              ))}
          </div>

          <div className="mt-3">
            <a
              onClick={() =>
                navigate(`${link}`, {
                  state: heading,
                })
              }
              className={`btn cursor-pointer hover:text-red-500 bg-opacity-50  bg-custom-gradient hover:bg-custom-gradient-hover text-base lg:text-lg rounded-full text-[var(--text-color)]  font-semibold px-5 md:px-5 py-2 md:py-2.5 duration-500 md:static`}
            >
              Explore More
            </a>
          </div>
        </div>{" "}
        <img
          src={icon}
          alt=""
          className="lg:w-[45%] md:w-[50%] h-full object-cover"
        />
      </div>
    </div>
  );
};

export default ServiceCommon;
