import React from "react";

const TableContentPopup = ({ isOpen, setIsOpen, tableOfContents }) => {
  return (
    <div
      className={`fixed overflow-auto top-9 left-0 w-full h-full bg-[#161617] bg-opacity-50 z-40 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="w-full my-auto mx-auto bg-[#161617] rounded-lg shadow-lg">
        <div className="px-4 md:px-10 pt-2 pb-10">
          <div className="text-white flex items-center justify-between">
            <div></div>
            <span
              onClick={() => setIsOpen(false)}
              className="text-xl cursor-pointer"
            >
              X
            </span>
          </div>

          <div className=" block md:hidden">
            {/* Table of Contents */}
            <div className="bg-[var(--background-gray-color)] rounded-md">
              <h3 className="text-4xl font-semibold mb-8">Table of Contents</h3>
              <ul className="space-y-1 flex flex-col items-start justify-start">
                {tableOfContents.map((item) => (
                  <li
                    key={item.id}
                    className="tableContent p-2 rounded-2xl w-full hover:bg-custom-gradient-purple"
                  >
                    <a
                      href={item?.link}
                      className="text-[var(--text-color)] text-xl ps-1"
                    >
                      {item?.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {/* Call to Action */}
            <div className="bg-[var(--background-gray-color)] p-6 rounded-md w-full z-40 sticky top-16">
              <img
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/blog-assets/downloadblog.svg"
                }
                alt="Call to action"
                className="w-full h-[200px] rounded-md mb-4"
              />

              <p className="text-[var(--text-color)]  text-center text-base lg:text-xl leading-6 font-medium my-5 md:my-7">
                How to Choose a Custom Software Development Company?
              </p>

              <div
                // onClick={() => setIsOpenPopup(true)}
                className="btn cursor-pointer text-center w-full bg-cover hover:text-red-500 bg-opacity-50  bg-custom-gradient hover:bg-custom-gradient-hover text-base lg:text-lg rounded-full text-[var(--text-color)]
               font-semibold px-5 py-3 duration-500 md:static"
              >
                Download Now
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableContentPopup;
