import React from "react";

const AIToTransForm = () => {
  return (
    <div className="bg-[var(--background-color)] text-[var(--text-color)] px-6 md:px-16  text-center max-w-7xl mx-auto ">
      <h2 className="text-3xl md:text-[56px] font-semibold mb-2 md:mb-7">
        We Use AI to Transform
      </h2>

      <p className="textColor lg:max-w-xl  mx-auto text-center text-base md:text-4xl font-bold lg:font-semibold mb-3">
        Revolutionizing businesses with AI-powered solutions
      </p>

      <div className="gradient-purple  text-[var(--text-color)] text-xl md:text-3xl font-semibold py-4 md:py-7 px-6 md:px-14  rounded-sm inline-block">
        Your AI Partner for Growth
      </div>
    </div>
  );
};

export default AIToTransForm;
