import React from "react";
import JobWidget from "./JobWidget";

const CurrentOpening = () => {
  return (
    <div className="bg-[var(--background-gray-color)]">
      <div className=" px-6 md:px-16 mb-20 mx-auto max-w-7xl">
        <main className="w-full max-w-6xl mx-auto bg-light-blue rounded-lg py-7 lg:py-10">
          <h2 className="text-3xl text-center md:text-[56px] font-semibold mb-5 md:mb-14">
            <span className="textColor font-bold lg:leading-[60px]">
              Current Openings
            </span>
          </h2>
          <JobWidget />
        </main>
      </div>
    </div>
  );
};

export default CurrentOpening;
