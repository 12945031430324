import React from "react";
import MetaData from "../../components/layout/MetaData";

import AboutReviewSection from "../about-us/AboutReviewSection";
import ButtonComponent from "../../components/common/ButtonComponent";

const ServiceHero = () => {
  return (
    <div
      className="flex  bg-no-repeat  text-[#fcfcfc] flex-col items-start justify-start pt-14 md:pt-20 bg-cover bg-center"
      style={{
        backgroundImage: `url(https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/servicebg.svg)`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <MetaData title="Service - Abstrabit Technologies" />
      <div className="flex px-6 md:px-12 lg:px-16 max-w-7xl mx-auto lg:mb-10 gap-5 items-center md:items-start justify-center md:justify-between flex-col w-full">
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-14 mb-4 gap-5">
          <div className="flex space-y-9 mt-14 animate-fade-in-down  flex-col items-center md:items-start justify-center md:justify-start">
            <div className="flex flex-col lg:flex-row md:items-start items-center justify-center md:justify-start w-full relative font-bold gap-5 md:font-semibold text-4xl md:text-5xl lg:text-6xl">
              <p className=" text-center md:text-start  flex flex-col">
                <span className=" mb-0">Empower </span>{" "}
                <span className=" mb-0"> Your Business </span>{" "}
                <span className=" mb-0"> with Advanced </span>
                <span className="textColorPurple mb-0"> AI Capabilities </span>
              </p>
            </div>
            <p className="text-[var(--text-color)] md:text-start text-center text-base lg:text-xl font-normal">
              Building solutions for tomorrow through advanced technology,
              strategic insights, and unparalleled expertise. Share your project
              ideas today!{" "}
            </p>

            <ButtonComponent />
          </div>

          <div className="animate-fade-in-right lg:mt-14 relative lg:left-8 ">
            <img
              src={
                "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/sg.svg"
              }
              alt=""
              className="lg:w-[500px] w-[400px]"
            />
          </div>
        </div>
        <div className="lg:-mt-20">
          <AboutReviewSection page={"service"} />
        </div>
      </div>
    </div>
  );
};

export default ServiceHero;
