//testimonial section data -------------------------------------------------------------->
export const testimonialResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const testimonials = [
  {
    id: 1,
    color: "#7A1AE9",
    name: "Damon Anderson",
    position: " Compliance Help",
    text: "Abstrabit delivered well-designed, reliable AI products that our staff uses daily. Their team impressed us with their responsiveness, top-notch communication, and unwavering commitment to quality work. We're thrilled with the results and look forward to continued collaboration!",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/test1.png",
  },
  {
    id: 2,
    color: "#B827F1",
    name: "Harsha Anil",
    position: "Karmikh",
    text: "Thank you! Our new website boasts excellent SEO performance, 100% uptime, and a user-friendly mobile experience. Managing content and inventory is now a breeze. Their transparent project management and expert guidance made the entire process smooth, successful, and highly satisfying.",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/test2.png",
  },
  {
    id: 3,
    color: "#F37257",
    name: "Alfred Robinson",
    position: "Thirty2give",
    text: "They have been a great partner. The team's collaborative spirit, creative insight, and technical knowledge have been key to our progress this year. Special recognition for their commitment to communication across time zones and platforms. Their dedication makes them a top recommendation!",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/test3.png",
  },
  {
    id: 4,
    color: "#EE426A",
    name: "Nikhil Ojha ",
    position: "Nurturev",
    text: "Abstrabit Technologies delivered our project on schedule, thanks to their knowledgeable leadership and effective technical solutions. The use of Freshdesk kept everything organized. We were particularly impressed by their team's expertise, leadership, and commitment to a positive experience.",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/test4.png",
  },
  {
    id: 5,
    color: "#B827F1",
    name: "Manish Ranjan",
    position: "House of Maithili",
    text: "Abstrabit brought our e-commerce vision to life! They flawlessly launched our website with all the features we needed, and their creative team actively incorporated our feedback throughout the process. We were impressed by their responsiveness, on-time delivery, and dedication to excellence.",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/test6.jpg",
  },
  {
    id: 6,
    color: "#7A1AE9",
    name: "Nataly Mendocilla",
    position: "Medocilla Law Firm",
    text: "They are an excellent team to work with. I've searched for years to find someone who truly listens and understands my vision, and Shobhraj did just that. She offers valuable feedback, proposes thoughtful ideas, and executes efficiently. Despite the time difference, our collaboration has been seamless. I highly recommend Abstrabit!",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/test5.png",
  },
];

// --------------------------------------------------------------------------------------------->

// capability section ------------------------------------------------------------------------------>
export const capabilitiesContentData = [
  {
    id: 1,
    link: "/services/ai-strategy-consulting",
    heading: "AI Strategy & Consulting",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/cp1.svg",
    color: "linear-gradient(180deg, #281452 0%, #BB10A4 100%)",
    text: "Transform your business with strategic AI insights and consulting. Unlock new opportunities, optimize operations, and drive growth with our expert guidance, tailored to create lasting value and competitive advantage. Our comprehensive approach ensures alignment with your goals, leveraging AI Consulting to foster innovation and long-term su",
  },
  {
    id: 2,
    link: "/services/generative-ai-solutions",
    heading: "Generative AI Solutions",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/cp2.svg",
    color: "linear-gradient(180deg, #1288B0 0%, #2E7161 100%)",
    text: "Unlock new possibilities with generative AI. Enhance your products and operations, boost productivity, and gain a competitive edge with cutting-edge AI technologies. From ideation to implementation, we provide comprehensive support, ensuring seamless integration and maximizing the transformative impact of AI on your business.",
  },
  {
    id: 3,
    link: "/services/ai-chatbot-solutions",
    heading: "AI Chatbot Solutions",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/cp-3.svg",
    color: "linear-gradient(180deg, #A53DE5 0%, #0F26B4 100%)",
    text: "Elevate customer engagement and support with custom AI chatbots. Provide instant, intelligent assistance, enhance user experiences, and streamline communication, leading to increased satisfaction and efficiency. We develop customized AI Chatbot Solutions that align perfectly with your customers' specific needs, ensuring your business delivers exceptional and personalized experiences.",
  },
  {
    id: 4,
    link: "/services/ai-knowledge-assistants",
    heading: "AI Knowledge Assistants",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/cp-4.svg",
    color: "linear-gradient(180deg, #4637B6 0%, #3671C3 100%)",
    text: "Empower your team with AI-driven knowledge assistants. Access instant, accurate information, enabling smarter decision-making and freeing up time for strategic initiatives. Our solutions are tailored to fit your organizational needs, enhancing productivity and ensuring your team is always informed and efficient.",
  },
  {
    id: 5,
    link: "/services/workflow-automation-with-gpt",
    heading: "Workflow Automation with GPT",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/cp-5.svg",
    color: "linear-gradient(180deg, #C54A3B 0%, #E6558E 100%)",
    text: "Maximize productivity with GPT-powered workflow automation. Reduce manual tasks, improve accuracy, and accelerate processes, allowing your team to focus on high-impact activities that drive business growth. Our automation solutions streamline operations, minimize errors, and enhance overall efficiency, transforming your workflow for optimal performance.",
  },
  {
    id: 6,
    link: "/services/aipowered-app-development",
    heading: "AI-Powered App Development",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/cp-6.svg",
    color: "linear-gradient(180deg, #5E272A 0%, #B27847 100%)",
    text: "Deliver exceptional user experiences with AI-powered web and mobile applications. Leverage advanced functionalities like personalized recommendations and intelligent features to drive engagement, satisfaction, and a competitive edge. Stay ahead of the curve by incorporating AI that personalizes experiences and creates intuitive user journeys.",
  },
];

//client section ------------------------------------------------------------------------------------->
export const clientProjectsData = [
  {
    title: "Voyage Pro",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/ow1.svg", // replace with the path to the image
    description:
      "We built a centralized web app for Voyage Pro, integrating AI-driven client management, automation, and efficiency.",
  },
  {
    title: "Soulpay",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/ow2.svg",
    description:
      "Benefiting from enhanced scalability, AI-driven monitoring, reduced downtime, fewer duplicates, and improved experience.",
  },
  {
    title: "Karmikh",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/ow3.svg",
    description:
      "Karmikh achieved 200% website traffic growth, 150% sales increase, and improved global reach through AI-driven e-commerce.",
  },
  {
    title: "Thirty2give",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/ow5.jpg",
    description:
      "Thirty2give saw a 30% engagement boost, 40% mentor efficiency increase, and 25% B2B growth through AI integration.",
  },
  {
    title: "Compliance Help",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/ow4.svg",
    description:
      "Compliance Help provides a toolkit for individuals and businesses needing assistance with regulatory compliance.",
  },

  {
    title: "Home Network Partner",
    image:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/ow6.jpg",
    description:
      "Revolutionized data management and communication, boosting engagement by 30% and cutting manual effort by 40%.",
  },
];

export const engagementToolsData = [
  {
    title: "Fixed-Cost Product Development",
    list: [
      "Ideal for : Well-defined projects with clear deliverables and a fixed budget.",
      "Benefits : Predictable costs, streamlined development process, and a clear timeline for completion.",
    ],
    imageUrl:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/e1.svg",
    color: "#7A1AE9",
  },
  {
    title: "Dedicated Tech Team",
    list: [
      "Ideal for : Ongoing projects requiring a dedicated team of AI & Full-stack experts for continuous development and support.",
      "Benefits : Scalable expertise aligned with your project needs, seamless integration with your team, and ongoing innovation.",
    ],
    imageUrl:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/e2.svg",
    color: "#B827F1",
  },
  {
    title: "Maintenance and Upgradation",
    list: [
      "Ideal for : Existing AI products or systems requiring ongoing maintenance, bug fixes, and feature enhancements.",
      "Benefits : Ensured long-term value for your AI investment, improved performance and stability, and continuous adaptation to evolving needs.",
    ],
    imageUrl:
      "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/e3.svg",
    color: "#F37257",
  },
];

export const contentDataDynamicSection = [
  {
    id: 1,
    heading: "Your Goals, Our Focus",
    text: "We prioritize understanding your unique business goals and challenges. Forget one-size-fits-all – we create custom AI solutions tailored to your specific needs.",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/w1.svg",
  },
  {
    id: 2,
    heading: "Results You Can See",
    text: "We pride ourselves on transparent and explainable AI. You'll always understand how AI is driving success, allowing for better decision-making and continuous improvement.",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/w2.svg",
  },
  {
    id: 3,
    heading: "Expert Innovators in AI",
    text: "Our passionate experts continually push the boundaries of AI technology, bringing you the most advanced solutions to propel your business forward and ensure ongoing success.",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/w3.svg",
  },
  {
    id: 4,
    heading: "Clear Communication, Every Step",
    text: "With a streamlined process and proficient communication skills, we ensure all stakeholders are aligned and their expectations are met. ",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/w4.svg",
  },
  {
    id: 5,
    heading: "Proven Success Stories",
    text: "With over 30 global clients, 150+ successfully delivered projects, and a team of 25+ dedicated members, our track record speaks for itself. Let us be your next success story!",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/w5.svg",
  },
];

export const exploreCareerData = [
  {
    title: "Innovative Projects",
    description:
      "Work on groundbreaking AI solutions for diverse industries and clients",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/checkbox.svg",
  },
  {
    title: "Specialized Professionals",
    description:
      "Collaborate with industry-leading professionals passionate about artificial intelligence",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/checkbox.svg",
  },
  {
    title: "Career Growth",
    description:
      "Advance your skills and career with continuous learning opportunities",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/checkbox.svg",
  },
  {
    title: "Supportive Environment",
    description:
      "Thrive in a culture that values teamwork, innovation, and creativity.",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/checkbox.svg",
  },
  {
    title: "Cutting-Edge Technology",
    description:
      "Access the latest AI tools and technologies to drive success.",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/checkbox.svg",
  },
  {
    title: "Global Impact",
    description:
      "Contribute to projects from all over the world that transform businesses and make a difference.",
    icon: "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/checkbox.svg",
  },
];

//faq data

export const faqData = [
  {
    id: 1,
    question: "What is AI Strategy & Consulting?",
    answer:
      "AI Strategy & Consulting helps businesses identify how artificial intelligence can benefit their specific goals. Our experts will work with you to develop a tailored plan to unlock new opportunities, optimize operations, and achieve a competitive advantage through AI implementation.",
  },
  {
    id: 2,
    question: "How can Generative AI Solutions benefit my business?",
    answer:
      "Generative AI can unlock entirely new possibilities. We can use these cutting-edge AI technologies to enhance your products, boost operational productivity, and give you a competitive edge in the market.",
  },
  {
    id: 3,
    question: "What are the advantages of Tailored AI Chatbots?",
    answer:
      "Tailored AI chatbots provide 24/7 customer support and engagement. They can answerwer questions intelligently, improve user experience by offering instant assistance, and streamline communication, ultimately leading to higher customer satisfaction and business efficiency.",
  },
  {
    id: 4,
    question: "How can Customized AI Knowledge Assistants empower my team?",
    answer:
      "AI-powered knowledge assistants give your team access to instant and accurate information, allowing them to automate routine tasks and make smarter decisions. This frees up valuable time for your team to focus on strategic initiatives.",
  },
  {
    id: 5,
    question:
      "How does GPT-powered workflow automation improve business processes?",
    answer:
      "Workflow automation powered by GPT reduces manual tasks, improves accuracy, and accelerates processes. This allows your team to focus on high-impact activities that directly drive business growth and achievement of your goals.",
  },
];
