import React from "react";
import ContactUs from "../contact-us/ContactUs";

const ReadyToGrowBrand = () => {
  return (
    <>
      {" "}
      <h1 className="text-3xl text-center  md:text-[56px] text-[var(--text-color)] font-semibold ">
        Ready to Grow Your Brand ?
      </h1>
      <div className="flex flex-col bg-[#161616] items-start justify-start ">
        <div className="flex flex-col  max-w-7xl mx-auto text-left  justify-center items-center">
          <ContactUs heading={"Partner with Us to Bring Your Vision to Life"} />
        </div>
      </div>
    </>
  );
};

export default ReadyToGrowBrand;
