import React, { useEffect } from "react";
import customeCss from "../../careerWidget.css";

const JobWidget = () => {
  useEffect(() => {
    // Create a script element to load the external widget script
    const script = document.createElement("script");
    script.src =
      "https://static.smartrecruiters.com/job-widget/1.7.0/script/smart_widget.js";
    script.type = "text/javascript";
    script.async = true;

    // Append the script to the document head
    document.head.appendChild(script);

    // Initialize the widget once the script is loaded
    script.onload = () => {
      if (window.widget) {
        window.widget({
          company_code: "AbstrabitTechnologiesPvtLtd",
          bg_color_widget: "#ffffff",
          bg_color_headers: "#969696",
          bg_color_links: "#A020F0",
          txt_color_headers: "#292929",
          txt_color_job: "#ffffff",
          bg_color_even_row: "#000000",
          bg_color_odd_row: "#000000",
          auto_width: "auto",
          auto_height: "auto",
          number: "on",
          job_title: "true",
          location: "true",
          type_of_employment: "true",
          published_since: "true",
          dateFormat: "us",
          remove_headers: "true",
          filter_locations: "",
          filter_languages: "en",
          trid: "",
          api_url: "https://www.smartrecruiters.com",
          custom_css_url: customeCss,
          job_ad_url: "https://jobs.smartrecruiters.com",
        });
      }
    };

    // Cleanup the script element when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className=" p-6 rounded-lg overflow-auto">
      <div className="job_widget rounded-lg  bg-transparent text-white text-lg"></div>
    </div>
  );
};

export default JobWidget;
