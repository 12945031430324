import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
// import NotFound from "./components/layout/notfound/NotFound";
import AboutUs from "./pages/about-us/AboutUs";
import Blog from "./pages/blog/Blog";
import Home from "./pages/home/Home";
import Portfolio from "./pages/portfolio/Portfolio";
import PortfolioDetails from "./pages/portfolio/PortfolioDetails";
import Service from "./pages/service/Service";
import ServiceDetails from "./pages/service/ServiceDetails";
import BlogDetails from "./pages/blog/BlogDetails";
import ScrollToTop from "./utils/ScrollToTop";
import Careers from "./pages/careers/Careers";
import ContactUsMain from "./pages/contact-us/ContactUsMain";
import CaseStudies from "./pages/resources/CaseStudies";
import WhitePapers from "./pages/resources/WhitePapers";
import Ebooks from "./pages/resources/Ebooks";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import PlaceholderPage from "./pages/resources/PlaceholderPage";
import { Toaster } from "react-hot-toast";
import Login from "./pages/login/Login";
import ProtectedRoute from "./components/context/ProtectedRoute";
import ReactGA from "react-ga4";
import PublicRoute from "./components/context/PublicRoute";
import UserData from "./components/users/UserData";
import ScrollToTopButton from "./utils/ScrollToTopButton";
import Pricing from "./pages/pricing/Pricing";

const TRACKING_ID = "G-YJBH37T9M5";
ReactGA.initialize(TRACKING_ID);
const App = () => {
  return (
    <div className="bg-[var(--background-color)] no-scrollbar">
      <BrowserRouter>
        <ScrollToTop />
        <TrackPageViews />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />

          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Service />} />
          <Route
            path="/admin/all-users"
            element={
              <ProtectedRoute>
                <UserData />
              </ProtectedRoute>
            }
          />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/resources/blogs" element={<Blog />} />
          <Route path="/resources/case-studies" element={<CaseStudies />} />
          <Route path="/resources/whitepapers" element={<WhitePapers />} />

          <Route path="/resources/ebooks" element={<Ebooks />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/blog/blog-details/:id" element={<BlogDetails />} />
          <Route
            path="/portfolio/portfolio-details/:id"
            element={<PortfolioDetails />}
          />
          <Route path="/contact-us" element={<ContactUsMain />} />
          <Route path="/services/:id" element={<ServiceDetails />} />

          <Route path="*" element={<PlaceholderPage />} />
        </Routes>
        <ScrollToTopButton />
        <Toaster />
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;

const TrackPageViews = () => {
  const location = useLocation();

  // Map route paths to page titles
  const titleMap = {
    "/": "Home | Abstrabit Technology",
    "/about-us": "About Us | Abstrabit Technology",
    "/services": "Services | Abstrabit Technology",
    "/services/:id": "Services Details | Abstrabit Technology",
    "/portfolio": "Portfolio | Abstrabit Technology",
    "/portfolio/portfolio-details/:id":
      "Portfolio Details | Abstrabit Technology",
    "/resources/blogs": "Blog | Abstrabit Technology",
    "/blog/blog-details/:id": "Blog Details | Abstrabit Technology",
    "/contact-us": "Contact Us | Abstrabit Technology",
    "/login": "Login | Abstrabit Technology",
    "/signup": "Sign Up | Abstrabit Technology",
    "/privacy-policy": "Privacy Policy | Abstrabit Technology",
    "/careers": "Careers | Abstrabit Technology",
    "/resources/case-studies": "Case Studies | Abstrabit Technology",
    "/resources/whitepapers": "White Papers | Abstrabit Technology",
    "/resources/ebooks": "Ebooks | Abstrabit Technology",
    "/pricing": "Pricing | Abstrabit Technology",
    "/admin/all-users": "Users List | Abstrabit Technology",
    "*": "404 Not Found | Abstrabit Technology", // Catch-all for undefined routes
  };

  useEffect(() => {
    const pageTitle = titleMap[location.pathname] || "Abstrabit Technology";
    document.title = pageTitle;

    // Send page view to Google Analytics
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
};
