import React from "react";

import ServiceCommon from "../../components/common/ServiceCommon";

const OurCapabilities = () => {
  const list1 = [
    "Drive growth and innovation tailored to your goals, creating lasting value and a competitive edge.",
    "Transform your business with AI insights and consulting to unlock new opportunities and optimize operations.",
    "Leverage AI consulting to drive growth with expert guidance, tailored to create lasting value and a competitive edge and long-term success, aligned with your goals.",
  ];

  const list2 = [
    "Gain a market edge and boost productivity from concept to seamless AI integration. Unlock new possibilities and enhance your products and operations with generative AI.",
    "Boost productivity and gain a competitive edge using cutting-edge AI technologies.",
    "From ideation to implementation, we provide comprehensive support for seamless AI integration and transformation.",
  ];

  const list3 = [
    "Deliver instant, intelligent assistance to enhance user experiences and streamline communication.",
    "Elevate customer engagement and support with custom AI chatbots that offer instant, intelligent assistance.",
    "Enhance user experiences and streamline communication, boosting satisfaction and efficiency. We create tailored AI chatbot solutions designed to meet your business's unique customer needs, with our specialized chatbot consulting services.",
  ];

  const list4 = [
    "Boost productivity and decision-making with our tailored AI knowledge solutions.",
    "Empower your team with AI-driven knowledge assistants for instant, accurate information.Enable smarter decision-making and free up time for strategic initiatives.",
    "Our customized solutions enhance productivity, keeping your team efficient.",
  ];

  const list5 = [
    "Reduce manual tasks and errors, allowing your team to focus on growth and high-impact activities.",
    "Maximize productivity with GPT-powered workflow automation, reducing manual tasks and improving accuracy.",
    "Accelerate processes, allowing your team to focus on high-impact activities that drive business growth.Our solutions streamline operations, minimize errors, and enhance overall efficiency for optimal performance.",
  ];

  const list6 = [
    "Boost engagement with personalized features and intuitive, cutting-edge design.",
    "Deliver exceptional user experiences with AI-powered web and mobile applications.",
    "Leverage advanced features like personalized recommendations to boost engagement and satisfaction.Stay ahead by incorporating AI that personalizes experiences and creates intuitive user journeys.",
  ];

  return (
    <div className="bg-[var(--background-color)] text-[var(--text-color)]">
      <div className="">
        <h2 className="text-3xl  mb-5 md:mb-14 flex flex-col items-center justify-center md:text-[56px] font-semibold">
          Our Capabilities
        </h2>
        <div className="space-y-10">
          <ServiceCommon
            heading={"AI Strategy & Consulting"}
            icon={
              "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/spt.svg"
            }
            bgColor={"#161617"}
            imgDirection={"right"}
            desc={
              "Unlock new opportunities and optimize your business with expert AI insights through our AI consulting services. "
            }
            list={list1}
            link={"/services/ai-strategy-consulting"}
          />
          <ServiceCommon
            heading={"End-to-End Generative AI Solutions"}
            icon={
              "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/spt2.svg"
            }
            bgColor={"#000000"}
            imgDirection={"left"}
            desc={
              "Enhance your products and operations with our comprehensive generative AI support."
            }
            list={list2}
            link={"/services/generative-ai-solutions"}
          />
          <ServiceCommon
            heading={"Tailored AI Chatbot Solutions"}
            icon={
              "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/spt3.svg"
            }
            bgColor={"#161617"}
            imgDirection={"right"}
            desc={"Transform customer support with custom AI chatbots."}
            list={list3}
            link={"/services/ai-chatbot-solutions"}
          />

          <ServiceCommon
            heading={"Customized AI Knowledge Assistants"}
            icon={
              "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/spt4.svg"
            }
            bgColor={"#000000"}
            imgDirection={"left"}
            desc={"Empower your team with instant, accurate information."}
            list={list4}
            link={"/services/ai-knowledge-assistants"}
          />

          <ServiceCommon
            heading={"Efficient Workflow Automation with GPT"}
            icon={
              "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/spt5.svg"
            }
            bgColor={"#161617"}
            imgDirection={"right"}
            desc={"Maximize efficiency with GPT-powered automation."}
            list={list5}
            link={"/services/workflow-automation-with-gpt"}
          />

          <ServiceCommon
            heading={"AI-Powered Web & Mobile App Development"}
            icon={
              "https://abswebsiteassets.blob.core.windows.net/websiteimages/service-assets/spt6.svg"
            }
            bgColor={"#000000"}
            imgDirection={"left"}
            desc={"Deliver exceptional user experiences with AI-powered apps."}
            list={list6}
            link={"/services/aipowered-app-development"}
          />
        </div>
      </div>
    </div>
  );
};

export default OurCapabilities;
