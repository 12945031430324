import React from "react";

import { Link } from "react-router-dom";
import AboutReviewSection from "../about-us/AboutReviewSection";

const BlogHero = () => {
  return (
    <div className=" bg-[var(--background-gray-color)] text-[var(--text-color)]">
      <div className="flex shadow-sm max-w-7xl pb-20 mx-auto px-6 md:px-12 lg:px-16 flex-col md:items-start md:justify-start items-center justify-center pt-14 md:pt-14 lg:pt-14">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-20">
          <div className="flex animate-fade-in-down space-y-9 flex-col items-center md:items-start justify-center md:justify-start">
            <div className="flex flex-col  mt-14 lg:flex-row md:items-start items-center justify-center md:justify-start w-full relative font-bold md:font-semibold text-5xl lg:text-6xl">
              <p className="text-center md:text-start  flex flex-col">
                <span className="textColorPurple lg:leading-[100px] leading-[60px]">
                  {" "}
                  Insights{" "}
                </span>{" "}
                <span className=" mb-0 lg:-mt-3">&</span>{" "}
                <span className=" mb-0"> Innovations</span>
              </p>
            </div>
            <p className="text-[var(--text-color)] md:text-start max-w-1xl text-center text-base lg:text-xl leading-6 font-medium">
              Explore our expert-crafted articles and insights to stay updated
              on the latest trends, innovative solutions, and best practices
              driving technology success.
            </p>

            <Link
              className="btn bg-cover hover:text-red-500 bg-opacity-50  bg-custom-gradient hover:bg-custom-gradient-hover text-base lg:text-lg rounded-full text-[var(--text-color)]
               font-semibold px-5 py-2.5 duration-500 md:static"
            >
              Request a Quote
            </Link>
          </div>

          <div className="animate-fade-in-right mt-10 lg:mt-24  flex items-center justify-center">
            <div className="md:-right-10  relative">
              <img
                src="https://abswebsiteassets.blob.core.windows.net/websiteimages/blog-assets/blog1.svg"
                alt="wer"
                className=""
              />
            </div>
            <div className="flex items-start justify-start flex-col">
              <img
                src="https://abswebsiteassets.blob.core.windows.net/websiteimages/blog-assets/blog2.svg"
                alt="wre"
                className=""
              />
              <img
                src="https://abswebsiteassets.blob.core.windows.net/websiteimages/blog-assets/blog3.svg"
                alt="df"
                className="-ms-16 -mt-20"
              />
            </div>
          </div>
        </div>
        <div className="lg:-mt-14 mt-10 animate-fade-in-down">
          {" "}
          <AboutReviewSection page={"portfolio"} />
        </div>
      </div>
    </div>
  );
};

export default BlogHero;
