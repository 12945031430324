import React from "react";

import WorkingProcessCard from "../../components/common/WorkingProcessCard";
import { perksBenefitsCard } from "../../components/data/CareerData";

const PerksBenefits = () => {
  return (
    <div className="bg-[var(--background-color)]">
      <div className="max-w-7xl mx-auto  px-6 md:px-12 lg:px-16 ">
        <div className=" text-[var(--text-color)]  flex items-center justify-center flex-col ">
          <div
            className={`grid grid-cols-1 gap-10 md:grid-cols-3  text-[var(--text-color)]`}
          >
            <div className="text-start md:flex-col">
              <div className="flex-1">
                {" "}
                <h2 className="text-3xl flex flex-col text-start md:text-4xl lg:text-5xl lg:leading-10  font-semibold">
                  <span className="textColorPurple font-bold">Perks</span>
                  <span>& Benefits</span>
                </h2>
                <p className="text-base font-normal md:text-base lg:text-xl my-2 md:my-5 ">
                  We develop custom AI software to automate processes, boosting
                  efficiency and freeing your team to focus on strategic
                  initiatives. 
                </p>
              </div>

              <div className="flex-1">
                {" "}
                <img
                  src="https://abswebsiteassets.blob.core.windows.net/websiteimages/career-assets/perkB.svg"
                  alt=""
                  className="w-full lg:h-full object-cover"
                />
              </div>
            </div>

            <div className="md:col-span-2">
              <WorkingProcessCard tabCard={perksBenefitsCard} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerksBenefits;
