import React, { useState } from "react";

import CaseStudyLeadPopup from "../../components/layout/popup/CaseStudyLeadPopup";
import TableContentPopup from "../../components/layout/popup/TableContentPopup";

const TableContent = ({ tableContent }) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isMobileViewOpen, setIsMobileViewOpen] = useState(false);

  console.log(tableContent, "ds");

  const data = tableContent?.content;

  return (
    <div className="bg-[var(--background-color)] text-[var(--text-color)]  px-6 md:px-12 lg:px-16 max-w-7xl mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div
          onClick={() => setIsMobileViewOpen(true)}
          className="fixed   bottom-52 z-50  text-base font-bold cursor-pointer bg-white text-purple-600 px-4 py-2 rounded-l-lg block md:hidden  right-0"
        >
          Content
        </div>
        <div className="md:col-span-2 space-y-12">
          {Object.keys(data).map((key) => {
            if (key === "overview") {
              return (
                <section key={key}>
                  <h1 className="lg:text-4xl text-3xl font-semibold mb-4">
                    Overview
                  </h1>
                  <p className="text-xl">{data[key]}</p>
                </section>
              );
            }

            return (
              <section key={key} id={key}>
                <h2 className="md:text-4xl text-3xl font-semibold mb-8">
                  {data[key]?.heading}
                </h2>
                <p
                  className="lg:text-xl md:text-lg text-base"
                  dangerouslySetInnerHTML={{ __html: data[key]?.desc }}
                />

                {data[key]?.list && (
                  <ul className="list-disc ml-6 my-4 space-y-2 lg:text-xl md:text-lg text-base">
                    {data[key].list.map((item, index) => (
                      <li
                        key={index}
                        dangerouslySetInnerHTML={{ __html: item }}
                      />
                    ))}
                  </ul>
                )}
                {data[key]?.result && (
                  <p
                    className="lg:text-xl md:text-lg text-base"
                    dangerouslySetInnerHTML={{ __html: data[key].result }}
                  />
                )}
              </section>
            );
          })}
        </div>

        {/* Sidebar */}
        <aside className="space-y-12 hidden md:block">
          {/* Table of Contents */}
          <div className="bg-[var(--background-gray-color)] p-3 lg:p-6 rounded-md">
            <h3 className="lg:text-4xl text-3xl font-semibold mb-8">
              Table of Contents
            </h3>
            <ul className="lg:space-y-2 flex flex-col items-start justify-start">
              {tableContent.tableOfContents.map((item) => (
                <li
                  key={item.id}
                  className="tableContent  flex items-start cursor-pointer p-2 rounded-2xl w-full hover:bg-custom-gradient-purple"
                >
                  <a
                    href={item?.link}
                    className="text-[var(--text-color)] md:text-lg lg:text-xl text-xl ps-1"
                  >
                    {item?.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Call to Action */}
          <div className="bg-[var(--background-gray-color)] p-6 rounded-md w-full z-40 sticky top-16">
            <img
              src={
                "https://abswebsiteassets.blob.core.windows.net/websiteimages/blog-assets/downloadblog.svg"
              }
              alt="Call to action"
              className="w-full md:h-[200px] lg:h-[309px] rounded-md mb-4"
            />

            <p className="text-[var(--text-color)]  text-center text-base lg:text-xl leading-6 font-medium my-5 md:my-7">
              How to Choose a Custom Software Development Company?
            </p>

            <div
              onClick={() => setIsOpenPopup(true)}
              className="btn cursor-pointer text-center w-full bg-cover hover:text-red-500 bg-opacity-50  bg-custom-gradient hover:bg-custom-gradient-hover text-base lg:text-lg rounded-full text-[var(--text-color)]
               font-semibold px-5 py-3 duration-500 md:static"
            >
              Download Now
            </div>
          </div>
        </aside>
      </div>

      {isOpenPopup && (
        <CaseStudyLeadPopup
          isOpenPopup={isOpenPopup}
          setIsOpenPopup={setIsOpenPopup}
        />
      )}

      {isMobileViewOpen && (
        <TableContentPopup
          isOpen={isMobileViewOpen}
          setIsOpen={setIsMobileViewOpen}
          tableOfContents={tableContent.tableOfContents}
        />
      )}
    </div>
  );
};

export default TableContent;
