const CustomLeftArrow = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="absolute  bottom-0 mt-30 mr-4 left-[50%] transform -translate-x-[100%]   z-auto rounded-full text-xl font-semibold bg-[white] p-2 md:p-3 text-[#7A1AE9] transition hover:bg-[#7A1AE9] hover:text-[var(--text-color)]"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="size-5 rtl:rotate-180"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.75 19.5L8.25 12l7.5-7.5"
        />
      </svg>
    </button>
  );
};

export default CustomLeftArrow;
