import React from "react";
import ProjectSectionCommon from "../../components/common/ProjectSectionCommon";

import { projectsPortfolioData } from "../../components/data/PortfolioData";

const ExploreMoreProject = () => {
  return (
    <>
      {" "}
      <h1 className="text-3xl text-center md:text-[56px] text-[var(--text-color)] font-semibold">
        Explore More Projects
      </h1>
      <div className="bg-[var(--background-gray-color)]  text-[var(--text-color)] ">
        <div className="flex flex-col mx-auto max-w-7xl  text-left  justify-center items-center">
          <ProjectSectionCommon displayedProjects={projectsPortfolioData} />
        </div>
      </div>
    </>
  );
};

export default ExploreMoreProject;
