import React, { useState } from "react";
import BlogDetailsHero from "./BlogDetailsHero";
import TableContent from "./TableContent";
import MoreOnAbstrabit from "./MoreOnAbstrabit";
import ContactUs from "../contact-us/ContactUs";
import MetaData from "../../components/layout/MetaData";
import { useLocation } from "react-router-dom";

const BlogDetails = () => {
  const [name, setName] = useState("");
  const location = useLocation();

  return (
    <div className="bg-[var(--background-color)] text-[var(--text-color)]  gap-20 md:gap-32 flex flex-col">
      <BlogDetailsHero setName={setName} />
      <TableContent tableContent={location.state} />
      <MoreOnAbstrabit />
      <ContactUs heading={"Partner with Us to Bring Your Vision to Life"} />
      <MetaData title={`${name} - Blog Details - Abstrabit Technologies`} />
    </div>
  );
};

export default BlogDetails;
