import React from "react";

const WorkingProcessCard = ({ tabCard }) => {
  console.log(tabCard, "just");
  return (
    <div className="grid gap-4 grid-cols-2 md:grid-cols-3">
      {tabCard &&
        tabCard?.map((tab, index) => (
          <div
            key={index}
            className="bg-[var(--background-gray-color)] pt-4 pb-5  px-5 flex flex-col items-start justify-start"
          >
            {tab.icon && (
              <div className="mb-3">
                <img src={tab.icon} alt="" />
              </div>
            )}
            <h3 className="md:text-lg text-base text-left font-semibold mb-3 text-[var(--text-color)]">
              {tab.title}
            </h3>
            <p className="md:text-sm  text-sm text-left font-medium text-[#7D7C88]">
              {tab.description}
            </p>
          </div>
        ))}
    </div>
  );
};

export default WorkingProcessCard;
