import React from "react";

import { useLocation } from "react-router-dom";

const BlogDetailsHero = ({ setName }) => {
  const location = useLocation();
  setName(location.state?.title);

  return (
    <div className=" bg-[var(--background-color)] text-center pb-16 pt-20 md:pt-32 px-6 md:px-16 max-w-7xl mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 text-[var(--text-color)]">
        <div className="text-start">
          <h2 className="text-4xl flex flex-col md:text-4xl lg:text-5xl leading-10  font-bold">
            <span className="lg:leading-[54px]">
              {" "}
              {location.state?.title ? location.state?.title : ""}
            </span>
          </h2>

          <p className="text-base md:text-base font-normal lg:text-xl mt-5">
            {" "}
            {location.state?.description ? location.state?.description : ""}
          </p>

          <div className="flex md:flex-col lg:flex-row text-[#7D7C88]   mt-4 text-base md:text-xl font-normal items-start gap-2 justify-start">
            <h4 className="text-left ">{location.state?.author}</h4>
            <span>•</span>
            <h5 className="text-start">{location.state?.date}</h5>
          </div>
        </div>

        <div className="">
          <img
            src={location.state?.image ? location.state?.image : ""}
            alt=""
            className="w-full object-cover lg:h-[450px]"
          />
        </div>
      </div>
    </div>
  );
};

export default BlogDetailsHero;
