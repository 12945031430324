import React from "react";

const ExperienceComponent = () => {
  return (
    <div className="flex items-center justify-center mt-40 lg:my-40">
      <div className="bg-[#2D2D2D]  mx-auto max-w-7xl">
        <div className="py-10  gap-10 px-6 md:px-16  relative flex flex-col-reverse md:flex-row items-center justify-between">
          {/* Heading */}
          <div className="md:w-1/2 -mt-60 md:-mt-0  md:mb-0 animate-fade-in-down">
            <h1 className="text-5xl md:text-6xl lg:text-[84px] textColorTech font-bold  text-transparent  animate-fade-in-down">
              <span className="block">12+</span>
              <span className="block"> Years</span>
              <span className="block">Of</span>
              <span className="block">Experience</span>
            </h1>
            <div className="mt-6 p-4 bg-[var(--background-color-white)] text-black  flex items-center space-x-4 animate-fade-in-left">
              <img
                src="https://abswebsiteassets.blob.core.windows.net/websiteimages/about-assets/pbatch.svg"
                alt=""
              />
              <div>
                <p className="lg:text-4xl textColorPurple text-lg  font-semibold">
                  Top-Rated Company
                </p>
                <p className="text-base md:text-xl text-[2D2D2D] lg:leading-5">
                  We are focused on delivering an exceptional customer
                  experience and meeting the needs of their clients.
                </p>
              </div>
            </div>
          </div>

          {/* Image */}
          <div className=" relative ">
            <div className="relative bottom-52 md:bottom-0 lg:bottom-0 z-10 left-24 md:left-0">
              <img
                src="https://abswebsiteassets.blob.core.windows.net/websiteimages/about-assets/expp.svg"
                alt="Business Meeting"
                className="z-10 w-full h-full object-cover animate-fade-in-right"
              />
            </div>
            <div className="absolute -z-0 -top-40 lg:top-20 lg:-right-28 transform translate-x-1/3 -translate-y-1/3">
              <div className="lg:w-[957px] w-[574px] h-[574px] z-0 lg:h-[957px] border-[37px] lg:border-[64px] border-purple-700 rounded-full clip-path-left-semicircle"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceComponent;
