import React, { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Link, useNavigate } from "react-router-dom";

import { RiArrowDownSLine } from "react-icons/ri";
import ButtonComponent from "./common/ButtonComponent";
import { convertToSlug } from "../utils/convertToSlug";
import { headerData } from "./data/HeaderData";
import MakeSidebar from "./sidebar/Sidebar";
import { motion, useScroll } from "framer-motion";

const Header = ({}) => {
  const navigate = useNavigate();
  const { scrollYProgress } = useScroll();

  const [open, setOpen] = useState(window.innerWidth <= 834);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const handleGotoSubMenuRespectivePage = (dropLink, heading, mainHeading) => {
    setIsSubmenuOpen(false);
    setOpen(false);

    if (mainHeading.name === "Resources") {
      navigate(`/resources/${dropLink}`, {
        state: heading,
      });
    } else if (mainHeading.name === "Pricing") {
      // Check heading to determine the correct section ID
      if (heading === "Fixed-Cost Product Development") {
        navigate(`/pricing#fixed-cost-product-development`);
      } else if (heading === "Dedicated Tech Team") {
        navigate(`/pricing#dedicated-tech-team`);
      } else if (heading === "Maintenance and Upgradation") {
        navigate(`/pricing#maintenance-and-upgradation`);
      }
    } else {
      navigate(`/services/${dropLink}`, {
        state: heading,
      });
    }
  };

  const handleResize = () => {
    if (window.innerWidth >= 834) {
      setOpen(false); // Ensure the mobile menu is closed on larger screens
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [openA, setOpenA] = useState(false);

  return (
    <div className="z-50  md:w-full fixed right-0 top-0 left-0 bg-[var(--background-color)]">
      <div className="mx-auto  max-w-7xl">
        <div className="md:flex lg:px-16 items-center  justify-between py-5 md:py-4 lg:py-4 text-[var(--text-color)]">
          {/* Logo Section */}

          <Link to="/">
            <img
              className="w-[120px] md:w-[155px] mb-2 ps-6 lg:ps-0 md:ps-6  h-7 lg:w-auto lg:h-auto"
              src={
                "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/logo.svg"
              }
              alt="Logo"
            />
          </Link>

          {/* Menu Icon */}
          <div className="absolute right-8 top-6 cursor-pointer lg:hidden w-7 h-7">
            {open ? (
              <XMarkIcon
                onClick={() => {
                  setOpenA(false);
                  setTimeout(() => {
                    setOpen(false); // Delay setting open to true
                  }, 600); // Adjust the delay time as needed
                }}
              />
            ) : (
              <img
                src={
                  "https://abswebsiteassets.blob.core.windows.net/websiteimages/home-assets/barIcon.svg"
                }
                alt="Menu Icon"
                onClick={() => {
                  setOpenA(true);
                  setOpen(true);
                }}
              />
            )}
          </div>

          {open ? (
            <MakeSidebar
              openA={openA}
              setOpenA={setOpenA}
              open={open}
              setOpen={setOpen}
            />
          ) : (
            <ul className="md:flex  md:pb-0 pb-12 absolute md:static md:z-auto -z-30 left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in top-[-490px] bg-none bg-[var(--background-color)] md:flex-row md:items-center items-start justify-start">
              {headerData.map((link, index) => (
                <li
                  key={index}
                  className="md:ml-7 relative group md:my-0 my-3 font-normal"
                  onMouseEnter={() => link.submenu && setIsSubmenuOpen(index)}
                  onMouseLeave={() => setIsSubmenuOpen(false)}
                >
                  <Link
                    to={link.link}
                    className="  relative font-normal md:text-sm text-base lg:text-base  flex items-center justify-center transition-colors duration-300"
                    onClick={() => setOpen(false)}
                  >
                    {link.name}
                    {(link.name === "Services" ||
                      link.name === "Resources" ||
                      link.name === "Pricing") && (
                      <RiArrowDownSLine
                        className={` fill-current ${
                          isSubmenuOpen === index ? "rotate-180" : ""
                        } rounded-full ms-1 -me-2 font-normal border-none text-[var(--text-color)] transform transition-transform duration-500`}
                      />
                    )}
                    <span className="absolute mt-5 top-5 z-50 font-normal left-0 bottom-0 w-full h-[2px] bg-[var(--background-color-white)] scale-x-0 group-hover:scale-x-100 transform origin-left transition-transform duration-300"></span>
                  </Link>

                  {link.submenu && isSubmenuOpen === index && (
                    <div className="group relative">
                      <ul className="rounded-lg pt-8 bg-[var(--background-color)] py-2 z-10 absolute hidden text-[var(--text-color)] group-hover:block w-72">
                        {link.submenu.map((sublink, subIndex) => (
                          <li
                            className="gap-10 py-2 px-4 cursor-pointer"
                            key={subIndex}
                          >
                            <a
                              onClick={() =>
                                handleGotoSubMenuRespectivePage(
                                  convertToSlug(sublink.name),
                                  sublink.name,
                                  link
                                )
                              }
                              className="md:text-base text-xs hover:font-medium font-medium link-text "
                            >
                              {sublink.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
              <div className="md:mt-0 z-10 mt-3 pe-6 md:pe-0">
                {" "}
                <ButtonComponent page={"header"} />
              </div>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
